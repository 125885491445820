import { Chip6DS } from '../SixSenseElements';
import styles from './ChipMultiSelect.module.scss';

interface Props {
  value: string;
  onDismiss?: () => void;
}

export function ChipMultiSelect({ onDismiss, value }: Props) {
  return (
    <div className={`${styles.Container} chip`} data-testid="ChipMultiSelect">
      <Chip6DS onDismiss={onDismiss} selected={!!onDismiss}>
        {value}
      </Chip6DS>
    </div>
  );
}
