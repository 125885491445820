import { Select6DS } from '../SixSenseElements';

export interface OptionType<TLabel, TValue> {
  label: TLabel;
  value: TValue;
  isDisabled?: boolean;
  subLabel?: string;
}

interface Props<TLabel, TValue> {
  onChange: ({ value }: OptionType<TLabel, TValue>) => void;
  options: OptionType<TLabel, TValue>[];
  className?: string;
  disabled?: boolean;
  error?: boolean;
  onFocus?: () => void;
  placeholder?: string;
  searchable?: boolean;
  value?: OptionType<TLabel, TValue>;
}

export function Select<TLabel, TValue>({
  className = '',
  disabled,
  onChange,
  onFocus,
  options,
  placeholder = 'Select...',
  searchable = false,
  value,
  error,
}: Props<TLabel, TValue>) {
  return (
    <Select6DS
      className={`SelectionDropdown ${className}`}
      disabled={disabled}
      error={error}
      onChange={onChange}
      onFocus={onFocus}
      options={options}
      placeholder={placeholder}
      searchable={searchable}
      value={value}
    />
  );
}
