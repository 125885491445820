import { FormEvent } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { checkIfFilterIsValid } from '../../../../utils/filterUtils';
import { FilterButton } from '../FilterButton';
import { RadioButton } from '../RadioButton';
import styles from './FilterBooleanSelect.module.scss';

interface Props {
  fieldLabel: string;
  isLoading: boolean;
  onChange: (value: boolean) => void;
  onClose: () => void;
  className?: string;
  defaultOpen?: boolean;
  onClickDelete?: () => void;
  readOnly?: boolean;
  selected?: boolean;
  testId?: string;
}

export function FilterBooleanSelect({
  onClose,
  onChange,
  onClickDelete,
  selected,
  testId = 'FilterBooleanSelect',
  fieldLabel,
  className = '',
  readOnly,
  isLoading,
  defaultOpen = true,
}: Props) {
  const radioButtonClickHandler = (e: FormEvent<HTMLDivElement>, value: boolean) => {
    e.stopPropagation();
    onChange(value);
  };
  const shouldOpenByDefault = defaultOpen && selected === undefined;
  const isDisabled = readOnly || isLoading;
  const isFilterValid = checkIfFilterIsValid({ filterType: 'boolean', values: selected });

  const t_button = (
    <FilterButton
      onClickDelete={onClickDelete}
      readOnly={readOnly}
      showError={!isFilterValid}
      testId={`${testId}__button`}
      title={`${fieldLabel} is ${isFilterValid ? selected?.toString() : 'missing value'}`}
    >
      {fieldLabel}&nbsp;
      <strong>is {isFilterValid ? selected?.toString() : 'missing value'}</strong>
    </FilterButton>
  );

  return (
    <Dropdown
      className={`${className} DropdownFilter`}
      data-testid={testId}
      defaultOpen={shouldOpenByDefault}
      disabled={isDisabled}
      icon={null}
      onClose={onClose}
      trigger={t_button}
    >
      <Dropdown.Menu className={styles.FilterBooleanSelectMenu} data-testid={`${testId}__menu`}>
        <Dropdown.Item onClick={(e: FormEvent<HTMLDivElement>) => radioButtonClickHandler(e, true)}>
          <RadioButton
            checked={selected === true}
            className={styles.RadioButton}
            label="true"
            testId={`${testId}__radioTrue`}
          />
        </Dropdown.Item>
        <Dropdown.Item
          onClick={(e: FormEvent<HTMLDivElement>) => radioButtonClickHandler(e, false)}
        >
          <RadioButton
            checked={selected === false}
            className={styles.RadioButton}
            label="false"
            testId={`${testId}__radioFalse`}
          />
        </Dropdown.Item>
        <Dropdown.Item className={styles.FilterBooleanSelectDone} data-testid={`${testId}__done`}>
          Done
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
