import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { COMPANY_NAME } from '../../../../../config/constants';
import { SimpleMainNav } from '../../../Elements';
import ContentContainer from '../../ContentContainer';
import StatusMessage from '../../StatusMessage';
import styles from './SuccessContainer.module.scss';

export default function SuccessContainer() {
  const history = useHistory();

  useEffect(() => {
    const timer = setTimeout(() => {
      history.push('/');
      clearTimeout(timer);
    }, 3000);
  }, [history]);

  return (
    <>
      <SimpleMainNav />
      <ContentContainer>
        <div className={styles.ImageContainer} data-testid="EmailConfirmationSuccess">
          <img
            alt="Success"
            className={styles.Image}
            src="https://static.saleswhale.com/rep/images/email-confirmation-success.png"
          />
        </div>
        <StatusMessage text="Email verified!" type="success" />
        <div>
          <span className={styles.RedirectText}>Redirecting you to {COMPANY_NAME}...</span>
        </div>
      </ContentContainer>
    </>
  );
}
