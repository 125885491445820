import { Moment } from 'moment';
import { useState } from 'react';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import 'react-dates/initialize';
import './DateRangeSelection.scss';

export interface MomentRange {
  endDate: Moment | null;
  startDate: Moment | null;
}
interface Props {
  dateRange: MomentRange;
  name: string;
  onDatesChange: (dates: MomentRange) => void;
  checkForOutsideRange?: boolean;
  className?: string;
  dateFormat?: string;
  label?: string;
  testId?: string;
}

export function DateRangeSelection({
  name,
  dateRange,
  label,
  className,
  dateFormat = 'MMMM D, YYYY',
  onDatesChange,
  checkForOutsideRange = false,
  testId = 'DateRangeSelection',
}: Props) {
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);

  const isOutsideRange = (moment: Moment) =>
    checkForOutsideRange ? !moment.isSameOrBefore(new Date(), 'day') : false;

  return (
    <div className={`DateRangeSelection ${className ? className : ''}`} data-testid={testId}>
      {label && <div className="DateRangeSelection__label">{label}</div>}
      <DateRangePicker
        customArrowIcon={<span className="DateRangeSelection__customMidIcon">to</span>}
        displayFormat={() => dateFormat}
        endDate={dateRange.endDate}
        endDateId={`endDate_${name}`}
        focusedInput={focusedInput}
        isOutsideRange={isOutsideRange}
        onDatesChange={({ startDate, endDate }) => onDatesChange({ startDate, endDate })}
        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
        startDate={dateRange.startDate}
        startDateId={`startDate_${name}`}
      />
    </div>
  );
}
