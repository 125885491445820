import { ReactNode } from 'react';
import styles from './PageContainer.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
  testId?: string;
}

// TODO: update more pages to use PageContainer
export function PageContainer({ children, testId = 'PageContainer', className = '' }: Props) {
  return (
    <div className={`${styles.PageContainer} ${className}`} data-testid={testId}>
      {children}
    </div>
  );
}
