import { Fragment, ReactElement, ReactNode } from 'react';
import styles from './ControlLabel.module.scss';

export interface ControlLabelProps {
  children?: ReactNode;
  disabled?: boolean;
  label?: string | ReactElement;
  labelColor?: 'warning' | 'success' | 'neutral' | 'danger';
  labelPosition?: 'after' | 'before';
  subLabel?: string | ReactElement;
}

export function ControlLabel({
  disabled,
  labelColor = 'neutral',
  children,
  label,
  labelPosition = 'after',
  subLabel,
}: ControlLabelProps) {
  return label || subLabel ? (
    <label className={styles.LabelContainer} data-testid="ControlLabel">
      {labelPosition === 'after' && <div className={styles.ChildContainer}>{children}</div>}
      <div
        className={`${styles.TextContainer} ${disabled ? styles.TextContainer__disabled : ''}`}
        data-testid="Controllabel__textContainer"
      >
        <span
          className={`${styles.Label} ${styles[`Label--${labelColor}`]}`}
          data-testid="ControlLabel__label"
        >
          {label}
        </span>
        {subLabel ? (
          <span className={styles.SubLabel} data-testid="ControlLabel__subLabel">
            {subLabel}
          </span>
        ) : null}
      </div>
      {labelPosition === 'before' && <div className={styles.ChildContainer}>{children}</div>}
    </label>
  ) : (
    <Fragment>{children}</Fragment>
  );
}
