import { FileDoc, Image, UploadCloud, XClose } from '@saleswhale/barnacle/icons';
import { Card, Button, Skeleton } from '..';
import { DOC_TYPES, IMAGE_TYPES, MISC_TYPES } from '../../../../config/constants';
import { formattedBytes, formattedFileTypes } from '../../../../utils/formatting';
import { AttachmentSchema } from '../../../schemas';
import styles from './AttachmentCard.module.scss';

interface Props {
  contentType: string;
  fileName: string;
  url: string;
  className?: string;
  fileSize?: number;
  testId?: string;
}

export function AttachmentCard({
  contentType,
  fileName,
  url,
  fileSize,
  className = '',
  testId = 'AttachmentCard',
}: Props) {
  const type = formattedFileTypes(contentType);

  return (
    <a
      className={className}
      data-testid={testId}
      href={url}
      rel="noopener noreferrer"
      target="_blank"
    >
      <Card isClickable className={styles.Container}>
        <div className={styles.IconContainer}>
          {DOC_TYPES.includes(type) && (
            <FileDoc className={styles.Icon} data-testid={`${testId}__fileIcon`} />
          )}
          {IMAGE_TYPES.includes(type) && (
            <Image className={styles.Icon} data-testid={`${testId}__imageIcon`} />
          )}
          {MISC_TYPES.includes(type) && (
            <UploadCloud className={styles.Icon} data-testid={`${testId}__miscIcon`} />
          )}
        </div>
        <div className={styles.InfoContainer}>
          <span className={styles.Filename} data-testid={`${testId}__name`}>
            {fileName}
          </span>
          {fileSize && (
            <span className={styles.Filesize} data-testid={`${testId}__size`}>
              {formattedBytes(fileSize)}
            </span>
          )}
        </div>
      </Card>
    </a>
  );
}

interface RemovableProps {
  attachment: AttachmentSchema;
  onRemove: (attachment: AttachmentSchema) => void;
  className?: string;
}

export function RemovableAttachmentCard({ attachment, onRemove, className = '' }: RemovableProps) {
  const { uploadContentType, uploadFileName, uploadFileSize, uploadUrl } = attachment;

  const type = formattedFileTypes(uploadContentType);

  return (
    <Card className={`${styles.RemovableContainer} ${className}`} testId="RemovableAttachmentCard">
      <div className={styles.InfoAndIconContainer}>
        <div className={styles.IconContainer}>
          {DOC_TYPES.includes(type) && <FileDoc className={styles.Icon} />}
          {IMAGE_TYPES.includes(type) && <Image className={styles.Icon} />}
          {MISC_TYPES.includes(type) && <UploadCloud className={styles.Icon} />}
        </div>
        <a
          data-testid="RemovableAttachmentCard__file"
          href={uploadUrl}
          rel="noopener noreferrer"
          target="_blank"
        >
          <div className={styles.InfoContainer}>
            <span className={styles.Filename} data-testid="RemovableAttachmentCard__name">
              {uploadFileName}
            </span>
            <span className={styles.Filesize} data-testid="RemovableAttachmentCard__size">
              {formattedBytes(uploadFileSize)}
            </span>
          </div>
        </a>
      </div>
      <Button
        onClick={() => onRemove(attachment)}
        testId="RemovableAttachmentCard__removeIcon"
        theme="flush"
      >
        <XClose />
      </Button>
    </Card>
  );
}

interface SkeletonProps {
  testId?: string;
}

export function AttachmentSkeletonCard({ testId = 'AttachmentSkeletonCard' }: SkeletonProps) {
  return (
    <Card className={styles.SkeletonContainer} testId={testId}>
      <div className={styles.SkeletonInfoAndIconContainer}>
        <Skeleton className={styles.SkeletonAvatar} testId={`${testId}__avatar`} variant="avatar" />
        <div className={styles.SkeletonDetails} data-testid={`${testId}__details`}>
          <Skeleton className={styles.SkeletonText} textWidthPercent={100} />
          <Skeleton className={styles.SkeletonText} textWidthPercent={25} />
        </div>
      </div>
    </Card>
  );
}
