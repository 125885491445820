import { SearchSm } from '@saleswhale/barnacle/icons';
import { cloneDeep } from 'lodash';
import { FormEvent, KeyboardEventHandler, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { checkIfFilterIsValid } from '../../../../utils/filterUtils';
import { PicklistOption } from '../../../schemas/filterableField';
import { Checkbox } from '../Checkbox';
import { FilterButton } from '../FilterButton';
import { Input } from '../Input';
import styles from './FilterMultiSelectPicklist.module.scss';

interface Props {
  fieldLabel: string;
  isLoading: boolean;
  onChange: (selected: (string | number)[]) => void;
  onClose: () => void;
  options: PicklistOption[];
  className?: string;
  defaultOpen?: boolean;
  direction?: 'left' | 'right';
  onClickDelete?: () => void;
  readOnly?: boolean;
  selected?: (string | number)[];
  testId?: string;
}

export function FilterMultiSelectPicklist({
  onClose,
  onChange,
  onClickDelete,
  options,
  selected,
  testId = 'FilterMultiSelectPicklist',
  fieldLabel,
  className = '',
  defaultOpen = true,
  readOnly,
  isLoading,
  direction,
}: Props) {
  const [query, setQuery] = useState<string>('');
  const isFilterValid = checkIfFilterIsValid({
    filterType: 'multi_select_picklist',
    values: selected,
    picklistOptions: options,
  });
  const validSelected = selected?.filter(s => options.some(o => o.value === s)) || []; // Filters out any item that does not have a match from the options
  const firstSelection = validSelected ? options.find(o => o.value === validSelected[0]) : null;
  const selection = firstSelection
    ? validSelected && validSelected.length > 1
      ? `${firstSelection.label} or ${validSelected.length - 1} more`
      : firstSelection.label
    : 'missing value';
  // pushes selected options to the top of the list, and remove them from the remaining non-selected options
  const reOrderedOptions = [
    ...validSelected.map(s => options.find(o => o.value === s) as PicklistOption),
    ...options.filter(o => !validSelected.some(s => s === o.value)),
  ];
  const filteredOptions = reOrderedOptions.filter((o: PicklistOption) =>
    o.label.toLowerCase().includes(query.toLowerCase())
  );
  const optionsToDisplay = query ? filteredOptions : reOrderedOptions;
  const shouldOpenByDefault = defaultOpen && selected === undefined;

  const handleOnClose = () => {
    setQuery('');
    onClose();
  };

  const handleOnChange = (e: FormEvent<HTMLDivElement>, value: string | number) => {
    e.stopPropagation();
    if (validSelected.length > 0) {
      let _selected = cloneDeep(validSelected);
      const index = _selected.findIndex(id => id === value);
      // this is temporary until we can find a way to standardize how we should represent "any" on BE
      const possibleAnyValues: { [key: string]: boolean } = {
        all_with_campaigns: true,
        any: true,
      };
      if (index > -1) {
        _selected.splice(index, 1);
      } else {
        if (possibleAnyValues[value]) {
          _selected = [value];
        } else {
          const filteredSelected = _selected.filter(s => !possibleAnyValues[s]);
          _selected = [...filteredSelected, value];
        }
      }
      onChange(_selected);
    } else {
      onChange([value]);
    }
  };
  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === ' ' || e.keyCode === 32) {
      // Prevent closing of dropdown if spacebar
      e.stopPropagation();
    }
  };

  const t_button = (
    <FilterButton
      onClickDelete={onClickDelete}
      readOnly={readOnly}
      showError={!isFilterValid}
      testId={`${testId}__button`}
      title={`${fieldLabel} is ${selection}`}
    >
      {fieldLabel}
      <strong> is {selection}</strong>
    </FilterButton>
  );

  const isDisabled = readOnly || isLoading;

  return (
    <Dropdown
      multiple
      className={`${className} ${styles.Container} checkbox DropdownFilter`}
      data-testid={testId}
      defaultOpen={shouldOpenByDefault}
      direction={direction}
      disabled={isDisabled}
      icon={null}
      onClose={handleOnClose}
      trigger={t_button}
    >
      <Dropdown.Menu data-testid={`${testId}__dropdownMenu`}>
        <Input
          iconLeft={<SearchSm color="#6C737F" />}
          name="Search"
          onChange={e => setQuery(e.currentTarget.value)}
          onClick={e => e.stopPropagation()}
          onKeyDown={onKeyDown}
          placeholder="Search"
          testId={`${testId}__search`}
          value={query}
        />
        <Dropdown.Menu scrolling className={styles.OptionsMenu} data-testid={`${testId}__menu`}>
          {optionsToDisplay.length > 0 ? (
            optionsToDisplay.map((option, i) => (
              <Dropdown.Item
                data-testid={option.label}
                key={i}
                onClick={(e: FormEvent<HTMLDivElement>) => handleOnChange(e, option.value)}
                title={option.label}
                value={option.value}
              >
                <Checkbox checked={!!selected?.some(s => s === option.value)} />
                <span className={styles.DropdownItemLabel}>{option.label}</span>
              </Dropdown.Item>
            ))
          ) : (
            <div className={styles.NoResults} data-testid={`${testId}__noResults`}>
              <span>No results.</span>
            </div>
          )}
        </Dropdown.Menu>
        <Dropdown.Item
          className={styles.MutliSelectPickListDoneButton}
          data-testid={`${testId}__done`}
        >
          Done
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
