export const alterLinksToOpenNewTab = (element: HTMLElement) => {
  const links = element.getElementsByTagName('a');
  for (const link of links) {
    link.target = '_blank';
  }
};
export const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank', '  ,noreferrer');
  if (newWindow) newWindow.opener = null;
};
export function generateLoginWithSixSenseLink(origin: string, env: string) {
  const match = origin.match(/^https?:\/\/((?:[a-zA-Z\d-]+\.)?)conversations\.6sense\.com$/);
  const team = match && match[1] ? match[1] : '';
  switch (env) {
    case 'prod':
      return `https://${team}login.6sense.com?redirect=https://${team}conversations.6sense.com/`;
    case 'staging':
      return 'https://login-release.dev.6si.com';
    default:
      return 'https://login.dev.6si.com';
  }
}
