import { CSSProperties } from 'react';
import styles from './Divider.module.scss';

interface Props {
  bottom?: number;
  className?: string;
  color?: string;
  top?: number;
}

export function Divider({ className, top, bottom, color }: Props) {
  const customStyles: CSSProperties = {
    marginTop: top ? `${top}px` : undefined,
    marginBottom: bottom ? `${bottom}px` : undefined,
    borderBottomColor: color ? color : undefined,
  };
  return <hr className={`${styles.Divider} ${className ? className : ''}`} style={customStyles} />;
}
