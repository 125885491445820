import { XClose, Lock } from '@saleswhale/barnacle/icons';
import { ReactNode } from 'react';
import { Button } from '../Button';
import styles from './FilterButton.module.scss';

interface Props {
  children: ReactNode;
  onClick?: () => void;
  onClickDelete?: () => void;
  readOnly?: boolean;
  showError?: boolean;
  testId?: string;
  title?: string;
}

export function FilterButton({
  children,
  testId = 'FilterButton',
  readOnly,
  showError = false,
  onClickDelete,
  onClick,
  title,
}: Props) {
  const showCloseButton = !!onClickDelete && !readOnly;
  return (
    <Button
      withEllipsis
      className={showError ? styles.ButtonError : styles.Button}
      onClick={onClick}
      testId={testId}
      title={title}
    >
      <div className={styles.ButtonLabel} data-testid={`${testId}__body`}>
        {children}
      </div>
      {readOnly && (
        <div className={styles.Lock} data-testid={`${testId}__lock`}>
          <Lock color="#696F7B" size={16} />
        </div>
      )}
      {showCloseButton && (
        <div className={styles.CloseButton}>
          <div
            className={styles.CloseButton}
            data-testid={`${testId}__delete`}
            onClick={e => {
              e.stopPropagation();
              onClickDelete();
            }}
          >
            <XClose size={14} />
          </div>
        </div>
      )}
    </Button>
  );
}
