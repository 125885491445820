import { QueryClient, QueryCache, MutationCache } from '@tanstack/react-query';
import {
  PersistedClient,
  Persister,
  PersistQueryClientProviderProps,
} from '@tanstack/react-query-persist-client';
import { didError } from 'app/components/Elements';
import { get, set, del } from 'idb-keyval';
import { isAbortError } from 'utils/errorUtils';

export const CACHE_MAX_AGE = 1000 * 60 * 60 * 24 * 7; // 1 week

// you can view the defaults here: https://tanstack.com/query/v4/docs/guides/important-defaults
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: window.isPlaywright ? 0 : 3, // we don't want to retry failed requests in Playwright tests
      cacheTime: CACHE_MAX_AGE,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      const { queryKey } = query;
      // we don't want to show a toast if it's an abort error
      if (isAbortError(error)) return;
      // we don't want to show a toast for auth errors as well as ai block preview errors, /send_grid/account errors
      if (
        !queryKey.includes('me') &&
        !(queryKey.includes('ai_blocks') && queryKey.includes('preview')) &&
        !(queryKey.includes('send_grid') && queryKey.includes('account'))
      )
        didError(error);
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, _variables, _context, mutation) => {
      if (isAbortError(error)) return;
      if (!mutation.options.onError) didError(error);
    },
  }),
});

function createIDBPersister(idbValidKey: IDBValidKey = 'reactQueryCache') {
  return {
    persistClient: async (client: PersistedClient) => {
      await set(idbValidKey, client);
    },
    restoreClient: async () => {
      return await get<PersistedClient>(idbValidKey);
    },
    removeClient: async () => {
      await del(idbValidKey);
    },
  } as Persister;
}

export const persistOptions: PersistQueryClientProviderProps['persistOptions'] = {
  maxAge: CACHE_MAX_AGE,
  persister: createIDBPersister(),
  dehydrateOptions: {
    shouldDehydrateQuery: query => {
      // we only want to persist certain queries
      return Boolean(query.meta?.persist);
    },
  },
};
