import { SearchSm } from '@saleswhale/barnacle/icons';
import { Dispatch, FormEvent, SetStateAction } from 'react';
import { Button } from '../Button';
import { Input } from '../Input';
import styles from './SearchBar.module.scss';

interface Props {
  inputName: string;
  inputPlaceholder: string;
  onSearch: (v: string | null) => void;
  searchValue: string | null;
  setSearchValue: Dispatch<SetStateAction<string | null>> | ((data: string | null) => void);
  className?: string;
  isAutoSearch?: boolean;
  isResetDisabled?: boolean;
  isSearchDisabled?: boolean;
  length?: 'short' | 'normal';
  onFocus?: () => void;
  testId?: string;
}

export function SearchBar({
  className = '',
  inputName,
  inputPlaceholder,
  isAutoSearch,
  isResetDisabled,
  isSearchDisabled,
  length = 'normal',
  onSearch,
  searchValue,
  setSearchValue,
  testId = 'SearchBar',
  onFocus,
}: Props) {
  const handleOnChange = (e: FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value || null;
    setSearchValue(value);
    isAutoSearch && onSearch(value ? value.trim() : value);
  };
  const handleSubmit = () => {
    onSearch(searchValue ? searchValue.trim() : searchValue);
  };
  const handleOnReset = () => {
    setSearchValue(null);
    onSearch(null);
  };

  return (
    <div className={`${styles.Container} ${className}`} data-testid={testId}>
      <Input
        className={length === 'short' ? styles.Input__short : styles.Input}
        iconLeft={<SearchSm color="#6C737F" />}
        name={inputName}
        onChange={handleOnChange}
        onEnter={handleSubmit}
        onFocus={onFocus}
        placeholder={inputPlaceholder}
        testId={`${testId}__input`}
        value={searchValue}
      />
      {!isAutoSearch && (
        <>
          <Button
            className={styles.Button}
            disabled={isSearchDisabled}
            label="Search"
            onClick={handleSubmit}
            testId={`${testId}__submit`}
            theme="secondary"
            type="submit"
          />
          <Button
            className={styles.ResetButton}
            disabled={isResetDisabled}
            label="Reset"
            onClick={handleOnReset}
            testId={`${testId}__reset`}
            theme="flush"
          />
        </>
      )}
    </div>
  );
}
