import { LoadingSpinner } from '../LoadingSpinner';

export interface SpinnerProps {
  className?: string;
  inline?: boolean;
  size?: 'small' | 'medium' | 'regular' | 'large';
  testId?: string;
}

export const Spinner = ({ className, size, inline, testId = 'Spinner' }: SpinnerProps) => {
  const type = inline ? 'inline' : 'default';

  return <LoadingSpinner className={className} size={size} testId={testId} type={type} />;
};
