import { SearchSm } from '@saleswhale/barnacle/icons';
import { KeyboardEventHandler, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { checkIfFilterIsValid } from '../../../../utils/filterUtils';
import { PicklistOption } from '../../../schemas/filterableField';
import { FilterButton } from '../FilterButton';
import { Input } from '../Input';
import styles from './FilterPickList.module.scss';

interface Props<T extends string | number> {
  fieldLabel: string;
  onChange: (value: T) => void;
  options: PicklistOption<T>[];
  className?: string;
  defaultOpen?: boolean;
  isLoading?: boolean;
  onClickDelete?: () => void;
  onClose?: () => void;
  readOnly?: boolean;
  selected?: T;
  testId?: string;
}

export function FilterPickList<T extends string | number>({
  onChange,
  onClose,
  onClickDelete,
  options,
  testId = 'FilterPickList',
  fieldLabel,
  selected,
  readOnly,
  isLoading,
  className = '',
  defaultOpen = true,
}: Props<T>) {
  const [query, setQuery] = useState<string>('');
  const shouldOpenByDefault = defaultOpen && selected === undefined;
  const label = options.find(o => o.value === selected)?.label;
  const isFilterValid = checkIfFilterIsValid({
    filterType: 'picklist',
    values: selected,
    picklistOptions: options,
  });
  const isDisabled = readOnly || isLoading;
  const filteredOptions = options.filter((o: PicklistOption) =>
    o.label.toLowerCase().includes(query.toLowerCase())
  );
  const optionsToDisplay = query ? filteredOptions : options;

  const handleOnClose = () => {
    setQuery('');
    onClose && onClose();
  };
  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === ' ' || e.keyCode === 32) {
      // Prevent closing of dropdown if spacebar
      e.stopPropagation();
    }
  };

  const t_button = (
    <FilterButton
      onClickDelete={onClickDelete}
      readOnly={readOnly}
      showError={!isFilterValid}
      testId={`${testId}__button`}
      title={`${fieldLabel} is ${label ? label : 'missing value'}`}
    >
      {fieldLabel}
      <strong> is {label ? label : 'missing value'}</strong>
    </FilterButton>
  );

  return (
    <Dropdown
      className={`${className} ${styles.Container} DropdownFilter`}
      data-testid={testId}
      defaultOpen={shouldOpenByDefault}
      disabled={isDisabled}
      icon={null}
      onClose={handleOnClose}
      trigger={t_button}
    >
      <Dropdown.Menu data-testid={`${testId}__dropdownMenu`}>
        <Input
          className={styles.InputField}
          iconLeft={<SearchSm color="#6C737F" />}
          inputClassName={styles.Input}
          name="Search"
          onChange={e => setQuery(e.currentTarget.value)}
          onClick={e => e.stopPropagation()}
          onKeyDown={onKeyDown}
          placeholder="Search"
          testId={`${testId}__search`}
          value={query}
        />
        <Dropdown.Menu scrolling className={styles.SelectionMenu} data-testid={`${testId}__menu`}>
          {optionsToDisplay.length > 0 ? (
            optionsToDisplay.map(({ label, value }) => (
              <Dropdown.Item
                className={styles.DropdownItem}
                data-testid={label}
                key={value}
                onClick={() => onChange(value)}
                title={label}
                value={value}
              >
                {label}
              </Dropdown.Item>
            ))
          ) : (
            <div className={styles.NoResults} data-testid={`${testId}__noResults`}>
              <span>No results.</span>
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown.Menu>
    </Dropdown>
  );
}
