import { find } from 'lodash';
import { FormEvent, KeyboardEventHandler, SyntheticEvent } from 'react';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { FilterButton, RadioButton } from '..';
import { checkIfFilterIsValid } from '../../../../utils/filterUtils';
import { Input } from '../Input';
import styles from './FilterTextSearch.module.scss';

interface Props {
  fieldLabel: string;
  filterCondition: string;
  filterConditions: { label: string; value: string }[];
  isLoading: boolean;
  onChange: (value: string) => void;
  onClose: () => void;
  onFilterConditionChange: (filterCondition: string) => void;
  className?: string;
  defaultOpen?: boolean;
  onClickDelete?: () => void;
  readOnly?: boolean;
  testId?: string;
  value?: string;
}
export function FilterTextSearch({
  fieldLabel,
  filterCondition,
  filterConditions,
  value,
  onChange,
  onClose,
  onClickDelete,
  onFilterConditionChange,
  className = '',
  testId = 'FilterTextSearch',
  readOnly,
  isLoading,
  defaultOpen = true,
}: Props) {
  const label = find(filterConditions, { value: filterCondition })?.label;
  const shouldOpenByDefault = defaultOpen && value === undefined;
  const isDisabled = readOnly || isLoading;
  const isFilterValid = checkIfFilterIsValid({ filterType: 'search', values: value });

  const handleOptionChange = (
    e: SyntheticEvent<HTMLDivElement, MouseEvent>,
    { value: newFilterCondition }: DropdownItemProps
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (newFilterCondition === filterCondition) return;
    onFilterConditionChange(newFilterCondition as string);
  };
  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === ' ' || e.keyCode === 32) {
      // Prevent closing of dropdown if spacebar
      e.stopPropagation();
    }
  };
  const handleUpdateValue = (e: FormEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const { value } = e.currentTarget;
    onChange(value);
  };

  const t_button = (
    <FilterButton
      onClickDelete={onClickDelete}
      readOnly={readOnly}
      showError={!isFilterValid}
      title={`${fieldLabel} ${!isFilterValid ? 'is missing value' : `${label} ${value}`}`}
    >
      {fieldLabel}
      <strong> {!isFilterValid ? 'is missing value' : `${label} ${value}`}</strong>
    </FilterButton>
  );
  return (
    <Dropdown
      className={`${className} DropdownFilter`}
      data-testid={testId}
      defaultOpen={shouldOpenByDefault}
      disabled={isDisabled}
      icon={null}
      onClose={onClose}
      trigger={t_button}
    >
      <Dropdown.Menu className={styles.Menu} data-testid={`${testId}__menu`}>
        {filterConditions.map(({ value: f, label }) => {
          const isSelected = f === filterCondition;
          return (
            <Dropdown.Item key={f} onClick={handleOptionChange} selected={isSelected} value={f}>
              <RadioButton checked={isSelected} className={styles.Radio} label={label} />
              {isSelected && (
                <Input
                  className={styles.InputField}
                  name="FilterTextSearch"
                  onChange={handleUpdateValue}
                  onClick={e => e.stopPropagation()}
                  onKeyDown={handleKeyDown}
                  placeholder={fieldLabel}
                  testId={`${testId}__input`}
                  value={value}
                />
              )}
            </Dropdown.Item>
          );
        })}
        <Dropdown.Item className={styles.Done} data-testid="FilterTextSearch__done">
          Done
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
