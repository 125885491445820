import { MouseEventHandler } from 'react';
import { Icon } from '../Icon';
import styles from './EllipsisButton.module.scss';

interface Props {
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  testId?: string;
}

export function EllipsisButton({ onClick, className, testId = 'EllipsisButton' }: Props) {
  return (
    <button
      className={`${styles.Button} ${className ? className : ''}`}
      data-testid={testId}
      onClick={onClick}
    >
      <Icon bottom={0} fill="#565656" left={0} name="ellipsis" right={0} size={16} top={0} />
    </button>
  );
}
