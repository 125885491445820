import { ChevronDown } from '@saleswhale/barnacle/icons';
import { useEffect, useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { SelectedFilterOperator, SelectedFilterSchema } from '../../../../schemas/filterableField';
import { FilterBooleanSelect } from '../../FilterBooleanSelect';
import { FilterDateTime } from '../../FilterDateTime';
import { FilterMultiSelectPicklist } from '../../FilterMultiSelectPicklist';
import { FilterMultiStringSearch } from '../../FilterMultiStringSearch';
import { FilterPickList } from '../../FilterPickList';
import { FilterTextSearch } from '../../FilterTextSearch';
import styles from './SelectedFilters.module.scss';

interface Props {
  confirmFilters: () => void;
  isLoading: boolean;
  selectedFilterOperator: SelectedFilterOperator;
  selectedFilters: SelectedFilterSchema[];
  updateSelectedFilterCondition: (
    index: number,
    selectedFilterCondition: SelectedFilterSchema['selectedFilterCondition']
  ) => void;
  updateSelectedFilterOperator: (selectedFilterOperator: SelectedFilterOperator) => void;
  updateValues: (index: number, values: SelectedFilterSchema['values']) => void;
  updateValuesAndConfirmFilters: (index: number, values: SelectedFilterSchema['values']) => void;
  className?: string;
  defaultOpen?: boolean;
  numberOfMonths?: number;
  removeFilter?: (index: number) => void;
  testId?: string;
}

export default function SelectedFilters({
  selectedFilters,
  selectedFilterOperator,
  updateValues,
  updateValuesAndConfirmFilters,
  updateSelectedFilterCondition,
  updateSelectedFilterOperator,
  confirmFilters,
  removeFilter,
  isLoading,
  className = '',
  testId = 'SelectedFilter',
  defaultOpen = true,
  numberOfMonths,
}: Props) {
  const [isHoveringOverOperator, setIsHoveringOverOperator] = useState(false);

  useEffect(() => {
    const operatorButtonElements: Element[] = Array.from(
      document.getElementsByClassName(styles.OperatorButton)
    );
    operatorButtonElements.forEach(e =>
      isHoveringOverOperator
        ? e.classList.add(styles.ActiveOperatorButton)
        : e.classList.remove(styles.ActiveOperatorButton)
    );
  }, [isHoveringOverOperator]);

  const generateFilter = (
    {
      fieldLabel,
      picklistOptions,
      filterType,
      values,
      selectedFilterCondition,
      availableConditions,
      readOnly,
    }: SelectedFilterSchema,
    index: number
  ) => {
    const onChange = (values: SelectedFilterSchema['values']) => {
      updateValues(index, values);
    };
    const onFilterConditionChange = (
      condition: SelectedFilterSchema['selectedFilterCondition']
    ) => {
      updateSelectedFilterCondition(index, condition);
    };
    const onClose = () => {
      confirmFilters();
    };
    const onClickDelete = removeFilter && (() => removeFilter(index));

    switch (filterType) {
      case 'multi_select_picklist':
        return (
          <FilterMultiSelectPicklist
            defaultOpen={defaultOpen}
            fieldLabel={fieldLabel}
            isLoading={isLoading}
            onChange={onChange}
            onClickDelete={onClickDelete}
            onClose={onClose}
            options={picklistOptions}
            readOnly={readOnly}
            selected={values}
          />
        );
      case 'picklist':
        return (
          <FilterPickList
            defaultOpen={defaultOpen}
            fieldLabel={fieldLabel}
            isLoading={isLoading}
            onChange={value => updateValuesAndConfirmFilters(index, value)}
            onClickDelete={onClickDelete}
            options={picklistOptions}
            readOnly={readOnly}
            selected={values}
          />
        );
      case 'datetime':
        return (
          <FilterDateTime
            defaultOpen={defaultOpen}
            fieldLabel={fieldLabel}
            filterCondition={selectedFilterCondition}
            filterConditions={availableConditions}
            isLoading={isLoading}
            numberOfMonths={numberOfMonths}
            onChange={onChange}
            onClickDelete={onClickDelete}
            onClose={onClose}
            onFilterConditionChange={onFilterConditionChange}
            readOnly={readOnly}
            value={values}
          />
        );
      case 'search':
        return (
          <FilterTextSearch
            defaultOpen={defaultOpen}
            fieldLabel={fieldLabel}
            filterCondition={selectedFilterCondition}
            filterConditions={availableConditions}
            isLoading={isLoading}
            onChange={onChange}
            onClickDelete={onClickDelete}
            onClose={onClose}
            onFilterConditionChange={onFilterConditionChange}
            readOnly={readOnly}
            value={values}
          />
        );
      case 'boolean':
        return (
          <FilterBooleanSelect
            defaultOpen={defaultOpen}
            fieldLabel={fieldLabel}
            isLoading={isLoading}
            onChange={onChange}
            onClickDelete={onClickDelete}
            onClose={onClose}
            readOnly={readOnly}
            selected={values}
          />
        );
      case 'multi_string_search':
        return (
          <FilterMultiStringSearch
            fieldLabel={fieldLabel}
            filterCondition={selectedFilterCondition}
            filterConditions={availableConditions}
            isLoading={isLoading}
            onChange={onChange}
            onClickDelete={onClickDelete}
            onClose={onClose}
            onFilterConditionChange={onFilterConditionChange}
            readOnly={readOnly}
            value={values}
          />
        );
      default:
        console.error('Something bad has truly happened here');
        return null;
    }
  };
  const filterOperators = [
    { key: 'and', value: 'and', text: 'and' },
    { key: 'or', value: 'or', text: 'or' },
  ];
  const t_operatorButton = (
    <div
      className={styles.OperatorButton}
      data-testid={`${testId}__operatorButton`}
      onMouseEnter={() => setIsHoveringOverOperator(true)}
      onMouseLeave={() => setIsHoveringOverOperator(false)}
    >
      <span className={styles.Operator}>{selectedFilterOperator}</span>
      <ChevronDown size={12} />
    </div>
  );
  return (
    <>
      {selectedFilters.map((filter, i) => (
        <div className={`${styles.Filter} ${className}`} data-testid={testId} key={i}>
          {i > 0 && (
            <Dropdown
              data-testid={`${testId}__operatorDropdown`}
              disabled={isLoading}
              icon={null}
              onChange={(_, { value }) =>
                updateSelectedFilterOperator(value as SelectedFilterOperator)
              }
              options={filterOperators}
              trigger={t_operatorButton}
              value={selectedFilterOperator}
            />
          )}
          {generateFilter(filter, i)}
        </div>
      ))}
    </>
  );
}
