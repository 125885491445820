import { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler, forwardRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styles from './TextArea.module.scss';

interface Props {
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  error?: boolean;
  inputClassName?: string;
  isAutoSize?: boolean;
  maxLength?: number;
  minRows?: number;
  name?: string;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  readOnly?: boolean;
  rows?: number;
  testId?: string;
  value?: string;
}
export const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      autoFocus,
      className = '',
      inputClassName = '',
      error,
      maxLength,
      name,
      onBlur,
      onChange,
      onKeyDown,
      placeholder,
      testId = 'TextArea',
      value,
      disabled,
      rows = 6,
      isAutoSize,
      minRows,
      readOnly,
      ...props
    }: Props,
    ref
  ) => {
    const textAreaProps = {
      autoFocus,
      className: `${styles.TextArea} ${inputClassName} ${error ? styles['TextArea--error'] : ''}`,
      'data-testid': testId,
      disabled,
      maxLength,
      name,
      onBlur,
      onChange,
      onKeyDown,
      placeholder,
      rows,
      value,
      readOnly,
    };
    return (
      <div className={`InputField ${className}`} data-testid="TextAreaContainer" {...props}>
        {isAutoSize ? (
          <TextareaAutosize minRows={minRows} {...textAreaProps} maxRows={rows} ref={ref} />
        ) : (
          <textarea {...textAreaProps} ref={ref} />
        )}
        {maxLength && (
          <div className={styles.CharacterCount} data-testid="CharacterCount">
            <span className={styles.CharacterCountText}>
              {value?.length || 0}/{maxLength}
            </span>
          </div>
        )}
      </div>
    );
  }
);
