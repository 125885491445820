import { ReactNode } from 'react';
import {
  Popup as SemanticPopup,
  PopupContentProps,
  SemanticShorthandItem,
  PopupProps,
} from 'semantic-ui-react';
import './Popup.scss';

interface Props extends PopupProps {
  content: SemanticShorthandItem<PopupContentProps>;
  trigger: ReactNode;
  basic?: boolean;
  className?: string;
  disabled?: boolean;
  hideOnScroll?: boolean;
  id?: string;
  inverted?: boolean;
  offset?: [number, number];
  on?: 'hover' | 'click' | 'focus';
  onOpen?: () => void;
  open?: boolean;
  position?:
    | 'top center'
    | 'top left'
    | 'top right'
    | 'bottom right'
    | 'bottom left'
    | 'right center'
    | 'left center'
    | 'bottom center';
  testId?: string;
}

export const Popup = ({
  inverted = true,
  position = 'top center',
  offset,
  testId = 'Popup',
  onOpen,
  id,
  ...options
}: Props) => {
  return (
    <SemanticPopup
      hoverable
      data-testid={testId}
      id={id}
      inverted={inverted}
      offset={offset}
      onOpen={onOpen}
      position={position}
      {...options}
    />
  );
};
