import { sendEvent } from '../../../../services/analytics';
import { AddFilterEventSchema } from '../../../schemas/analytics';

const events = {
  addFilterPillQuery: 'Add Filter Query',
};

export const sendAddFilterEvent = (properties: AddFilterEventSchema) => {
  sendEvent({
    event: events.addFilterPillQuery,
    type: 'dropdown_value',
    group: 'filters',
    properties,
  });
};
