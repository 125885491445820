import { useEffect } from 'react';
import { switchTeam } from '../../../services/auth';
import { useQueryString } from '../../../services/routing';

export function AuthSwitchTeam() {
  const query = useQueryString();
  const teamId = query.get('team');
  const engagementId = query.get('engagement');

  useEffect(() => {
    if (teamId) {
      switchTeam(Number(teamId), `/conversations/${engagementId}`);
    }
  }, [teamId, engagementId]);
  return null;
}
