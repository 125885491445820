import { useConfirmUnsubscribe } from 'app/adapters/unsubscribe';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useQueryString } from 'services/routing';
import { Button, PageContainer } from '../../Elements';
import styles from './UnsubscribeContainer.module.scss';

export function UnsubscribeContainer() {
  const testId = 'UnsubscribeContainer';
  const { path } = useRouteMatch();
  const history = useHistory();
  const query = useQueryString();
  const teamName = query.get('team_name');
  const email = query.get('email');
  const hash = query.get('hash');

  const confirmUnsubscribeQuery = useConfirmUnsubscribe();

  const onClickUnsubscribe = () => {
    if (!email || !hash) return;
    confirmUnsubscribeQuery.mutate(
      { email, hash },
      {
        onSuccess: () => {
          history.push(`${path}/confirmation`);
        },
      }
    );
  };

  return (
    <PageContainer className={styles.Container} testId={testId}>
      <div className={styles.ContentContainer}>
        <Switch>
          <Route exact path={path}>
            <div data-testid={`${testId}__landing`}>
              <div className={styles.Header}>
                <span className={styles.HeaderText}>Unsubscribe Confirmation</span>
              </div>
              <div className={styles.Body}>
                <span className={styles.BodyText} data-testid={`${testId}__bodyText`}>
                  Click below if you no longer want to receive emails from{' '}
                  {teamName || 'this company'}
                </span>
              </div>
              <div className={styles.ButtonContainer}>
                <Button
                  isLoading={confirmUnsubscribeQuery.isLoading}
                  onClick={onClickUnsubscribe}
                  testId={`${testId}__unsubscribeButton`}
                  theme="primary"
                >
                  Unsubscribe
                </Button>
              </div>
            </div>
          </Route>
          <Route path={`${path}/confirmation`}>
            <div data-testid={`${testId}__confirmation`}>
              <div className={styles.Header}>
                <span className={styles.HeaderText}>Successfully Unsubscribed</span>
              </div>
            </div>
          </Route>
        </Switch>
      </div>
      <div>done</div>
    </PageContainer>
  );
}
