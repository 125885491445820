import { Shuffle } from '@saleswhale/barnacle/icons';
import { Badge } from '../Badge';
import styles from './SplitTestIndicator.module.scss';

interface Props {
  className?: string;
  noIcon?: boolean;
  testId?: string;
}

export function SplitTestIndicator({
  testId = 'SplitTestIndicator',
  className = '',
  noIcon,
}: Props) {
  return (
    <div className={`${styles.Container} ${className}`} data-testid={testId}>
      <Badge color="primary" icon={noIcon ? undefined : Shuffle} label="A/B testing" />
    </div>
  );
}
