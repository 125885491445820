import { Hourglass } from '@saleswhale/barnacle/icons';
import pluralize from 'pluralize';
import { useEffect, useRef } from 'react';
import { AttachmentCard } from '..';
import { leadLabel } from '../../../../config/constants';
import { htmlSanitizer } from '../../../../utils/formatting';
import { alterLinksToOpenNewTab } from '../../../../utils/linkUtils';
import { substitute as substituteMergeTags } from '../../../../utils/mergeTagUtils';
import { DecoratedSequenceTouchPointSchema, MergeTagValueSchema } from '../../../schemas';
import styles from './TouchPointPreview.module.scss';

interface Props {
  mergeTags: MergeTagValueSchema[];
  touchPoint: DecoratedSequenceTouchPointSchema;
}

// TODO: this could probably be extended to be used in the other parts of the app. feel free to setup the referral snippet if you need it.
export function TouchPointPreview({ touchPoint, mergeTags }: Props) {
  const bodyRef = useRef<HTMLDivElement>(null);
  const isReply = touchPoint.templates[0].deliveryType === 'reply';
  const previewSubject = substituteMergeTags(touchPoint.templates[0].subject, mergeTags);
  const previewBody = substituteMergeTags(touchPoint.templates[0].body, mergeTags);
  const hasAttachments = touchPoint.templates[0].sequenceTouchPointTemplateAttachments.length > 0;

  useEffect(() => {
    const bodyElement = bodyRef.current;
    if (bodyElement) {
      alterLinksToOpenNewTab(bodyElement);
    }
  }, [previewBody]);

  return (
    <>
      <div className={styles.Container} data-testid="TouchPointPreview">
        {touchPoint.triggerOnDuration > 0 && (
          <div className={styles.SendLaterBanner} data-testid="TouchPointPreview__sendLaterBanner">
            <Hourglass className={styles.TimeIcon} color="#007BC1" size={18} />
            <span className={styles.SendLaterText} data-testid="TouchPointPreview__sendLaterText">
              If {`${leadLabel()}`} does not reply, send{' '}
              <span className={styles.DaysCount}>{touchPoint.triggerOnDuration}</span>{' '}
              {`${pluralize('work day', touchPoint.triggerOnDuration)}`} later
            </span>
          </div>
        )}
        <div className={styles.Email}>
          <div className={styles.Header}>
            <div className={styles.Dot} />
            <div className={styles.Dot} />
            <div className={styles.Dot} />
          </div>
          <div>
            {!isReply && (
              <div className={styles.SubjectContainer}>
                <span
                  className={styles.SubjectText}
                  dangerouslySetInnerHTML={{ __html: htmlSanitizer(previewSubject) }}
                  data-testid="TouchPointPreview__subject"
                />
                {touchPoint.isSendImmediately && (
                  <div>
                    <span className={styles.ScheduleText} data-testid="TouchPointPreview__schedule">
                      Send immediately
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className={styles.BodyContainer}>
            <span
              dangerouslySetInnerHTML={{ __html: htmlSanitizer(previewBody) }}
              data-testid="TouchPointPreview__body"
              ref={bodyRef}
            />
          </div>
          {hasAttachments && (
            <div
              className={styles.AttachmentsContainer}
              data-testid="TouchPointPreview__attachments"
            >
              {touchPoint.templates[0].sequenceTouchPointTemplateAttachments.map(
                ({ attachment }, i) => (
                  <AttachmentCard
                    className={styles.Attachment}
                    contentType={attachment.uploadContentType}
                    fileName={attachment.uploadFileName}
                    fileSize={attachment.uploadFileSize}
                    key={i}
                    url={attachment.uploadUrl}
                  />
                )
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
