/**
 * Use this for all common padding and gutter holders
 */
import { ReactNode } from 'react';
import styles from './SegmentSpacer.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
  testId?: string;
  title?: string;
}
export function SegmentSpacer({
  children,
  title,
  className = '',
  testId = 'SegmentSpacer',
}: Props) {
  return (
    <div className={`${styles.Segment} ${className}`} data-testid={testId}>
      {title && <div className={styles.SegmentTitle}>{title}</div>}
      {children}
    </div>
  );
}
