import { generatePageEventObject, sendEvent } from './services/analytics';
/**
 * Handler to track every page change
 * Uses the pathname of the useLocation hook to reference the analytics page object above
 *
 * More details on how event names and groups are generated in the service/analytics file
 * Page Events Documentation: https://www.notion.so/saleswhale/Events-Documentation-31e1851143eb43f9b18be94fbc9d97cd#b33ffa7817264e4eb2619f5d531ca603
 */
export const sendPageViewEvent = (path: string) => {
  if (path === '/') return; //Since login and home share the index route based on authentication, better to handle them accordingly in their own components
  const { event, group, type } = generatePageEventObject(path);
  sendEvent({
    event,
    type,
    group,
  });
};
