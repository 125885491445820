import { useState, useRef, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { Spinner } from '../Spinner';
import './AccordionCard.scss';

interface AccordionCardProps {
  body: any;
  footer: any;
  header: any;
  isActive: boolean;
  isAlwaysActive: boolean;
  testId: string;
  toggleAccordion: () => void;
  className?: string;
  disabled?: boolean;

  isLoading?: boolean;
  showOpenState?: boolean;
  variant?: string;
}

export const AccordionCard = ({
  variant,
  header,
  body,
  testId,
  footer,
  isActive,
  isAlwaysActive,
  isLoading,
  toggleAccordion,
  className,
  showOpenState,
}: AccordionCardProps) => {
  const [height, setHeight] = useState(0);
  const contentElement = useRef<HTMLDivElement>(null);

  const handleToggleAccordion = () => {
    toggleAccordion();
  };

  useEffect(() => {
    let element: HTMLDivElement;
    let observer: ResizeObserver;
    if (contentElement.current) {
      element = contentElement.current;
      const updateMaxHeight = (entries: ResizeObserverEntry[]) => {
        if (isActive) {
          for (let entry of entries) {
            if (entry.contentBoxSize) {
              // Firefox implements `contentBoxSize` as a single content rect, rather than an array
              const contentBoxSize = Array.isArray(entry.contentBoxSize)
                ? entry.contentBoxSize[0]
                : entry.contentBoxSize;
              setHeight(contentBoxSize.blockSize);
            } else {
              //ContentBoxSize is not supported in safari but contentRect is. From the documentation, this is an earlier implementation but it's been left over in the api for web compatibility so there's a chance we have to update this when it gets deprecated
              setHeight(entry.contentRect.height);
            }
          }
        } else {
          setHeight(0);
        }
      };
      observer = new ResizeObserver(updateMaxHeight);
      observer.observe(contentElement.current);
    }
    return () => observer.unobserve(element);
  }, [isActive]);

  return (
    <div className={`AccordionCard ${variant} ${className}`} data-testid={testId}>
      <div
        className={`AccordionCard__header ${
          isAlwaysActive ? 'AccordionCard__header--alwaysActive' : ''
        } ${isActive && showOpenState ? 'AccordionCard__header--active' : ''}`}
        data-testid={`${testId}__header`}
        onClick={handleToggleAccordion}
      >
        {header}
      </div>
      <div
        className={`AccordionCard__body ${
          isAlwaysActive ? 'AccordionCard__body--alwaysActive' : ''
        }`}
        data-testid="AccordionCard__body"
        style={{ maxHeight: `${height}px`, overflow: isActive ? 'visible' : 'hidden' }}
      >
        <div
          className={`${isActive ? 'AccordionCard__content' : 'AccordionCard__content--hidden'}`}
          data-testid={`${testId}__content`}
          ref={contentElement}
        >
          {isLoading ? (
            <div className="AccordionCard__spinner">
              <Spinner size="large" />
            </div>
          ) : (
            body
          )}
          {footer}
        </div>
      </div>
    </div>
  );
};
