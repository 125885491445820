import { ReactNode } from 'react';
import { StickyContainer } from '../StickyContainer';
import styles from './Subnav.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
  testId?: string;
}

export function SubNav({ children, className, testId = 'SubNav' }: Props) {
  return (
    <StickyContainer className={`${styles.SubNav} ${className ? className : ''}`} testId={testId}>
      {children}
    </StickyContainer>
  );
}
