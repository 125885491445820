import { ReactNode, useEffect, useState } from 'react';
import { Modal } from '../Modal';

/**
 * Reusable component to show a modal for first-time users visiting a page
 * Normally this is done through 3rd-party integrations but as of right now we don't have access to 6sense's Pendo
 *
 * Caveat that if a user decides to clear their application data, they will inevitably be shown this modal again
 * A necessary UX sacrifice for simplicity as opposed to having user onboarding state saved in our databases
 * One can just only hope that we somehow gain access to Pendo to make our lives easier :shrug:
 *
 * Required props:
 * @userOnboarded - boolean that determines whether we show the modal or not, logic is in parent component
 * @finishUserOnboarding - function to complete user onboarding, by saving user email to localStorage
 * @children - content of the modal to be shown to the user
 */
interface Props {
  children: ReactNode;
  finishUserOnboarding: () => void;
  userOnboarded: boolean;
  className?: string;
  onDismiss?: () => void;
  onView?: () => void;
  testId?: string;
}
export function FirstTimeOnboardingModal({
  children,
  className,
  onView,
  onDismiss,
  testId = 'FirstTimeOnboardingModal',
  userOnboarded,
  finishUserOnboarding,
}: Props) {
  const [isShowModal, setIsShowModal] = useState<boolean>(!userOnboarded);

  useEffect(() => {
    isShowModal && onView && onView();
  }, [isShowModal, onView]);

  return (
    <Modal
      className={className}
      isCancellable={false}
      isOpen={isShowModal}
      onPrimary={() => {
        finishUserOnboarding();
        onDismiss && onDismiss();
        setIsShowModal(false);
      }}
      primary="Dismiss"
      testId={testId}
    >
      {children}
    </Modal>
  );
}
