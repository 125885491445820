import { HelpCircle, Lock } from '@saleswhale/barnacle/icons';
import { FormEvent, ReactNode, SetStateAction, useState } from 'react';
import { Icon, Input, Spinner, Popup } from '..';
import { handleFormUpdate } from '../../../../utils/formUtils';
import styles from './EditText.module.scss';

interface Props {
  title: string;
  children?: ReactNode | null;
  isDisabled?: boolean;
  isLoading?: boolean;
  name?: string;
  onBlur?: () => void;
  onChange?: (e: FormEvent<HTMLInputElement>) => void;
  onEnter?: () => void;
  setValue?: SetStateAction<any>;
  testId?: string;
  tooltipLabel?: string;
  value?: string | null;
}

export function EditText({
  children,
  isDisabled,
  isLoading,
  name,
  onBlur,
  onChange,
  onEnter,
  setValue,
  testId = 'EditText',
  title,
  value,
  tooltipLabel,
}: Props) {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const handleOnEnter = () => {
    onEnter && onEnter();
    setIsEditing(false);
  };
  const handleOnBlur = () => {
    onBlur && onBlur();
    setIsEditing(false);
  };

  return (
    <div className={styles.Container} data-testid={testId}>
      <div className={styles.Header} data-testid={`${testId}__title`}>
        {title}
        {tooltipLabel && (
          <Popup
            inverted
            content={<div>{tooltipLabel}</div>}
            position="top center"
            testId={`${testId}__tooltipContent`}
            trigger={
              <HelpCircle
                className={styles.TooltipIcon}
                data-testid={`${testId}__tooltip`}
                size={13}
              />
            }
          />
        )}
      </div>
      {isEditing && name ? (
        <Input
          autoFocus
          className={styles.Input}
          name={name}
          onBlur={handleOnBlur}
          onChange={(e: FormEvent<HTMLInputElement>) =>
            onChange ? onChange(e) : handleFormUpdate(e, setValue)
          }
          onEnter={handleOnEnter}
          placeholder={name}
          testId={`${testId}__input`}
          type="text"
          value={value}
        />
      ) : (
        <div className={styles.TextContainer} data-testid={`${testId}__detail`}>
          <div className={styles.Text}>
            {children || value || <span className={styles.NoData}>No data</span>}
          </div>
          {isDisabled ? (
            <div className={styles.Icon} data-testid={`${testId}__lock`}>
              <Lock size={16} />
            </div>
          ) : isLoading ? (
            <Spinner className={styles.Icon} />
          ) : (
            <Icon
              className={styles.IconEdit}
              name="Edit"
              onClick={() => setIsEditing(true)}
              size={16}
              testId={`${testId}__edit`}
            />
          )}
        </div>
      )}
    </div>
  );
}
