import { ChangeEventHandler, MouseEventHandler, ReactNode } from 'react';
import { ReactComponent as Check } from '../../../../assets/icons/check.svg';
import { ReactComponent as Circle } from '../../../../assets/icons/circle.svg';
import { ControlLabel, ControlLabelProps } from '../ControlLabel';
import styles from './RadioButton.module.scss';

interface RadioIconProps {
  checked?: boolean;
  checkedIndicator?: 'check' | 'circle';
  disabled?: boolean;
  error?: boolean;
}

const RadioIcon = ({ checked, disabled, checkedIndicator }: RadioIconProps) => {
  const styleName = `IconContainer${
    disabled && checked ? 'DisabledChecked' : disabled ? 'Disabled' : checked ? 'Checked' : ''
  }`;
  const icon = checked ? (
    checkedIndicator === 'circle' ? (
      <Circle color="#fff" />
    ) : (
      <Check color="#fff" />
    )
  ) : null;

  return <span className={styles[styleName]}>{icon}</span>;
};

interface RadioButtonProps extends RadioIconProps, ControlLabelProps {
  checked?: boolean;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  name?: string;
  onChange?: ChangeEventHandler;
  onClick?: MouseEventHandler;
  testId?: string;
  value?: string | number;
}

export function RadioButton({
  checked,
  className,
  checkedIndicator = 'circle',
  disabled,
  name,
  testId = 'RadioButton',
  onChange,
  onClick,
  value,
  children,
  error,
  label,
  labelPosition,
  subLabel,
}: RadioButtonProps) {
  return (
    <div className={className} data-testid={`${testId}__outerContainer`}>
      <ControlLabel
        disabled={disabled}
        label={label}
        labelPosition={labelPosition}
        subLabel={subLabel}
      >
        <label className={styles.RadioLabel} data-testid={testId}>
          <input
            checked={checked}
            className={styles.Input}
            data-testid={`${testId}Input`}
            disabled={disabled}
            name={name}
            onChange={onChange}
            onClick={onClick}
            readOnly={!onChange}
            type="radio"
            value={value}
          />
          <RadioIcon
            checked={checked}
            checkedIndicator={checkedIndicator}
            disabled={disabled}
            error={error}
          />
          {children}
        </label>
      </ControlLabel>
    </div>
  );
}
