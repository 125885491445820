import { ReactNode } from 'react';
import './Tooltip.scss';

interface Props {
  content: ReactNode;
  className?: string;
  inverted?: boolean;
  isOpen?: boolean;
  position?:
    | 'top center'
    | 'top left'
    | 'top right'
    | 'bottom right'
    | 'bottom left'
    | 'right center'
    | 'left center'
    | 'bottom center';
  testId?: string;
}
/**
 * This Tooltip is similar to the Popup element.
 * This separate Tooltip component is created because:
 * 1. Popup does not reposition when its trigger is repositioned. (E.g. After the trigger is moved or animated in, the popup does not reposition)
 * 2. This can also be used in the cflow tree when/if we add a tooltip in any of the sequences.
 * 3. This is also good for applying a tooltip on disabled buttons (Semantic Popup does not handle disabled triggers very well)
 */
export function Tooltip({
  content,
  className,
  inverted,
  position = 'top center',
  testId = 'Tooltip',
  isOpen = false,
}: Props) {
  return (
    <div
      className={`ui popup tooltip ${position}
        ${className ? className : ''}
        ${inverted ? 'inverted' : ''}
        ${isOpen ? 'visible' : ''}`}
      data-testid={testId}
    >
      {content}
    </div>
  );
}
