import styles from './IsolatedHeader.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  testId?: string;
}

export function IsolatedHeader({ children, className, testId = 'IsolatedHeader' }: Props) {
  return (
    <div className={`${styles.Container} ${className || ''}`} data-testid={testId}>
      {children}
    </div>
  );
}
