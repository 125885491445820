import { datadogLogs } from '@datadog/browser-logs';
import { useMutation, useQuery } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';
import { SetStateAction } from 'react';
import { getJSON, patch, post } from '../../services/fetch';
import { queryClient } from '../../services/reactQuery';
import { stringifyToQueryString } from '../../utils/formatting';
import { NegativeToast, PositiveToast } from '../components/Elements';

import {
  InviteeSchema,
  MembershipAPISchema,
  MembershipsAPISchema,
  MembershipSchema,
  MemberStatus,
  RoleAPISchema,
  RolesAPISchema,
  RoleSchema,
} from '../schemas';

const ENDPOINT = '/memberships';

export function generateMembershipsQueryKey(status?: string) {
  return ['memberships', ...(status ? [{ status }] : [])];
}
export function useGetMemberships(status?: MemberStatus) {
  const query = useQuery(generateMembershipsQueryKey(status), async () => {
    const qs = stringifyToQueryString({ status });
    const { memberships }: MembershipsAPISchema = await getJSON(`${ENDPOINT}${qs}`);
    return memberships;
  });
  const data: MembershipSchema[] = query.isSuccess ? query.data : [];
  return { ...query, data };
}
export function useGetUsers() {
  const query = useGetMemberships();
  const data = query.data.map(m => m.user);
  return { ...query, data };
}
export function useGetActiveUsers() {
  const query = useGetMemberships('active');
  const data = query.data.map(m => m.user);
  return { ...query, data };
}
export const getTeammates = (set?: SetStateAction<any>) => {
  return new Promise<MembershipSchema[]>(async (resolve, reject) => {
    try {
      const r: { memberships: MembershipSchema[] } = await getJSON(ENDPOINT);
      set && set(r.memberships);
      resolve(r.memberships);
    } catch (e) {
      reject(e);
    }
  });
};

export function updateMembershipsQueryData(member: MembershipSchema) {
  const queryKey = generateMembershipsQueryKey();
  const data = cloneDeep(queryClient.getQueryData<MembershipSchema[]>(queryKey));
  if (!data) return;
  const i = data.findIndex(m => m.id === member.id);
  if (i === -1) {
    // Add member to array if they don't exist, otherwise update the member
    data.push(member);
  } else {
    data[i] = member;
  }
  queryClient.setQueryData(queryKey, data);
}

export function useUpdateMemberRole() {
  return useMutation(
    async ({ id, role }: { id: string | number; role: MembershipSchema['role'] }) => {
      const payload = { membership: { role } };
      const r: MembershipAPISchema = await patch(`${ENDPOINT}/${id}`, payload);
      return r.membership;
    },
    {
      onSuccess: updatedMember => {
        updateMembershipsQueryData(updatedMember);
        PositiveToast('Successfully updated role.');
      },
      onError: () => NegativeToast('Unable to update role.'),
    }
  );
}

export function useArchiveMember() {
  return useMutation(
    async (id: string | number) => {
      const { membership }: MembershipAPISchema = await post(`${ENDPOINT}/${id}/archive`);
      return membership;
    },
    {
      onSuccess: updatedMember => {
        updateMembershipsQueryData(updatedMember);
        PositiveToast('Successfully archived teammate.');
      },
      onError: () => NegativeToast('Unable to archive teammate.'),
    }
  );
}
export function useUnarchiveMember() {
  return useMutation(
    async (id: string | number) => {
      const { membership }: MembershipAPISchema = await post(`${ENDPOINT}/${id}/unarchive`);
      return membership;
    },
    {
      onSuccess: updatedMember => {
        updateMembershipsQueryData(updatedMember);
        PositiveToast('Successfully unarchived teammate.');
      },
      onError: () => NegativeToast('Unable to unarchive teammate.'),
    }
  );
}

export const resendInvite = async (id: string | number) => {
  const r = await post(`/invites/${id}/resend`);
  return r.invite;
};

export function useSendInvite() {
  return useMutation(
    async (invitee: InviteeSchema) => {
      const payload = { invite: invitee };
      const { membership }: MembershipAPISchema = await post(`/invites`, payload);
      return membership;
    },
    {
      onSuccess: newMember => {
        updateMembershipsQueryData(newMember);
        PositiveToast('Invitation sent');
      },
    }
  );
}

export const resendConfirmationEmail = async (id: string) => {
  const response = await post('/user_confirmations', {
    data: {
      type: 'user_confirmations',
      relationships: {
        user: {
          data: {
            id,
            type: 'users',
          },
        },
      },
    },
  });
  return { ...response.data.attributes };
};

export function useGetUserRoles({ enabled = true }: { enabled?: boolean }) {
  const query = useQuery(
    ['six_sense', 'roles'],
    () => getJSON('/six_sense/roles').then((r: RolesAPISchema) => r.data.map(roleDecorator)),
    {
      onError: e => {
        // Most likely the endpoint doesn't exist
        datadogLogs.logger.error('Failed to get user roles', {}, e as Error);
        return [];
      },
      enabled,
    }
  );
  return { ...query, data: query.data || [] };
}

function roleDecorator(r: RoleAPISchema) {
  const role = {
    ...r.attributes,
  };
  return role as RoleSchema;
}
