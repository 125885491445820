import { FormEvent, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { COMPANY_NAME } from '../../../config/constants';
import { useQueryString } from '../../../services/routing';
import { setPassword as handleSetPassword } from '../../adapters/auth';
import { NegativeToast, PositiveToast, Input, Button } from '../Elements';
import styles from '../Login/Login.module.scss';

export default function SetPassword() {
  const history = useHistory();
  const query = useQueryString();
  const token = query.get('token');
  const email = query.get('email');
  const [password, setPassword] = useState('');
  const [showPasswordEmpty, setShowPasswordEmpty] = useState(false);

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!email || !token || password.trim().length < 8) {
      return setShowPasswordEmpty(true);
    }
    try {
      await handleSetPassword(email, token, password);
      PositiveToast(`Password set for ${email}`);
      history.push(`/password_login?email=${email}&token=${password}`);
    } catch {
      NegativeToast('Unable to reset password');
    }
  };
  return (
    <div className={styles.Container} data-testid="SetPassword">
      <div className={styles.FormContainer}>
        <div className={styles.LogoContainer}>
          <img
            alt={`${COMPANY_NAME} Logo`}
            height="32px"
            src="https://static.saleswhale.com/rep/images/6sense-logo-full.svg"
          />
        </div>
        <h2 className={styles.Header}>Set up your account</h2>
        <div className={styles.Subheader}>Set a password to access {COMPANY_NAME}.</div>
        <div>
          <form className={styles.Form} onSubmit={onSubmit}>
            <Input
              className={styles.Input}
              error={showPasswordEmpty}
              errorMsg="Please provide a password."
              label="Password"
              name="password"
              onChange={e => {
                setShowPasswordEmpty(false);
                setPassword(e.currentTarget.value);
              }}
              placeholder="At least 8 characters"
              testId="SetPasswordInput"
              type="password"
              value={password}
            />
            <Button
              className={styles.ActionButton}
              label="Set password"
              testId="SetPassword__submit"
              theme="primary"
              type="submit"
            />
          </form>
        </div>
        <div className={styles.Links}>
          <NavLink data-testid="SetPassword__return" to="/password_login">
            Return to log in
          </NavLink>
        </div>
      </div>
    </div>
  );
}
