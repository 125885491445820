import {
  Chip,
  EmptyState,
  FeaturedIcon,
  Icon,
  Select,
  Sidebar,
  Skeleton,
  TextArea,
  Tooltip,
  TopBar,
} from '@saleswhale/barnacle/components/';
import { ButtonGroup } from '@saleswhale/barnacle/components/buttons';
import { DonutChart } from '@saleswhale/barnacle/components/data-display/charts';
import DatePicker from '@saleswhale/barnacle/components/data-entry/DatePicker';
import Dropdown from '@saleswhale/barnacle/components/Dropdown';

const Chip6DS = Chip;
const DatePicker6DS = DatePicker;
const Dropdown6DS = Dropdown;
const DonutChart6DS = DonutChart;
const EmptyState6DS = EmptyState;
const FeaturedIcon6DS = FeaturedIcon;
const Icon6DS = Icon;
const Select6DS = Select;
const Sidebar6DS = Sidebar;
const Skeleton6DS = Skeleton;
const TextArea6DS = TextArea;
const Tooltip6DS = Tooltip;
const TopBar6DS = TopBar;
const ButtonGroup6DS = ButtonGroup;

export {
  Chip6DS,
  DatePicker6DS,
  Dropdown6DS,
  DonutChart6DS,
  EmptyState6DS,
  FeaturedIcon6DS,
  Icon6DS,
  Select6DS,
  Sidebar6DS,
  Skeleton6DS,
  TextArea6DS,
  Tooltip6DS,
  TopBar6DS,
  ButtonGroup6DS,
};
