import { getClass } from '../../../../utils/getClass';
import './LoadingSpinner.scss';

interface LoadingSpinnerProps {
  className?: string;
  color?: string;
  size?: 'small' | 'regular' | 'medium' | 'large';
  testId?: string;
  type?: 'default' | 'inline' | 'page';
}

export function LoadingSpinner({
  className,
  color,
  size = 'medium',
  type = 'default',
  testId = 'LoadingSpinner',
}: LoadingSpinnerProps) {
  return (
    <span
      className={`${
        type === 'inline' ? 'InlineLoadingSpinnerWrapper' : 'LoadingSpinnerWrapper'
      } ${className}`}
    >
      <svg
        className={`${getClass('LoadingSpinner', { type, size })}`}
        data-testid={testId}
        viewBox="0 0 66 66"
      >
        <circle
          className="LoadingSpinnerCircle"
          cx="33"
          cy="33"
          fill="none"
          r="30"
          stroke={color || '#007bc1'}
        />
      </svg>
    </span>
  );
}
