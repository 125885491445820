import { ReactNode } from 'react';

interface Props {
  onClick: () => any;
  children?: ReactNode;
  className?: string;
  testId?: string;
  zIndexLayer?: 'base' | 'one' | 'two' | 'three' | 'four' | 'five' | 'highest';
}
/**
 * Used for a screen-wide catcher. Mostly for modals.
 * The passed in components will be living in an absolutely positioned div so don't forget to style it correctly
 */
export function Blanket({
  children,
  onClick,
  className,
  zIndexLayer,
  testId = 'Blanket__side',
}: Props) {
  const zIndex = (() => {
    switch (zIndexLayer) {
      case 'base':
        return 100;
      case 'one':
        return 101;
      case 'two':
        return 102;
      case 'three':
        return 103;
      case 'four':
        return 104;
      case 'five':
        return 105;
      case 'highest':
        return 1100;
      default:
        return 1;
    }
  })();

  return (
    <>
      <div
        className={className}
        data-testid={testId}
        onClick={onClick}
        style={{
          bottom: 0,
          left: 0,
          top: 0,
          right: 0,
          position: 'fixed',
          zIndex,
        }}
      />
      <div
        style={{
          position: 'absolute',
          zIndex,
        }}
      >
        {children}
      </div>
    </>
  );
}
