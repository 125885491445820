import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ErrorContainer from './ErrorContainer';
import SuccessContainer from './SuccessContainer';
import styles from './EmailConfirmationContainer.module.scss';

export default function EmailConfirmationContainer() {
  const { path } = useRouteMatch();
  return (
    <div className={styles.Container} data-testid="EmailConfirmationContainer">
      <Switch>
        <Route path={`${path}/success`}>
          <SuccessContainer />
        </Route>
        <Route path={`${path}/error`}>
          <ErrorContainer />
        </Route>
      </Switch>
    </div>
  );
}
