import { capitalize } from 'lodash';
import { Icon } from '../../Elements';
import styles from './StatusMessage.module.scss';

interface Props {
  text: string;
  type: 'success' | 'error';
}

export default function StatusMessage({ text, type }: Props) {
  return (
    <div className={styles.Container}>
      <Icon
        className={styles.Icon}
        height={40}
        name={type === 'success' ? 'ic-tick' : 'ic-alert'}
        width={40}
      />
      <span className={styles[capitalize(type)]}>{text}</span>
    </div>
  );
}
