import { Redirect } from 'react-router-dom';
import { useQueryString } from '../../../services/routing';
import { useAuthenticate } from '../../adapters/auth';
import { Loading, PositiveToast } from '../Elements';

export default function AuthTokenHandoff() {
  const query = useQueryString();
  const token = query.get('token');
  const engagement = query.get('engagement');
  const authWithTokenQuery = useAuthenticate({
    token,
    refetchOnMount: true,
    onSuccess: data => PositiveToast(`Successfully signed in as ${data.session.email}`),
  });

  if (authWithTokenQuery.isLoading) return <Loading />;

  if (engagement) {
    return <Redirect to={`/conversations/${engagement}`} />;
  }
  return <Redirect to="/" />;
}
