import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { lowerCase } from 'lodash';
import { SetStateAction } from 'react';
import { getJSON, post } from '../../services/fetch';
import { mapRelationship } from '../../utils/APIUtils';
import {
  formattedDisplayName,
  formattedDateTime,
  stringifyToQueryString,
} from '../../utils/formatting';
import { PositiveToast } from '../components/Elements';
import { BotApiSchema } from '../schemas';
import {
  DecoratedHumanReviewTaskSchema,
  DecoratedSpontaneousTaskSchema,
  HumanReviewTasksAPISchema,
  HumanReviewTaskSchema,
  SpontaneousTaskAPISchema,
  SpontaneousTasksAPISchema,
  SpontaneousTaskSchema,
  HumanReplyPayload,
  HumanReviewUnsolicitedStats,
  HumanReplyAPIPayload,
} from '../schemas/humanReviewTask';
import {
  HumanConversationAPISchema,
  HumanReviewCampaignStats,
  HumanReviewTaskAPISchema,
  SpontaneousTaskShowAPISchema,
} from './../schemas/humanReviewTask';

const ENDPOINT = `/human_review_tasks`;

interface HumanTouchPointsQueryKeyPayload {
  humanConversationId?: string;
  stage?: 'scheduled';
}
export function generateHumanTouchPointsQueryKey({
  humanConversationId,
  stage,
}: HumanTouchPointsQueryKeyPayload) {
  return ['human_touch_points', { humanConversationId, stage }];
}
const generateHumanReviewTaskQueryKey = (id?: string) => {
  return ['human_review_tasks', id];
};
const generateSpontaneousEmailQueryKey = (id: string) => {
  return ['spontaneous_emails', id];
};
export function generateHumanReviewCampaignStatsQueryKey() {
  return ['human_review_campaign_stats'];
}
export function useGetHumanReviewTaskCampaignStats({
  enabled = true,
  onSuccess,
}: {
  enabled?: boolean;
  onSuccess?: (response: HumanReviewCampaignStats) => void;
} = {}) {
  return useQuery(
    generateHumanReviewCampaignStatsQueryKey(),
    (): Promise<HumanReviewCampaignStats> => getJSON(`${ENDPOINT}/stats`),
    {
      onSuccess,
      enabled,
    }
  );
}
export function useGetHumanReviewTaskUnsolicitedStats() {
  return useQuery(
    ['human_review_unsolicited_stats'],
    (): Promise<HumanReviewUnsolicitedStats> => getJSON(`/spontaneous_emails/stats`)
  );
}
interface Payload {
  page: number;
  perPage: number;
  state: string | string[] | null;
  convoState?: string;
  humanReviewTaskType?: string[] | string | null;
}
export function useGetHumanReviewTasks(payload: Payload) {
  return useQuery(['human_review_tasks', payload], async () => {
    const qs = stringifyToQueryString(payload, { skipNulls: true });

    const { humanReviewTasks, meta }: HumanReviewTasksAPISchema = await getJSON(`${ENDPOINT}${qs}`);
    const decoratedHumanReviewTasks: DecoratedHumanReviewTaskSchema[] = humanReviewTasks.map(t =>
      humanReviewTaskDecorator(t)
    );
    return { meta, humanReviewTasks: decoratedHumanReviewTasks };
  });
}

export function useGetHumanReviewTask(id?: string) {
  const queryKey = generateHumanReviewTaskQueryKey(id);
  return useQuery(
    queryKey,
    () => getJSON(`${ENDPOINT}/${id}`).then(r => humanReviewTaskDecorator(r.humanReviewTask)),
    { enabled: !!id }
  );
}

const humanReviewTaskDecorator = (
  t: HumanReviewTaskSchema
): DecoratedHumanReviewTaskSchema | DecoratedSpontaneousTaskSchema => {
  const task: DecoratedHumanReviewTaskSchema = {
    ...t,
    isCanceled: t.state === 'canceled',
    isAssigned: t.state === 'assigned',
    isCompleted: t.state === 'completed',
    isNew: t.state === 'new',
    isPendingBotResponse: t.conversationStatus === 'in_progress_replied',
    isPendingLeadResponse: t.conversationStatus === 'in_progress_followed_up',
    isHumanIntervention: t.state === 'human_intervention',
    isQualified: t.leadResult === 'qualified',
    isNotInterested: t.leadResult === 'not_interested',
    isNotInterestedNow: t.leadResult === 'not_interested_now',
    isStopResponding: t.leadResult === 'stop_responding',
    isTypeNormal: t.humanReviewTaskType === 'normal',
    isTypeSpontaneous: t.humanReviewTaskType === 'spontaneous',
    isTypeInterference: t.humanReviewTaskType === 'interference',
    isTypeAutomation: t.humanReviewTaskType === 'automation',
    isReferralCanceled: t.leadResult === 'referral_canceled',
    hasRelatedTasks: t.relatedTasksCount > 0,
    canBeUpdated: t.state === 'completed',
    formattedLeadResult: t.leadResult ? lowerCase(t.leadResult).toUpperCase() : null,
    formattedType:
      t.humanReviewTaskType === 'normal'
        ? 'Default'
        : t.humanReviewTaskType === 'automation'
        ? 'Automation rule'
        : t.humanReviewTaskType === 'spontaneous'
        ? 'Unsolicited'
        : t.humanReviewTaskType === 'interference'
        ? 'Hijacked'
        : '',
    formattedAssignedAt: formattedDateTime(t.assignedAt),
    formattedLastInteractedAt: formattedDateTime(t.lastInteractedAt),
  };
  return task;
};

export function useReferralCancelTask() {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: string) => {
      const r: HumanReviewTaskAPISchema = await post(`${ENDPOINT}/${id}/complete`, {
        result: 'referral_canceled',
      });
      return humanReviewTaskDecorator(r.humanReviewTask);
    },
    {
      onSuccess: (task, id) => {
        const queryKey = generateHumanReviewTaskQueryKey(id);
        queryClient.setQueryData(queryKey, task);
        PositiveToast('Updated Human Review Task.');
      },
    }
  );
}

export function useAssignTask() {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id, salesRepId }: { id: string; salesRepId: number | null }) => {
      // a null id means round robin
      const r: HumanReviewTaskAPISchema = await post(`${ENDPOINT}/${id}/assign`, {
        sales_rep_id: salesRepId,
      });
      return humanReviewTaskDecorator(r.humanReviewTask);
    },
    {
      onSuccess: (task, { id }) => {
        const queryKey = generateHumanReviewTaskQueryKey(id);
        queryClient.setQueryData(queryKey, task);
        PositiveToast('Assigned Human Review Task.');
      },
    }
  );
}

export function useCompleteTask() {
  const queryClient = useQueryClient();
  return useMutation(
    async ({
      id,
      result,
      salesRepId,
    }: {
      id: string;
      result: string;
      salesRepId?: number | null;
    }) => {
      const payload =
        result === 'qualified'
          ? {
              result,
              salesRepId,
            }
          : { result };
      const r: HumanReviewTaskAPISchema = await post(`${ENDPOINT}/${id}/complete`, payload);
      return humanReviewTaskDecorator(r.humanReviewTask);
    },
    {
      onSuccess: (task, { id }) => {
        const { leadResult, salesRep } = task;
        const queryKey = generateHumanReviewTaskQueryKey(id);
        queryClient.setQueryData(queryKey, task);
        const successMessage =
          leadResult === 'qualified'
            ? `Task successfully marked as qualified. This qualified conversation will be handed over to ${formattedDisplayName(
                salesRep?.firstName,
                salesRep?.lastName
              )}.`
            : 'Updated Human Review Task.';
        PositiveToast(successMessage);
      },
    }
  );
}

interface SpontaneousPayload {
  page: number;
  perPage: number;
  spontaneousType?: string;
  unprocessed?: boolean;
}
export function useGetSpontaneousTasks(payload: SpontaneousPayload) {
  const qs = stringifyToQueryString(payload, { skipNulls: true });
  return useQuery(['spontaneous_emails', payload], () =>
    getJSON(`/spontaneous_emails${qs}`).then((r: SpontaneousTasksAPISchema) => ({
      spontaneousTasks: r.data.map(t => ({ id: t.id, ...t.attributes })),
      meta: r.meta,
    }))
  );
}

export const classifySpontaneousTask = async (
  id: number | string,
  importance: 'important' | 'not_important'
) => {
  const payload = {
    data: {
      attributes: {
        importance,
      },
    },
  };
  const { data }: { data: SpontaneousTaskAPISchema } = await post(
    `/spontaneous_emails/${id}/classify`,
    payload
  );
  return data.attributes.engagementId;
};

const spontaneousTaskDecorator = ({
  data,
  included,
}: SpontaneousTaskShowAPISchema): SpontaneousTaskSchema => {
  const botApiObj = mapRelationship<BotApiSchema>(data.relationships.bot, included);
  const bot = botApiObj ? { id: botApiObj.id, ...botApiObj.attributes } : null;
  return { id: data.id, ...data.attributes, ...(bot ? { bot } : {}) };
};
export function useGetSpontaneousTask(id: string) {
  return useQuery(generateSpontaneousEmailQueryKey(id), async () => {
    const qs = stringifyToQueryString({ include: 'bot' });
    const response: SpontaneousTaskShowAPISchema = await getJSON(`/spontaneous_emails/${id}${qs}`);
    return spontaneousTaskDecorator(response);
  });
}

export function useSendEmail() {
  const queryClient = useQueryClient();
  return useMutation(
    async ({
      humanReviewTaskId,
      reply,
    }: {
      humanReviewTaskId: string;
      reply: HumanReplyPayload;
    }) => {
      const { immediate, tos, ccs, emails } = reply;
      const payload: HumanReplyAPIPayload = {
        emailSequenceContent: {
          tos,
          ccs,
          immediate,
          emails: emails.map(email => {
            const { body, triggerOnDuration, attachments } = email;
            return {
              body,
              triggerOnDuration,
              attachmentIds: attachments.map(a => a.id.toString()),
            };
          }),
        },
      };
      const url = `/human_review_tasks/${humanReviewTaskId}/human_intervention`;
      const response = await post(url, payload);
      const decoratedTask = humanReviewTaskDecorator(response.humanReviewTask);
      return decoratedTask;
    },
    {
      onSuccess: (decoratedTask, { humanReviewTaskId }) => {
        const queryKey = generateHumanReviewTaskQueryKey(humanReviewTaskId);
        queryClient.setQueryData(queryKey, decoratedTask);
        queryClient.invalidateQueries(generateHumanReviewCampaignStatsQueryKey());
      },
    }
  );
}
export function useSendFollowUpEmail() {
  const queryClient = useQueryClient();
  return useMutation(
    async ({
      humanConversationId,
      reply,
    }: {
      humanConversationId: string;
      reply: HumanReplyPayload;
    }) => {
      const { immediate, tos, ccs, emails } = reply;
      const payload: HumanReplyAPIPayload = {
        emailSequenceContent: {
          tos,
          ccs,
          immediate,
          emails: emails.map(email => {
            const { body, triggerOnDuration, attachments } = email;
            return {
              body,
              triggerOnDuration,
              attachmentIds: attachments.map(a => a.id.toString()),
            };
          }),
        },
      };
      const url = `/human_conversations/${humanConversationId}/follow_up`;
      const response: HumanConversationAPISchema = await post(url, payload);
      return response.humanConversation;
    },
    {
      onSuccess: (humanConversation, { humanConversationId }) => {
        const scheduledTouchPointsQueryKey = generateHumanTouchPointsQueryKey({
          humanConversationId,
          stage: 'scheduled',
        });
        const humanReviewTaskQueryKey = generateHumanReviewTaskQueryKey(
          humanConversation.humanReviewTaskId.toString()
        );
        queryClient.invalidateQueries(scheduledTouchPointsQueryKey);
        queryClient.invalidateQueries(humanReviewTaskQueryKey);
        queryClient.invalidateQueries(generateHumanReviewCampaignStatsQueryKey());
      },
    }
  );
}

export const getTaskByUuid = async (uuid: string, set?: SetStateAction<any>) => {
  const response = await getJSON(`/human_review_tasks/${uuid}/retrieve`);
  const humanReviewTask = response.humanReviewTask;
  set && set(humanReviewTask);
  return humanReviewTask;
};
