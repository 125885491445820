import { LinkExternal } from '@saleswhale/barnacle/icons';
import { upperFirst } from 'lodash';
import { ReactElement } from 'react';
import styles from './SupportLink.module.scss';

interface Props {
  label: string;
  className?: string;
  href?: string;
  icon?: ReactElement;
  iconDirection?: 'left' | 'right';
  noIcon?: boolean;
  onClick?: () => void;
  openInNewTab?: boolean;
  testId?: string;
}

export function SupportLink({
  label,
  icon = <LinkExternal size={16} />,
  iconDirection = 'left',
  href,
  onClick,
  className,
  testId = 'SupportLink',
  openInNewTab = true,
  noIcon = false,
}: Props) {
  const target = {
    rel: openInNewTab ? 'noreferrer' : undefined,
    target: openInNewTab ? '_blank' : undefined,
  };
  const t_icon = !noIcon ? (
    <span className={styles.Icon} data-testid={`${testId}__icon${upperFirst(iconDirection)}`}>
      {icon}
    </span>
  ) : null;
  return (
    <a
      {...target}
      className={`${noIcon ? styles.LinkUnderlined : styles.Link} ${className ? className : ''}`}
      data-testid={testId}
      href={href}
      onClick={onClick}
    >
      {iconDirection === 'left' && t_icon}
      {label}
      {iconDirection === 'right' && t_icon}
    </a>
  );
}
