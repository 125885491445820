import { TextArea6DS } from '../SixSenseElements';

interface Props {
  onChange: (value: string) => void;
  className?: string;
  disabled?: boolean;
  error?: boolean;
  hint?: string;
  infoText?: string;
  label?: string;
  mark?: string;
  placeholder?: string;
  testId?: string;
  textAreaProps?: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
  value?: string;
}
export function TextArea6ds({
  label,
  className = '',
  textAreaProps,
  testId = 'TextArea6ds',
  error,
  disabled,
  ...props
}: Props) {
  return (
    <TextArea6DS.Labeled
      className={className}
      data-testid={testId}
      disabled={disabled}
      error={error}
      label={label}
      textAreaProps={textAreaProps}
      {...props}
    />
  );
}
