import { MouseEventHandler, ReactElement } from 'react';
import { getClass } from '../../../../utils/getClass';
import { Icon } from '../Icon';
import { Spinner } from '../Spinner';
import './Button.scss';

export interface ButtonProps {
  children?: React.ReactNode;
  className?: string;
  destructive?: boolean;
  disabled?: boolean;
  fill?: string;
  form?: string;
  icon?: string | ReactElement;
  iconDirection?: 'left' | 'right';
  iconHeight?: number;
  iconWidth?: number;
  isLoading?: boolean;
  label?: string;
  onClick?: (e?: any) => void;
  onMouseDown?: MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: MouseEventHandler<HTMLButtonElement>;
  size?: 's' | 'm' | 'l';
  testId?: string;
  theme?: 'primary' | 'secondary' | 'flush';
  title?: string;
  type?: 'submit' | 'reset' | 'button';
  variant?: string;
  withEllipsis?: boolean;
}

//TODO: add option to add the icon to the left
export const Button = ({
  children,
  className,
  destructive,
  disabled,
  fill,
  form,
  icon,
  iconDirection = 'left',
  iconWidth,
  iconHeight,
  isLoading,
  label,
  onClick,
  onMouseDown,
  onMouseEnter,
  onMouseLeave,
  size,
  testId = 'Button',
  theme,
  type,
  variant,
  withEllipsis,
  title,
}: ButtonProps) => {
  const themeStyle =
    theme && destructive ? `${theme}--destructive` : !theme && destructive ? 'destructive' : theme;

  const iconElement =
    typeof icon === 'string' ? (
      <Icon
        className={iconDirection === 'left' ? 'mr-5' : 'ml-5'}
        fill={fill}
        height={iconHeight ? iconHeight : undefined}
        name={icon}
        width={iconWidth ? iconWidth : undefined}
      />
    ) : icon && typeof icon !== 'string' ? (
      <div className={`Button--${iconDirection}Icon`}>{icon}</div>
    ) : null;

  return (
    <button
      className={`${className} ${getClass('Button', { themeStyle, size }, variant)}`}
      data-testid={testId}
      disabled={disabled || isLoading}
      form={form}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      title={title}
      type={type || 'button'}
    >
      {isLoading && <Spinner className="Button__spinner" testId={`${testId}__loader`} />}
      <div
        className={`${isLoading ? 'Button__contentHidden' : 'Button__content'} ${
          withEllipsis ? 'Button__content--overflow' : ''
        }`}
        data-testid={`${testId}__content`}
      >
        {iconDirection === 'left' && iconElement}
        {children || label}
        {iconDirection === 'right' && iconElement}
      </div>
    </button>
  );
};
