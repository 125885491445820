import { decode } from 'js-base64';
import { startCase } from 'lodash';
import { Link } from 'react-router-dom';
import { camelcaseKeys } from 'utils/formatting';
import { COMPANY_NAME } from '../../../../config/constants';
import { useQueryString } from '../../../../services/routing';
import { Icon, Button } from '../../Elements';
import styles from './IntegrationReconnectedContainer.module.scss';

export default function IntegrationReconnectedContainer() {
  const dataParam = useQueryString().get('data');

  const data = dataParam ? camelcaseKeys(JSON.parse(decode(dataParam))) : null;
  const teamName = data ? data.teamName : '';
  const integrationType = data ? startCase(data.integrationType) : '';

  const t_header =
    integrationType === 'Salesforce' ? (
      <div className={styles.HeaderWithLogo}>
        <Icon name="integration-salesforce" width={46} />
        <span>+</span>
        <Icon height={32} name="SixSenseLogo" width={41} />
      </div>
    ) : (
      <div className={styles.Header}>
        <Icon height={32} name="SixSenseLogo" width={41} />
      </div>
    );
  return (
    <>
      <div className={styles.Container} data-testid="IntegrationReconnected">
        {t_header}
        <div className={styles.Content}>
          <div className={styles.ContentHeader}>
            {integrationType} Integration successfully reconnected
          </div>
          <div className={styles.ContentDescription}>
            {`${teamName} ${integrationType}`.trim()} Integration has been reconnected with{' '}
            {COMPANY_NAME}.
          </div>
          <Link to="/">
            <Button label={`Go to ${COMPANY_NAME}`} theme="primary" />
          </Link>
        </div>
        <div className={styles.Footer}>
          We're happy to help if you have any questions. You can reach out to us at{' '}
          <a href="mailto:support@6sense.com">support@6sense.com</a>.
        </div>
      </div>
      <div className={styles.Copyright}>
        Copyright © {new Date().getFullYear()} {COMPANY_NAME}, Inc. All rights reserved.
      </div>
    </>
  );
}
