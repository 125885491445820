import { SuppressionListSchema } from '../../../schemas';
import { MultiSelectDropdown, MultiSelectDropdownItemProps } from '../MultiSelectDropdown';
import styles from './SuppressionListsDropdown.module.scss';

interface Props {
  onChange: (Ids: number[]) => void;
  selected: number[];
  suppressionLists: SuppressionListSchema[];
  isDisabled?: boolean;
  onClose?: (suppressionListIds: number[]) => void;
  onOpen?: () => void;
}
export default function SuppressionListsDropdown({
  suppressionLists,
  selected,
  onChange,
  onClose,
  onOpen,
  isDisabled,
}: Props) {
  const requiredSuppressionLists = suppressionLists.filter(s => s.required);
  const configurableSuppressionLists = suppressionLists.filter(s => !s.required);
  const configurableEmailLists = configurableSuppressionLists.filter(s => s.listType === 'email');
  const configurableDomainLists = configurableSuppressionLists.filter(s => s.listType === 'domain');
  const configurableFilterLists = configurableSuppressionLists.filter(s => s.listType === 'filter');

  let emailListsDropdown: MultiSelectDropdownItemProps[] = configurableEmailLists.map(c => {
    return { key: c.id, value: c.id, text: c.name, disabled: false };
  });
  if (emailListsDropdown.length > 0) {
    emailListsDropdown.unshift({
      key: 'emailHeader',
      value: 'emailHeader',
      text: 'EMAIL',
      disabled: true,
    });
  }
  let domainListsDropdown: MultiSelectDropdownItemProps[] = configurableDomainLists.map(c => {
    return { key: c.id, value: c.id, text: c.name, disabled: false };
  });
  if (domainListsDropdown.length > 0) {
    domainListsDropdown.unshift({
      key: 'domainHeader',
      value: 'domainHeader',
      text: 'DOMAIN',
      disabled: true,
    });
  }
  let filterListsDropdown: MultiSelectDropdownItemProps[] = configurableFilterLists.map(c => {
    return { key: c.id, value: c.id, text: c.name, disabled: false };
  });
  if (filterListsDropdown.length > 0) {
    filterListsDropdown.unshift({
      key: 'filterHeader',
      value: 'filterHeader',
      text: 'FILTER',
      disabled: true,
    });
  }

  const requiredDropdownSelection = requiredSuppressionLists.map(c => {
    return { key: c.id, value: c.id, text: c.name };
  });
  const dropdownSelection = emailListsDropdown
    .concat(domainListsDropdown)
    .concat(filterListsDropdown);

  const handleOnChange = (value: number[]) => {
    onChange(value);
  };

  return (
    <>
      <MultiSelectDropdown
        disabled
        selection
        className={styles.RequiredSuppressionList}
        label={'Required suppression lists'}
        options={requiredDropdownSelection}
        testId="SuppressionListsDropdown__required"
        value={requiredSuppressionLists.map(c => c.id)}
      />
      <MultiSelectDropdown
        search
        selection
        className={styles.OptionalSuppressionList}
        data-testid="SuppressionListsDropdown__configurableDropdown"
        disabled={isDisabled}
        label={'Optional suppression lists'}
        onChange={handleOnChange}
        onClose={(_, { value }) => {
          onClose && onClose(value as number[]);
        }}
        onOpen={() => onOpen && onOpen()}
        options={dropdownSelection}
        placeholder="Select or search for lists"
        testId="SuppressionListsDropdown__configurable"
        value={selected}
      />
    </>
  );
}
