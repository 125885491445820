import { capitalize, isEmpty } from 'lodash';
import { SHORT_COMPANY_NAME } from '../../config/constants';
import { formatStringWithAI } from '../formatting';

/**
 * Get's the route name in a format we expect
 * Useful for dynamic routes
 * i.e. /campaign_launcher/:id/details/preview -> campaign_launcher/details/preview
 */
export const formatRouteName = (path: string) => {
  return path
    .split('/')
    .slice(1)
    .reduce((updatedPath, p, i) => {
      const parsed = parseInt(p, 10);
      if (isNaN(parsed)) return updatedPath + `${i === 0 ? p : `/${p}`}`; // removes any potentials ids from the path. also doesn't append / at the start of the string
      return updatedPath;
    }, '');
};
/**
 * Some routes are treated differently in their naming conventions for our analytics tool,
 * This function is a collection of conditions to set their Event Names and Groups accordingly
 * Separated from the main page event handler as this isn't as readable
 */
export const checkIfSpecialRouteForAnalytics = ({
  event,
  group,
  routeName,
}: {
  event: string;
  group: string;
  routeName: string;
}) => {
  let eventName = event;
  let groupName = group;

  if (routeName === 'ai_email_generator') {
    eventName = 'View June';
    groupName = 'june';
  }
  if (routeName.includes('integration')) {
    groupName = 'integrations';
  }
  if (routeName.includes('login')) {
    groupName = 'login';
  }
  if (routeName.includes('password') && routeName !== 'password_login') {
    groupName = 'password';
  }
  if (routeName.includes('salesforce')) {
    groupName = 'integrations_salesforce';
  }
  if (
    routeName === 'email_confirmation' ||
    routeName === 'confirmation_email_sent' ||
    routeName === 'hello'
  ) {
    groupName = 'onboarding';
  }
  if (routeName.includes('/ai_inbox')) {
    groupName = 'settings_ai';
  }

  return {
    eventName,
    groupName,
  };
};
/**
 * This is to map the beginning of pathnames, to what we want to display as page
 * title in our global top nav bar.
 */
export const selectBasePathName = (path: string) => {
  if (isEmpty(path)) return '';
  return `${path.split(/[/|?]/)[1]}`;
};
const mapTitleFromPath = (path: string) => {
  switch (path) {
    case 'attachments':
    case 'campaign launcher':
      return 'campaigns';
    case 'contacts':
    case 'accounts':
    case 'suppression lists':
      return 'people';
    case 'human review tasks':
    case 'unsolicited':
      return 'human review';
    case 'settings':
      return 'general settings';
    default:
      return path;
  }
};
export const pageTitleFromPath = (pathname: string) => {
  // formats away any link extensions or query params
  const path = selectBasePathName(pathname).split('_').join(' ');
  if (path === '') return SHORT_COMPANY_NAME;
  const title = mapTitleFromPath(path);
  return formatStringWithAI(capitalize(title));
};
