import { NavLink } from 'react-router-dom';
import { Badge } from '../Badge';
import styles from './NavLinkTab.module.scss';

interface PropsType {
  children: React.ReactNode;
  linkTo: string;
  badgeContent?: string | number;
  exact?: boolean;
  onClick?: () => void;
  testId?: string;
}

export const NavLinkTab = ({
  children,
  exact,
  testId = 'NavLinkTab',
  linkTo,
  onClick,
  badgeContent,
}: PropsType) => {
  const stringifiedBadgeContent = badgeContent?.toString();
  return (
    <NavLink
      activeClassName={styles.ActiveNavItem}
      className={styles.NavItem}
      data-testid={testId}
      exact={exact}
      onClick={onClick}
      to={linkTo}
    >
      {children}
      {stringifiedBadgeContent && (
        <Badge
          className={styles.Badge}
          color="gray"
          label={stringifiedBadgeContent}
          testId={`${testId}Badge`}
        />
      )}
    </NavLink>
  );
};
