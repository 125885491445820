import { FeaturedIconProps } from '@saleswhale/barnacle/components';
import { isEmpty } from 'lodash';
import { ReactElement } from 'react';
import { FeaturedIcon6DS } from '../SixSenseElements';
import styles from './OverviewCard.module.scss';

interface Props {
  children: ReactElement | ReactElement[];
  headerText: string;
  action?: ReactElement | ReactElement[];
  bodyClassName?: string;
  featuredIcon?: FeaturedIconProps;
  footer?: ReactElement | ReactElement[];
  noPadding?: boolean;
  subHeaderText?: string;
  testId?: string;
}

export function OverviewCard({
  action,
  children,
  featuredIcon,
  headerText,
  subHeaderText,
  noPadding,
  footer,
  bodyClassName = '',
  testId = 'OverviewCard',
}: Props) {
  const t_header = (
    <div className={styles.HeaderContainer} data-testid={`${testId}__header`}>
      <div className={styles.LeftContainer} data-testid={`${testId}__headerLeft`}>
        {featuredIcon && <FeaturedIcon6DS theme="light-circle" {...featuredIcon} />}
        <div className={styles.TextContainer}>
          <h1 className={styles.HeaderText} data-testid={`${testId}__headerText`}>
            {headerText}
          </h1>
          {!isEmpty(subHeaderText) && <p className={styles.SubHeaderText}>{subHeaderText}</p>}
        </div>
      </div>
      {action && (
        <div className={styles.Action} data-testid={`${testId}__headerAction`}>
          {action}
        </div>
      )}
    </div>
  );

  const t_body = (
    <div
      className={`${!noPadding && styles.BodyContainer} ${bodyClassName}`}
      data-testid={`${testId}__bodyContainer`}
    >
      {children}
    </div>
  );

  const t_footer = footer ? (
    <div className={styles.Footer} data-testid={`${testId}__footer`}>
      {footer}
    </div>
  ) : null;

  return (
    <div className={styles.Container} data-testid={testId}>
      {t_header}
      {t_body}
      {t_footer}
    </div>
  );
}
