import { Link } from 'react-router-dom';
import { Button, LayoutContainer, Typography } from '../../Elements';
import styles from '../Utils.module.scss';

export default function PageDoesNotExist() {
  return (
    <LayoutContainer testId="PageDoesNotExist">
      <div className={styles.Container}>
        <div className={styles.Content}>
          <Typography tag="h2" variant={styles.Heading}>
            Oops!
          </Typography>
          <h3 className={styles.SubHeading}>That page doesn't exist.</h3>
          <p className={styles.Text}>
            <strong>Error code:</strong> 404
          </p>
          <p className={styles.Text}>
            We can't seem to find the page you're looking for. Report this error or go to the home
            page.
          </p>
          <div>
            <Link to="/">
              <Button label="Go to home" size="l" />
            </Link>
          </div>
        </div>
        <img
          alt="failwhale"
          className={styles.Image}
          src="https://static.saleswhale.com/rep/images/figure_failwhale_1.png"
        />
      </div>
    </LayoutContainer>
  );
}
