import mixpanel from 'mixpanel-browser';
import { MIXPANEL_ID } from '../config/environment';

export const initializeMixpanel = () => {
  if (process.env.REACT_APP_DISABLE_VENDORS === 'true') {
    return mixpanel.init('PEKOPEKOPEKO');
  }
  const debug = process.env.REACT_APP_APP_ENV !== 'prod';
  return mixpanel.init(MIXPANEL_ID, { debug, persistence: 'localStorage' });
};
