import { PlusSquare, SearchSm } from '@saleswhale/barnacle/icons';
import { KeyboardEventHandler, ReactNode, useEffect, useReducer, useRef } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useDropdownDirection } from '../../../../services/dropdown';
import { deserializeFilters, serializeFilters } from '../../../../utils/filterUtils';
import {
  FilterableFieldSchema,
  SelectedFilterOperator,
  SelectedFilterSchema,
  SerializedFiltersSchema,
} from '../../../schemas/filterableField';
import { Button } from '../Button';
import { Input } from '../Input';
import { sendAddFilterEvent } from './AddFilterDropdown.tracking';
import SelectedFilters from './SelectedFilters';
import styles from './AddFilterDropdown.module.scss';

interface State {
  query: string;
  selectedFilterOperator: SelectedFilterOperator;
  selectedFilters: SelectedFilterSchema[];
}
interface Props {
  filters: FilterableFieldSchema[];
  isLoading: boolean;
  updateConfirmedFilters: (serializedFilters: SerializedFiltersSchema | null) => void;
  activeFilters?: SerializedFiltersSchema;
  addFilterLabel?: string;
  children?: ReactNode;
  isAddDisabled?: boolean;
  resetLabel?: string;
  testId?: string; // Disables adding/removing of filters
}
type Action =
  | { type: 'resetQuery' }
  | {
      data: {
        selectedFilterOperator: SelectedFilterOperator;
        selectedFilters: SelectedFilterSchema[];
      };
      type: 'onActiveFiltersChange';
    }
  | { data: { selectedFilters: SelectedFilterSchema[] }; type: 'updateSelectedFilters' }
  | {
      data: {
        selectedFilterOperator: SelectedFilterOperator;
        selectedFilters: SelectedFilterSchema[];
      };
      type: 'removeFilter';
    }
  | {
      data: { selectedFilterOperator: SelectedFilterOperator };
      type: 'updateSelectedFilterOperator';
    }
  | {
      data: {
        index: number;
        selectedFilterCondition: SelectedFilterSchema['selectedFilterCondition'];
      };
      type: 'updateFilterCondition';
    }
  | {
      data: {
        filter: FilterableFieldSchema;
      };
      type: 'selectFilter';
    }
  | {
      data: {
        index: number;
        values: SelectedFilterSchema['values'];
      };
      type: 'updateFilterValues';
    }
  | {
      data: {
        requiredFilters: SelectedFilterSchema[];
      };
      type: 'resetFilters';
    }
  | { data: { query: string }; type: 'updateQuery' };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'onActiveFiltersChange': {
      const { selectedFilters, selectedFilterOperator } = action.data;
      /**
       * We already default select a filterCondition when adding a filter.
       * However, a filterCondition is stripped off during encodeFilters if it has an undefined filter value.
       * This will catch any undefined selectedFilterCondition coming from the filters query
       */
      const formattedSelectedFilters = selectedFilters.map(f => {
        if (f.selectedFilterCondition) return f;
        return { ...f, selectedFilterCondition: f.availableConditions[0].value };
      });
      return {
        ...state,
        selectedFilters: formattedSelectedFilters,
        selectedFilterOperator,
      };
    }
    case 'updateSelectedFilters': {
      const { selectedFilters } = action.data;
      return {
        ...state,
        selectedFilters,
      };
    }
    case 'removeFilter': {
      const { selectedFilters, selectedFilterOperator } = action.data;
      return {
        ...state,
        selectedFilters,
        selectedFilterOperator,
      };
    }
    case 'updateSelectedFilterOperator': {
      const { selectedFilterOperator } = action.data;
      return {
        ...state,
        selectedFilterOperator,
      };
    }
    case 'selectFilter': {
      const { filter } = action.data;
      return {
        ...state,
        selectedFilters: [
          ...state.selectedFilters,
          {
            ...filter,
            selectedFilterCondition: filter.availableConditions[0].value,
          }, // we want to default to select the first available condition in each filter
        ],
      };
    }
    case 'updateFilterCondition': {
      const { index, selectedFilterCondition } = action.data;
      const { selectedFilters } = state;
      return {
        ...state,
        selectedFilters: selectedFilters.map((f, i) => {
          const { values, ...restOfFilter } = f; // eslint-disable-line
          return i === index
            ? {
                ...restOfFilter,
                selectedFilterCondition,
              }
            : f;
        }),
      };
    }
    case 'updateFilterValues': {
      const { index, values } = action.data;
      const { selectedFilters } = state;
      return {
        ...state,
        selectedFilters: selectedFilters.map((f, i) =>
          i === index ? ({ ...f, values } as SelectedFilterSchema) : f
        ),
      };
    }
    case 'updateQuery': {
      const { query } = action.data;
      return {
        ...state,
        query,
      };
    }
    case 'resetQuery': {
      return {
        ...state,
        query: '',
      };
    }
    case 'resetFilters': {
      const { requiredFilters } = action.data;
      return {
        ...state,
        selectedFilterOperator: 'and',
        selectedFilters: requiredFilters,
      };
    }
    default:
      return state;
  }
};
const initialState: State = {
  query: '',
  selectedFilters: [],
  selectedFilterOperator: 'and',
};

export function AddFilterDropdown({
  children,
  filters,
  activeFilters,
  addFilterLabel = 'Add filter',
  resetLabel = 'Reset filters',
  testId = 'AddFilterDropdown',
  updateConfirmedFilters,
  isLoading,
  isAddDisabled,
}: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { query, selectedFilters, selectedFilterOperator } = state;
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const dropdownDirection = useDropdownDirection(dropdownRef);

  useEffect(() => {
    if (activeFilters) {
      const [deserializedFilters, deserializedOperator] = deserializeFilters(
        activeFilters,
        filters
      );
      dispatch({
        type: 'onActiveFiltersChange',
        data: {
          selectedFilters: deserializedFilters,
          selectedFilterOperator: deserializedOperator,
        },
      });
    }
  }, [activeFilters, filters]);

  const selectableFilters = filters.filter(f => !f.readOnly);
  const filteredFilters = selectableFilters.filter(o =>
    o.fieldLabel.toLowerCase().includes(query.toLowerCase())
  );
  const filtersToDisplay = query ? filteredFilters : selectableFilters;
  const doesNotHaveFiltersAdded = selectedFilters.filter(f => !f.readOnly).length === 0;

  const resetQuery = () => {
    dispatch({ type: 'resetQuery' });
  };
  const handleUpdateConfirmedFilters = (
    confirmedFilters: SelectedFilterSchema[],
    selectedFilterOperator: SelectedFilterOperator
  ) => {
    // can probably refactor in the future with a custom state hook(?) to track validSelectedFilters previous value
    const [previousSelectedFilters] =
      activeFilters !== undefined ? deserializeFilters(activeFilters, filters) : [[]];
    if (
      previousSelectedFilters &&
      confirmedFilters.length > 0 &&
      previousSelectedFilters.length !== confirmedFilters.length
    ) {
      sendAddFilterEvent({
        filters: confirmedFilters,
        page: confirmedFilters[0].resourceType,
      });
    }
    const formattedFilters = confirmedFilters.length
      ? serializeFilters(confirmedFilters, selectedFilterOperator)
      : null;
    updateConfirmedFilters(formattedFilters);
  };
  const selectFilter = (filter: FilterableFieldSchema) => {
    dispatch({ type: 'selectFilter', data: { filter } });
  };
  const removeFilter = (index: number) => {
    const updatedSelectedFilters = selectedFilters.filter((f, i) => i !== index);
    const selectedFilterOperatorToUse =
      updatedSelectedFilters.length <= 1 ? 'and' : selectedFilterOperator;
    dispatch({
      type: 'removeFilter',
      data: {
        selectedFilters: updatedSelectedFilters,
        selectedFilterOperator: selectedFilterOperatorToUse,
      },
    });
    handleUpdateConfirmedFilters(updatedSelectedFilters, selectedFilterOperatorToUse);
  };
  const updateSelectedFilterCondition = (
    index: number,
    selectedFilterCondition: SelectedFilterSchema['selectedFilterCondition']
  ) => {
    dispatch({ type: 'updateFilterCondition', data: { index, selectedFilterCondition } });
  };
  const updateValues = (index: number, values: SelectedFilterSchema['values']) => {
    dispatch({ type: 'updateFilterValues', data: { index, values } });
  };
  const updateValuesAndConfirmFilters = (index: number, values: SelectedFilterSchema['values']) => {
    const newSelectedFilters = selectedFilters.map((f, i) =>
      i === index ? ({ ...f, values } as SelectedFilterSchema) : f
    );
    dispatch({ type: 'updateSelectedFilters', data: { selectedFilters: newSelectedFilters } });
    handleUpdateConfirmedFilters(newSelectedFilters, selectedFilterOperator);
  };
  const updateSelectedFilterOperator = (filterOperator: SelectedFilterOperator) => {
    dispatch({
      type: 'updateSelectedFilterOperator',
      data: { selectedFilterOperator: filterOperator },
    });
    handleUpdateConfirmedFilters(selectedFilters, filterOperator);
  };
  const confirmFilters = () => {
    dispatch({ type: 'updateSelectedFilters', data: { selectedFilters } });
    handleUpdateConfirmedFilters(selectedFilters, selectedFilterOperator);
  };
  const resetFilters = () => {
    if (selectedFilters.length > 0) {
      const requiredFilters = selectedFilters.filter(f => f.readOnly);
      dispatch({ type: 'resetFilters', data: { requiredFilters } });
      handleUpdateConfirmedFilters(requiredFilters, selectedFilterOperator);
    }
  };
  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === ' ' || e.keyCode === 32) {
      // Prevent closing of dropdown if spacebar
      e.stopPropagation();
    }
  };

  const t_addFilterButton = (
    <Button
      className={styles.AddButton}
      disabled={isLoading}
      icon={<PlusSquare size={18} />}
      label={addFilterLabel}
      testId={`${testId}__button`}
      theme="flush"
    />
  );
  return (
    <div className={styles.Container}>
      <SelectedFilters
        {...(!isAddDisabled && { removeFilter })}
        confirmFilters={confirmFilters}
        isLoading={isLoading}
        selectedFilterOperator={selectedFilterOperator}
        selectedFilters={selectedFilters}
        updateSelectedFilterCondition={updateSelectedFilterCondition}
        updateSelectedFilterOperator={updateSelectedFilterOperator}
        updateValues={updateValues}
        updateValuesAndConfirmFilters={updateValuesAndConfirmFilters}
      />
      {!isAddDisabled && (
        <div ref={dropdownRef}>
          <Dropdown
            className={styles.Dropdown}
            data-testid={testId}
            direction={dropdownDirection}
            disabled={isLoading}
            icon={null}
            onClose={resetQuery}
            search={false}
            trigger={t_addFilterButton}
            upward={false}
          >
            <Dropdown.Menu
              className={styles.DropdownMenu}
              data-testid="AddFilterDropdown__dropdown"
            >
              <Input
                autoFocus
                className={styles.SearchField}
                iconLeft={<SearchSm color="#6C737F" />}
                inputClassName={styles.SearchFieldInput}
                name="Search"
                onChange={e =>
                  dispatch({ type: 'updateQuery', data: { query: e.currentTarget.value } })
                }
                onClick={e => e.stopPropagation()}
                onKeyDown={onKeyDown}
                placeholder="Search or select filters"
                testId={`${testId}__search`}
                value={query}
              />
              <Dropdown.Menu
                scrolling
                className={styles.ScrollingMenu}
                data-testid={`${testId}__menu`}
              >
                {filtersToDisplay.length > 0 ? (
                  filtersToDisplay.map((filter, i) => (
                    <Dropdown.Item
                      className={styles.DropdownItem}
                      data-testid={filter.fieldName}
                      key={i}
                      onClick={() => selectFilter(filter)}
                      value={filter.fieldName}
                    >
                      <span className={styles.DropdownItemValue}>{filter.fieldLabel}</span>
                    </Dropdown.Item>
                  ))
                ) : (
                  <div className={styles.NoResults} data-testid={`${testId}__noResults`}>
                    <span>No results.</span>
                  </div>
                )}
              </Dropdown.Menu>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
      {children}
      {!isAddDisabled && (
        <Button
          disabled={doesNotHaveFiltersAdded}
          label={resetLabel}
          onClick={resetFilters}
          testId={`${testId}__resetButton`}
          theme="flush"
        />
      )}
    </div>
  );
}
