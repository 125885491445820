import { compact, find, isArray } from 'lodash';
import { SetStateAction, useEffect, useState } from 'react';
import { EngageAPIError } from '../../app/schemas/error';
import { getJSON } from '../../services/fetch';

/**
 * TODO: think of a way to cancel these maybe
 * Takes promises and sets a state's loading state
 * @param f
 * @param setLoading
 */
export function getAPIData(f: Promise<any> | Promise<any>[], setLoading?: SetStateAction<any>) {
  setLoading && setLoading(true);
  if (isArray(f)) {
    return Promise.all(f).finally(() => {
      setLoading && setLoading(false);
    });
  } else {
    return f.finally(() => {
      setLoading && setLoading(false);
    });
  }
}

type LoadableStatus = 'isLoading' | 'hasValue' | 'hasError' | 'isInitializing';

export interface APIResponse<T = any> {
  data: T | undefined;
  error: Error | EngageAPIError | unknown;
  status: LoadableStatus;
}

export function useGet(url: string) {
  const [response, setResponse] = useState<APIResponse>({
    status: 'isInitializing',
    data: undefined,
    error: undefined,
  });

  useEffect(() => {
    if (!url) return;
    (async () => {
      try {
        setResponse(r => {
          return { ...r, status: 'isLoading' };
        });
        const data = await getJSON(url);
        setResponse(r => {
          return { ...r, status: 'hasValue', data };
        });
      } catch (error) {
        setResponse(r => {
          return { ...r, status: 'hasError', error };
        });
      }
    })();
  }, [url]);

  return response;
}

export interface IncludedSchema {
  attributes: { [key: string]: unknown };
  id: string | number;
  type: string;
}

export const mapRelationships = <T extends IncludedSchema>(
  { data }: { data: { id: string | number; type: string }[] | null },
  included?: IncludedSchema[]
): T[] => {
  if (!data || !included) return [];
  return compact(data.map(({ type, id }) => find(included, { type, id }) as T));
};
export const mapRelationship = <T extends IncludedSchema>(
  { data }: { data: { id: string | number; type: string } | null },
  included?: IncludedSchema[]
): T | null => {
  if (!data || !included) return null;
  const { type, id } = data;
  return find(included, { type, id }) as T;
};
