import { cloneDeep, merge } from 'lodash';
import { ChangeEvent, SetStateAction, Reducer, useReducer, useState, useEffect } from 'react';

export const VALID_EMAIL_REGEX =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
export const VALID_DOMAIN_REGEX = /^([^@]*\.[^@]*)+$/i;
interface FormState {
  fields: { [key: string]: unknown };
  isSubmitting: boolean;
  validationErrors: { [key: string]: string };
}
type Action =
  | {
      message: string;
      name: string | number | symbol;
      type: 'UPDATE_INPUT';
      value: any;
    }
  | {
      type: 'RESET' | 'START_SUBMIT' | 'END_SUBMIT';
    };

// useForm - Returns a formState, that follows the FormState structure, along with some actions to modify the state
export const useForm = <T extends FormState>(initialState: T) => {
  const formReducer = (state: T, action: Action) => {
    switch (action.type) {
      case 'UPDATE_INPUT':
        return merge(cloneDeep(state), {
          fields: { [action.name]: action.value },
          validationErrors: { [action.name]: action.message },
        });
      case 'RESET':
        return initialState;
      case 'START_SUBMIT':
        return { ...state, isSubmitting: true };
      case 'END_SUBMIT':
        return { ...state, isSubmitting: false };
      default:
        break;
    }
    return state;
  };
  const [formState, dispatch] = useReducer<Reducer<T, Action>>(formReducer, initialState);

  // Updates a field's value and its validation error message
  const updateInput = <U extends keyof T['fields'], V extends T['fields'][U]>(
    name: U,
    value: V,
    message: string
  ) => {
    dispatch({ type: 'UPDATE_INPUT', name, value, message });
  };

  // Reset values to initialFormState
  const resetForm = () => dispatch({ type: 'RESET' });

  // Set 'isSubmitting' to true
  const startSubmit = () => dispatch({ type: 'START_SUBMIT' });

  // Set 'isSubmitting' to false
  const endSubmit = () => dispatch({ type: 'END_SUBMIT' });

  // Returns true if all fields have no validation error message
  const isFormValid = () => {
    let isFormValid = true;
    for (const key in formState.validationErrors) {
      const prop = key as keyof typeof formState.validationErrors;
      isFormValid = isFormValid && !formState.validationErrors[prop];
    }
    return isFormValid;
  };
  return { formState, updateInput, isFormValid, resetForm, startSubmit, endSubmit };
};

export const handleFormUpdate = (e: ChangeEvent<any>, set: SetStateAction<any>) => {
  const { name, value } = e.target;
  set((state: any) => state && { ...state, [name]: value });
};

export const isEmptyString = (value: string) => value.trim().length === 0;
export const isValidEmail = (value: string) => VALID_EMAIL_REGEX.test(value);
export const isValidDomain = (value: string) => VALID_DOMAIN_REGEX.test(value);
export const isValidUrl = (value: string) =>
  /^(http|https):\/\/[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(([0-9]{1,5})?\/\.*)?/i.test(value);
export const isLooselyValidUrl = (value: string) =>
  /^(?:(http|https):\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(([0-9]{1,5})?\/\.*)?/i.test(
    value
  );
export const isEmptyValues = (value: any) => {
  return (
    value === undefined ||
    value === null ||
    Number.isNaN(value) ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && isEmptyString(value))
  );
};
/** Reference: https://usehooks-ts.com/react-hook/use-debounce */
export function useDebounce<T>(value: T, delay = 1000): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);
    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);
  return debouncedValue;
}

/**
 * Generates an array of numeric options for SingleSelect component
 * @param end - the end length of the range
 * @returns an array of objects with label and value properties
 */
export function generateNumOptions(end: number): {
  label: string;
  value: number;
}[] {
  return Array.from({ length: end }, (_, index) => ({
    label: (index + 1).toString(),
    value: index + 1,
  }));
}
