interface AccordionRingProps {
  currentStep: number;
  disabled: boolean;
  size: number;
  strokeColor: string;
  strokeWidth: number;
  totalSteps: number;
  variant?: string;
}

export const AccordionRing = ({
  variant = '',
  size,
  strokeWidth,
  strokeColor,
  currentStep,
  totalSteps,
  disabled,
}: AccordionRingProps) => {
  const center = size / 2;
  const radius = center - strokeWidth * 2;
  const circumference = radius * 2 * Math.PI;
  const offset = (1 - currentStep / totalSteps) * circumference;
  const isComplete = currentStep === totalSteps;

  const style = {
    width: size,
    height: size,
  };
  const offsetStyle = {
    strokeDasharray: `${circumference} ${circumference}`,
    strokeDashoffset: `${offset}px`,
    transform: 'rotate(-90deg)',
    transformOrigin: 'center',
  };

  return (
    <svg
      className={`AccordionRing ${variant}`}
      data-testid={`AccordionRing${isComplete ? '__complete' : ''}`}
      style={style}
    >
      <circle
        cx={center}
        cy={center}
        fill="transparent"
        r={radius}
        stroke="#E5E7EB"
        strokeWidth={strokeWidth}
      />
      <circle
        cx={center}
        cy={center}
        fill="transparent"
        r={radius}
        stroke={disabled ? '#E5E7EB' : strokeColor}
        strokeWidth={strokeWidth}
        style={offsetStyle}
      />
    </svg>
  );
};
