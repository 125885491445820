import { ReactNode, SetStateAction, useEffect, useRef } from 'react';
import { Icon } from '..';
import styles from './Video.module.scss';

interface Props {
  id: string;
  isActive: boolean;
  mp4: string;
  setIsActive: SetStateAction<any>;
  className?: string;
  trigger?: ReactNode;
}

export function Video({ className, id, isActive, mp4, setIsActive, trigger }: Props) {
  const body = document.querySelector('body');
  const videoRef = useRef<HTMLVideoElement>(null);

  const play = () => {
    if (videoRef.current) {
      setIsActive(true);
      body && (body.style.overflow = 'hidden');
      videoRef.current.play();
    }
  };

  const pause = () => {
    if (videoRef.current) {
      setIsActive(false);
      body && (body.style.overflow = 'auto');
      videoRef.current.pause();
    }
  };

  useEffect(() => {
    function closeOverlay(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        setIsActive(false);
      }
    }
    window.addEventListener('keydown', closeOverlay);

    return () => {
      window.removeEventListener('keydown', closeOverlay);
    };
  }, [setIsActive]);

  return (
    <div className={className ? className : ''} data-testid="VideoElement">
      {trigger ? (
        <div onClick={play}>{trigger}</div>
      ) : (
        <div className={styles.Container}>
          <div className={styles.Overlay}></div>
          <video preload="auto">
            <source src={mp4} type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
          </video>

          <button
            className={`${styles.Icon} ${styles.Icon__circle}`}
            data-testid="VideoElement__trigger"
            onClick={play}
          >
            <svg
              aria-hidden="true"
              height="180"
              shapeRendering="geometricPrecision"
              width="180"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="90" cy="90" r="45"></circle>
            </svg>
            <Icon className={styles.Icon__play} name="Play" />
          </button>
        </div>
      )}
      <div
        className={`${styles.Modal} ${isActive ? styles.Modal__active : ''}`}
        data-testid="VideoElement__modal"
        id={id}
      >
        <div
          className={`${styles.Icon} ${styles.Icon__close}`}
          data-testid="VideoElement__close"
          onClick={pause}
        ></div>
        <div className={styles.Modal__content}>
          <video
            controls
            controlsList="nodownload"
            data-testid="VideoElement__video"
            preload="auto"
            ref={videoRef}
          >
            <source src={mp4} type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
          </video>
        </div>
      </div>
    </div>
  );
}
