import { AlertTriangle } from '@saleswhale/barnacle/icons';
import { Modal, ModalProps } from '../Modal';

export function UnsavedChangesModal({
  className,
  isOpen,
  onSecondary,
  setIsOpen,
  secondary = 'Discard changes',
  children,
  testId = 'UnsavedChangesModal',
  title = 'You have unsaved changes',
  onClose,
}: ModalProps) {
  return (
    <Modal
      isCancellable
      children={children}
      className={className}
      featuredIcon={{
        icon: AlertTriangle,
        color: 'Warning',
      }}
      isOpen={isOpen}
      onClose={onClose}
      onSecondary={onSecondary}
      secondary={secondary}
      setIsOpen={setIsOpen}
      size="small"
      testId={testId}
      title={title}
    />
  );
}
