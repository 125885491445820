import { FeaturedIconProps } from '@saleswhale/barnacle/components';
import { ReactNode } from 'react';
import { Modal as UiModal } from 'semantic-ui-react';
import { Button, ButtonProps } from '../Button';
import { FeaturedIcon6DS } from '../SixSenseElements';
import { Spinner } from '../Spinner';
import './Modal.scss';

export interface ModalProps {
  children: ReactNode;
  isOpen: boolean;
  bodyClassName?: string;
  cancel?: string;
  cancelButtonProps?: ButtonProps;
  className?: string;
  closeIconClassName?: string;
  closeOnDimmerClick?: boolean;
  dimmerClassName?: string;
  featuredIcon?: FeaturedIconProps;
  form?: string;
  hideCloseIcon?: boolean;
  isCancellable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  onCancel?: () => void;
  onClose?: () => void;
  onOpen?: () => void;
  onPrimary?: () => void;
  onSecondary?: () => void;
  primary?: string;
  primaryButtonProps?: ButtonProps;
  primaryButtonSpinner?: boolean;
  secondary?: string;
  secondaryButtonProps?: ButtonProps;
  setIsOpen?: (isOpen: boolean) => void;
  size?: 'small' | 'medium' | 'large';
  testId?: string;
  title?: string;
}

export const Modal = (props: ModalProps) => {
  const {
    size = 'medium',
    className,
    title,
    children,
    form,
    primary = 'Save',
    secondary = 'Discard',
    cancel = 'Cancel',
    isOpen,
    isCancellable,
    isDisabled,
    isLoading,
    testId = 'Modal',
    setIsOpen,
    onOpen,
    onClose,
    onCancel,
    onPrimary,
    onSecondary,
    featuredIcon,
    bodyClassName = '',
    closeOnDimmerClick,
    dimmerClassName = '',
    hideCloseIcon = false,
    primaryButtonProps,
    closeIconClassName,
    secondaryButtonProps,
    cancelButtonProps,
    primaryButtonSpinner,
  } = props;
  const modalClassName = size !== 'medium' ? `Modal__${size}` : '';
  const noFooter = !onPrimary && !onSecondary && !isCancellable && !isLoading;

  const handleClose = () => {
    setIsOpen && setIsOpen(false);
    onClose && onClose();
  };

  return (
    <UiModal
      className={`Modal ${modalClassName} ${className || ''}`}
      closeOnDimmerClick={closeOnDimmerClick}
      data-testid={testId}
      dimmer={<UiModal.Dimmer className={dimmerClassName} />}
      onClose={handleClose}
      onOpen={onOpen}
      open={isOpen}
    >
      {featuredIcon && (
        <div className={`Modal__featuredIconContainer`}>
          <FeaturedIcon6DS size="lg" theme="light-circle-outline" {...featuredIcon} />
        </div>
      )}
      {title && (
        <div className="Modal__header" data-testid={`${testId}__title`}>
          {title}
        </div>
      )}
      {!hideCloseIcon && (
        <Button
          className={`Modal__closeIcon ${closeIconClassName}`}
          icon="message-close"
          iconWidth={12}
          onClick={handleClose}
          testId="Modal__buttonClose"
          theme="flush"
        />
      )}
      <div className={`Modal__body ${bodyClassName}`} data-testid={`${testId}__body`}>
        {children}
      </div>
      {!noFooter && (
        <div className="Modal__footer">
          {isLoading && !primaryButtonSpinner && <Spinner />}
          {isCancellable && (
            <Button
              disabled={isLoading}
              label={cancel}
              onClick={onCancel ? onCancel : handleClose}
              testId="Modal__buttonCancel"
              theme="secondary"
              {...cancelButtonProps}
            />
          )}
          {onSecondary && (
            <Button
              destructive
              disabled={isDisabled || isLoading}
              label={secondary}
              onClick={onSecondary}
              testId="Modal__buttonSecondary"
              theme="primary"
              {...secondaryButtonProps}
            />
          )}
          {onPrimary && (
            <Button
              disabled={isDisabled || isLoading}
              form={form}
              icon={isLoading && primaryButtonSpinner ? <Spinner /> : undefined}
              label={primary}
              onClick={onPrimary}
              testId="Modal__buttonPrimary"
              theme="primary"
              type={form ? 'submit' : 'button'}
              {...primaryButtonProps}
            />
          )}
        </div>
      )}
    </UiModal>
  );
};
