import PageDoesNotExist from 'app/components/Utils/404';
import { lowerCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { COMPANY_NAME } from '../../../../config/constants';
import { useIsAuthenticated } from '../../../../services/auth';
import { getAPIData } from '../../../../utils/APIUtils';
import { getTaskByUuid } from '../../../adapters/humanReviewTask';
import { HumanReviewTaskSchema } from '../../../schemas';
import { Icon, LayoutContainer, Typography, Button, didError, Loading } from '../../Elements';
import styles from './FromEmail.module.scss';

export default function FromEmailContainer() {
  const { uuid, status } = useParams<{ uuid: string; status?: string }>();
  const { path } = useRouteMatch();
  const history = useHistory();

  const isAssigning = path.includes('assign');
  const isUpdating = path.includes('update');
  const isCanceling = path.includes('cancel');
  const isContinuing = path.includes('continue');
  const isSuccess = status === 'success';
  const isFail = status === 'fail';
  const isAuthenticated = useIsAuthenticated();
  const [task, setTask] = useState<HumanReviewTaskSchema | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    try {
      getAPIData(getTaskByUuid(uuid, setTask), setIsLoading);
    } catch (e) {
      didError(e);
    }
  }, [uuid]);

  if (isLoading) return <Loading />;
  if (!task) return <PageDoesNotExist />;

  const isAssigned = task.state === 'assigned';
  const isCanceled = task.state === 'canceled';
  const isCompleted = task.state === 'completed';
  const formattedLeadResult = task.leadResult ? lowerCase(task.leadResult).toUpperCase() : null;

  const { lead, salesRep, owner } = task;
  const leadName = lead.firstName;
  const repName = salesRep?.firstName;
  const ownerName = owner?.firstName;
  const t_failText = isAssigned ? (
    <span>
      It seems like {leadName} has already been assigned to {repName}. Did you click on this link
      twice? Or did you forward this link to someone else? If this wasn't handled by you or your
      team - contact us at <a href="mailto:support@6sense.com">support@6sense.com</a>
    </span>
  ) : isCanceled ? (
    <span>
      It seems like this task has already been canceled. Did you click on this link twice? Or did
      you forward this link to someone else? If this wasn't handled by you or your team - contact us
      at <a href="mailto:support@6sense.com">support@6sense.com</a>
    </span>
  ) : isCompleted ? (
    <span>
      It seems like {leadName} has already been marked as {formattedLeadResult}. Did you click on
      this link twice? Or did you forward this link to someone else? If this wasn't handled by you
      or your team - contact us at <a href="mailto:support@6sense.com">support@6sense.com</a>
    </span>
  ) : (
    <span>
      Something seems to have gone wrong. If this wasn't handled by you or your team - contact us at{' '}
      <a href="mailto:support@6sense.com">support@6sense.com</a>
    </span>
  );
  const t_successText = isAssigning ? (
    <span>
      {leadName} has been successfully assigned to {repName}. We will update {leadName}'s records
      and status once we hear back from {repName}. Thanks {ownerName}!
    </span>
  ) : isUpdating ? (
    <span>
      {leadName} has been successfully marked as {formattedLeadResult}. We have updated {leadName}'s
      records and status on {COMPANY_NAME}. Thanks {repName}!
    </span>
  ) : isCanceling ? (
    <span>
      {leadName} has been successfully marked as Canceled. We have updated {leadName}'s records and
      status on {COMPANY_NAME}.
    </span>
  ) : (
    "We'll check back with you again in a few days"
  );
  const t_successHeader = isAssigning
    ? 'Successfully assigned!'
    : isUpdating
    ? 'Successfully updated!'
    : isCanceling
    ? 'Successfully canceled!'
    : 'Successfully updated!';

  const t_body = isContinuing ? (
    <span>We'll check back with you again in a few days. Thanks {repName}!</span>
  ) : isSuccess ? (
    t_successText
  ) : (
    t_failText
  );
  const t_header = isContinuing ? 'Successfully updated!' : isSuccess ? t_successHeader : 'Whoops!';

  return (
    <div data-testid="FromEmailContainer">
      <nav className={styles.Header}>
        <Icon className={styles.Logo} name="SixSenseLogo" />
      </nav>
      <LayoutContainer className={styles.Container}>
        <div className={styles.Wrapper}>
          <div className={styles.Content}>
            <img
              alt={isFail ? 'Failed icon' : 'Success icon'}
              className={styles.Image}
              data-testid="FromEmailContainer__image"
              src={
                isFail
                  ? 'https://static.saleswhale.com/rep/images/icon-emoji-wow.svg'
                  : 'https://static.saleswhale.com/rep/images/icon-emoji-smiley.svg'
              }
            />
            <Typography label={t_header} tag="h1" />
            <p className={styles.Body} data-testid="FromEmailContainer__body">
              {t_body}
            </p>
            {!isAuthenticated && (
              <Button
                label="Login now"
                onClick={() => history.push('/login')}
                testId="FromEmailContainer__login"
                theme="primary"
              />
            )}
          </div>
        </div>
      </LayoutContainer>
    </div>
  );
}
