const env = process.env.REACT_APP_APP_ENV;

export const FLAGSMITH_API_KEY =
  env === 'prod'
    ? 'SVnMDFkXTqsCdbj7Z3y7Dt'
    : env === 'staging'
    ? 'SuEGLgtYUCv4DLQRwbm8KC'
    : 'L3qCPXEcskMoxZdy5KeB7f';
export const MIXPANEL_ID =
  env === 'prod' ? '6d6a2ef3980c86015cba04efe4130a8d' : '149a19f29f50caef713d7e093481c481';

export const ROLLBAR_TOKEN = '3b0033d1ddd44c988071a4b8d950b038';
export const PENDO_TOKEN = 'cc49df42-b395-4427-6da6-f5bbd6603f0f';
export const DD_CLIENT_TOKEN = 'pub02c2227445a997351710cb73beb1d373';
export const COMMANDBAR_TOKEN = '28b00518';
