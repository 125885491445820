import { ReactNode } from 'react';
import styles from './TableContainer.module.scss';

// TODO: Update styling acc. to Poseidon

interface Props {
  children: ReactNode;
  className?: string;
  shouldOverflow?: boolean; // TODO: possibly if we can figure out a way to pop the dropdown menu to be outside of the container (similar to rep), we can do away with this.
  testId?: string;
}

export function TableContainer({
  className,
  children,
  shouldOverflow = false,
  testId = 'TableContainer',
}: Props) {
  return (
    <div className={`${styles.Container} ${className ? className : ''}`} data-testid={testId}>
      <div
        className={`${shouldOverflow ? styles.Content : styles.RigidContent}`}
        data-testid={`${testId}__content`}
      >
        <table className={styles.Table}>{children}</table>
      </div>
    </div>
  );
}
