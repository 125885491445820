import { MutableRefObject, useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export const useDropdownDirection = (dropdownRef: MutableRefObject<HTMLDivElement | null>) => {
  const [dropdownDirection, setDropdownDirection] = useState<'left' | 'right'>('right');

  /* 
  This custom hook's ResizeObserver observes dropdownMenu's width change  whenever we open the dropdown, 
  and the updateDropdownDirection function calculates if the dropdown will overflow to determine opening direction.
  */
  useEffect(() => {
    let observer: ResizeObserver;
    if (dropdownRef.current) {
      const dropdownMenu = dropdownRef.current.querySelector('.menu');
      const updateDropdownDirection = (entries: ResizeObserverEntry[]) => {
        const dropdownMenuWidth = entries[0].borderBoxSize?.[0].inlineSize;
        const dropdown = dropdownRef.current as HTMLDivElement;
        if (!dropdown) return;
        if (window.innerWidth - dropdown.offsetLeft < dropdownMenuWidth) {
          setDropdownDirection('left');
        } else {
          setDropdownDirection('right');
        }
      };
      observer = new ResizeObserver(updateDropdownDirection);
      if (dropdownMenu) {
        observer.observe(dropdownMenu);
      }
    }
    return () => observer && observer.disconnect();
  }, [dropdownRef]);

  return dropdownDirection;
};
