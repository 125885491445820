import { find, trimEnd } from 'lodash';
import moment, { Moment } from 'moment';
import { FormEvent, SyntheticEvent, useState } from 'react';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { FilterButton, Input, RadioButton } from '..';
import { checkIfFilterIsValid } from '../../../../utils/filterUtils';
import { formattedDate, formattedMoment } from '../../../../utils/formatting';
import styles from './FilterDateTime.module.scss';

const INPUT_DATE_FORMAT = 'MMMM D, YYYY';

const generateButtonLabel = (filterCondition: string, label: string, value: string[] | string) => {
  if (filterCondition === 'is_between')
    return `${label} ${formattedDate(value[0])} and ${formattedDate(value[1])}`;
  if (filterCondition.includes('than')) {
    const labelSplit = label.split('than');
    return `${labelSplit[0]}than ${value}${labelSplit[1]}`;
  }
  return `${label} ${value}`;
};

interface Props {
  fieldLabel: string;
  filterCondition: string;
  filterConditions: { label: string; value: string }[];
  isLoading: boolean;
  onChange: (value: string[] | string) => void;
  onClose: () => void;
  onFilterConditionChange: (filterCondition: string) => void;
  checkForOutsideRange?: boolean;
  className?: string;
  defaultOpen?: boolean;
  numberOfMonths?: number;
  onClickDelete?: () => void;
  readOnly?: boolean;
  testId?: string;
  value?: string[] | string;
}

export function FilterDateTime({
  fieldLabel,
  filterCondition,
  filterConditions,
  value,
  checkForOutsideRange = false,
  onFilterConditionChange,
  onChange,
  onClose,
  onClickDelete,
  className = '',
  testId = 'FilterDateTime',
  defaultOpen = true,
  readOnly,
  isLoading,
  numberOfMonths,
}: Props) {
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);
  const label = find(filterConditions, { value: filterCondition })?.label;
  const shouldOpenByDefault = defaultOpen && value === undefined;
  const isFilterValid = checkIfFilterIsValid({
    filterType: 'datetime',
    values: value,
    selectedFilterCondition: filterCondition,
  });
  const isDisabled = readOnly || isLoading;
  const t_button = (
    <FilterButton
      onClickDelete={onClickDelete}
      readOnly={readOnly}
      showError={!isFilterValid}
      title={`${fieldLabel} ${
        value && value.length > 0 && label
          ? generateButtonLabel(filterCondition, label, value)
          : 'is missing value'
      }`}
    >
      {fieldLabel}{' '}
      <strong>
        {value && value.length > 0 && label
          ? generateButtonLabel(filterCondition, label, value)
          : 'is missing value'}
      </strong>
    </FilterButton>
  );

  const handleOptionChange = (
    e: SyntheticEvent<HTMLDivElement, MouseEvent>,
    { value: newFilterCondition }: DropdownItemProps
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (newFilterCondition === filterCondition) return;
    onFilterConditionChange(newFilterCondition as string);
  };

  const handleDatesChange = ({
    startDate,
    endDate,
  }: {
    endDate: Moment | null;
    startDate: Moment | null;
  }) => {
    const dateArray = [];
    if (startDate) {
      dateArray.push(formattedMoment(startDate));
    }
    if (endDate) {
      dateArray.push(formattedMoment(endDate));
    }
    onChange(dateArray);
  };

  const handleDaysChange = (e: FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    onChange(value);
  };

  const isOutsideRange = (moment: Moment) =>
    checkForOutsideRange ? !moment.isSameOrBefore(new Date(), 'day') : false;
  return (
    <Dropdown
      className={`${styles.FilterDateTime} ${className} DropdownFilter`}
      closeOnChange={false}
      data-testid={testId}
      defaultOpen={shouldOpenByDefault}
      disabled={isDisabled}
      icon={null}
      onClose={onClose}
      trigger={t_button}
    >
      <Dropdown.Menu data-testid={`${testId}__menu`}>
        {filterConditions.map(({ value: f, label }) => {
          const isSelected = f === filterCondition;
          let radioLabel = label;
          let inputSubLabel = null;
          if (label.includes('than')) {
            // Split label if it has 'than' and render the second half as a sublabel
            const labelSplit = label.split('than');
            radioLabel = `${trimEnd(labelSplit[0])} than`;
            inputSubLabel = labelSplit[1];
          }
          return (
            <Dropdown.Item key={f} onClick={handleOptionChange} selected={isSelected} value={f}>
              <RadioButton checked={isSelected} className={styles.Radio} label={radioLabel} />
              {isSelected &&
                (f === 'is_between' ? (
                  <div className={styles.DatePicker}>
                    <DateRangePicker
                      customArrowIcon={<span>and</span>}
                      displayFormat={INPUT_DATE_FORMAT}
                      endDate={value && value[1] ? moment(value[1]) : null}
                      endDateId="FilterDateTime__endDate"
                      focusedInput={focusedInput}
                      isOutsideRange={isOutsideRange}
                      numberOfMonths={numberOfMonths} // this closes the calendar after selecting the start date
                      onDatesChange={handleDatesChange}
                      onFocusChange={newFocusedInput =>
                        focusedInput === 'startDate'
                          ? setFocusedInput(null)
                          : setFocusedInput(newFocusedInput)
                      } // this closes the calendar after selecting the start date
                      startDate={value && value[0] ? moment(value[0]) : null}
                      startDateId="FilterDateTime__startDate"
                    />
                  </div>
                ) : (
                  <div className={styles.Days}>
                    <Input
                      className={styles.Input}
                      min="0"
                      name={f}
                      onChange={handleDaysChange}
                      testId={`${testId}__input`}
                      type="number"
                      value={value as string}
                    />
                    {inputSubLabel && (
                      <span className={styles.InputLabel} data-testid={`${testId}__inputSubLabel`}>
                        {inputSubLabel}
                      </span>
                    )}
                  </div>
                ))}
            </Dropdown.Item>
          );
        })}
        <Dropdown.Item className={styles.Done} data-testid={`${testId}__done`}>
          Done
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
