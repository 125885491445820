import { FC, MouseEventHandler, SVGProps } from 'react';
import icons from '../../../../assets/icons';

interface IconProps extends SVGProps<SVGSVGElement> {
  name: string;
  bottom?: number;
  className?: string;
  fill?: string;
  height?: number;
  left?: number;
  onClick?: MouseEventHandler<SVGSVGElement>;
  right?: number;
  size?: number;
  testId?: string;
  top?: number;
  width?: number;
}

export const Icon = ({
  name,
  size,
  top,
  right,
  bottom,
  left,
  testId = 'Icon',
  onClick,
  ...props
}: IconProps) => {
  const styles = {
    width: size && `${size}px`,
    height: size && `${size}px`,
    minWidth: size && `${size}px`,
    minHeight: size && `${size}px`,
    marginTop: top && `${top}px`,
    marginRight: right && `${right}px`,
    marginBottom: bottom && `${bottom}px`,
    marginLeft: left && `${left}px`,
    cursor: onClick ? 'pointer' : undefined,
  };

  const Icon = (
    icons as {
      [key: string]: FC<SVGProps<SVGSVGElement>>;
    }
  )[name];

  // we need this fallback so that it doesn't break storybook.
  return Icon ? <Icon data-testid={testId} onClick={onClick} style={styles} {...props} /> : <div />;
};
