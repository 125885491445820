import { useMutation } from '@tanstack/react-query';
import { post } from 'services/fetch';
import { stringifyToQueryString } from '../../utils/formatting';

const ENDPOINT = '/unsubscribes';

export function useConfirmUnsubscribe() {
  return useMutation(async (payload: { email: string; hash: string }) => {
    const qs = stringifyToQueryString(payload, { encode: true });
    const response = post(`${ENDPOINT}${qs}`);
    return response;
  });
}
