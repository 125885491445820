import { Icon } from '..';
import styles from './RedirectToInboxButtons.module.scss';

interface Props {
  className?: string;
}
export function RedirectToInboxButtons({ className = '' }: Props) {
  return (
    <div className={`${styles.Container} ${className}`} data-testid="RedirectToInbox">
      <a href="https://mail.google.com/mail" rel="noopener noreferrer" target="_blank">
        <div className={styles.Button}>
          <Icon className={styles.ButtonIcon} height={32} name="icon-gmail" width={32} />
          <span className={styles.ButtonText}>Open Gmail</span>
        </div>
      </a>
      <a href="https://outlook.live.com/mail" rel="noopener noreferrer" target="_blank">
        <div className={styles.Button}>
          <Icon className={styles.ButtonIcon} height={32} name="icon-outlook" width={32} />
          <span className={styles.ButtonText}>Open Outlook</span>
        </div>
      </a>
    </div>
  );
}
