import { ReactNode } from 'react';
import AttributeItem from './AttributeItem';
import styles from './Attribute.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
  testId?: string;
}

export function Attribute({ children, className = '', testId = 'Attribute' }: Props) {
  return (
    <div className={`${styles.Container} ${className}`} data-testid={testId}>
      {children}
    </div>
  );
}

Attribute.Item = AttributeItem;
