import { ReactNode } from 'react';
import { COMPANY_NAME } from '../../../../config/constants';
import styles from './SimpleMainNav.module.scss';

interface Props {
  children?: ReactNode;
}

export function SimpleMainNav({ children }: Props) {
  return (
    <div className={styles.Container}>
      <a href="https://www.saleswhale.com/" rel="noopener noreferrer">
        <div className={styles.Logo}>
          <img
            alt={`${COMPANY_NAME} Logo`}
            height="32px"
            src="https://static.saleswhale.com/rep/images/6sense-logo-full.svg"
          />
        </div>
      </a>
      {children}
    </div>
  );
}
