export const getClass = (...options: Array<string | object | undefined>) => {
  const classes: Array<string> = [];

  const formatClasses = (option: any, key: string) => {
    switch (typeof option[key]) {
      case 'string':
        return classes[0] && classes.push(`${classes[0]}--${option[key]}`);
      case 'boolean':
        return Boolean(option[key]) && classes.push(key);
      default:
        return;
    }
  };

  options.forEach((option: string | object | undefined) => {
    switch (typeof option) {
      case 'string':
        return classes.push(option);
      case 'object':
        Object.keys(option).forEach(key => {
          formatClasses(option, key);
        });
        return;
      default:
        return;
    }
  });

  return classes.filter((c: string, i: number) => classes.indexOf(c) === i).join(' ');
};
