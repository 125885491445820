import { useQueryClient } from '@tanstack/react-query';
import { NegativeToast } from 'app/components/Elements';
import { useFlagsmith } from 'flagsmith/react';
import { useHistory } from 'react-router-dom';
import { authSwitchTeam, generateAuthQueryKey, useSignOut } from '../app/adapters/auth';
import { AuthSchema } from '../app/schemas';
import { invalidateCommandbar } from './commandbar';
import { invalidatePendo } from './pendo';

export interface ErrorPayload {
  detail: string;
  status: string;
  title: string;
}

export function success(payload: AuthSchema) {
  localStorage.setItem('auth/data', JSON.stringify(payload));
}
export function invalidate() {
  localStorage.removeItem('auth/data');
}

export async function switchTeam(teamId: number, redirectUrl = '/') {
  try {
    const data = await authSwitchTeam(teamId);
    success(data);
  } catch (e: unknown) {
    invalidate();
  } finally {
    window.location.href = redirectUrl;
  }
}

export function useSelectAuthData() {
  const queryClient = useQueryClient();
  return queryClient.getQueryData<AuthSchema>(generateAuthQueryKey());
}
export function useIsAuthenticated() {
  const authData = useSelectAuthData();
  return !!authData?.session.userId;
}
export function useIsAuthenticatedWithSixSense() {
  const authData = useSelectAuthData();
  return !!authData?.session.isAuthedWithSixSense;
}
export function useSelectCurrentTeam() {
  const authData = useSelectAuthData();
  return authData?.session.currentTeam;
}
export function useSelectUserRole() {
  const authData = useSelectAuthData();
  return authData?.session.membershipRole;
}
export function useSelectIsAdmin() {
  const authData = useSelectAuthData();
  return (
    authData?.session.membershipRole === 'admin' ||
    authData?.session.membershipRole === 'view_only_admin'
  );
}

// SSO url is auth/sso_login. Leaving this here for the future

export const useLogout = () => {
  const flagsmith = useFlagsmith();
  const history = useHistory();
  const queryClient = useQueryClient();
  const signOutQuery = useSignOut();
  const currentTeam = useSelectCurrentTeam();
  const isAuthenticatedWithSixSense = useIsAuthenticatedWithSixSense();

  return () => {
    if (!currentTeam) return;
    signOutQuery.mutate(undefined, {
      onSuccess: () => {
        invalidatePendo();
        invalidateCommandbar();
        flagsmith.logout();
        invalidate();
        if (isAuthenticatedWithSixSense) {
          const env = process.env.REACT_APP_APP_ENV;
          const url =
            env === 'prod'
              ? `https://${currentTeam.sixSenseOrgName}.abm.6sense.com/logout/`
              : env === 'staging'
              ? `https://${currentTeam.sixSenseOrgName}.account-analytics-release.dev.6si.com/logout/`
              : `https://${currentTeam.sixSenseOrgName}.account-analytics.dev.6si.com/logout/`;
          window.location.href = url;
        } else {
          queryClient.removeQueries(generateAuthQueryKey());
          history.push('/');
        }
      },
      onError: () => NegativeToast('An error occured while logging out.'),
    });
  };
};
