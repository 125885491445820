import { ReactNode } from 'react';
import styles from './styles.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
  isNoPadding?: boolean;
  testId?: string;
}

export function LayoutContainer({ children, testId, isNoPadding, className = '' }: Props) {
  return (
    <div
      className={`${styles.BaseLayoutContainer} ${className} ${
        isNoPadding ? styles.NoPadding : ''
      }`}
      data-testid={testId}
    >
      {children}
    </div>
  );
}
