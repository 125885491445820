import StatusMessage from '../../../StatusMessage';
import styles from './Catchall.module.scss';

export default function Catchall() {
  return (
    <div data-testid="EmailConfirmationErrorCatchall">
      <StatusMessage text="There’s been a glitch....." type="error" />
      <div>
        <span className={styles.Text}>
          We’re not quite sure what went wrong. <br />
        </span>
      </div>
    </div>
  );
}
