import React, { useEffect } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import type { DropResult, DroppableProvided } from 'react-beautiful-dnd';
import { useInView } from 'react-intersection-observer';
import { TableContainer, Spinner } from '..';
import { StrictModeDroppable } from '../../../../utils/DraggableUtils/StrictModeDroppable';
import { htmlSanitizer } from '../../../../utils/formatting';
import { TableState } from '../../../../utils/tableUtils';
import {
  getColSpanValue,
  checkIfHasData,
  checkIfShouldLoadData,
  checkIfShouldDisplayEndOfResultsBanner,
} from '../InfiniteScrollingTable';
import styles from '../InfiniteScrollingTable/InfiniteScrollingTable.module.scss';
import TableNoData from '../Table/TableNoData';

interface Props {
  loadData: () => void;
  // to handle case where we pass in a fragment or an array of elements
  tableHeaders: JSX.Element | JSX.Element[];
  tableRows: JSX.Element | JSX.Element[];
  tableState: TableState;
  className?: string;
  emptyStateChildren?: React.ReactNode;
  emptyStateDescription?: string;
  emptyStateHeader?: string;
  isDropDisabled?: boolean;
  // to handle case where we pass in a fragment or an array of elements
  loadingDescription?: string;
  loadingHeader?: string;
  onReorderingRows?: (result: DropResult) => void;
  shouldOverflow?: boolean;
  tableClassName?: string;
  testId?: string;
}

export function DraggableInfiniteScrollingTable({
  loadingDescription,
  shouldOverflow = false,
  emptyStateHeader = 'No data to show',
  emptyStateDescription = '',
  emptyStateChildren,
  loadingHeader = 'One moment...',
  className = '',
  tableClassName = '',
  loadData,
  tableHeaders,
  tableRows,
  tableState,
  testId = 'DraggableInfiniteScrollingTable',
  onReorderingRows,
  isDropDisabled = true,
}: Props) {
  const { isLoading, isFetchingNextPage, hasNextPage, hasErrors } = tableState;
  const { ref: footerRef, inView: isFooterInView } = useInView({ threshold: 0 });
  const colSpanValue = getColSpanValue(tableHeaders);
  const hasData = checkIfHasData(tableRows);
  const shouldLoadData = checkIfShouldLoadData(hasNextPage, hasErrors);
  const displayEndOfResultsBanner = checkIfShouldDisplayEndOfResultsBanner(hasData, hasNextPage);

  useEffect(() => {
    (async () => {
      if (shouldLoadData && !isFetchingNextPage && isFooterInView) {
        await loadData();
      }
    })();
  }, [shouldLoadData, isFetchingNextPage, isFooterInView, loadData]);
  return (
    <div className={`${styles.Container} ${className}`} data-testid={testId}>
      <TableContainer
        className={`${hasData ? styles.Table : styles.TableEmptyState} ${tableClassName}`}
        shouldOverflow={shouldOverflow}
      >
        <thead>
          <tr data-testid="DraggableInfiniteScrollingTable__tableHeadersContainer">
            {tableHeaders}
          </tr>
        </thead>
        {hasData ? (
          <DragDropContext
            onDragEnd={(result: DropResult) => {
              onReorderingRows?.(result);
            }}
          >
            <StrictModeDroppable
              droppableId={`${testId}__droppable`}
              isDropDisabled={isDropDisabled}
            >
              {(droppableProvided: DroppableProvided) => (
                <tbody
                  className={styles.Body}
                  data-testid="DraggableInfiniteScrollingTable__bodyWithData"
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                >
                  {tableRows}
                  {droppableProvided.placeholder}
                  {isFetchingNextPage && (
                    <tr
                      className={styles.Loader}
                      data-testid="DraggableInfiniteScrollingTable__tableRowSpinner"
                    >
                      <td colSpan={colSpanValue}>
                        <div className={styles.SpinnerContainer}>
                          <Spinner className={styles.Spinner} size="medium" />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        ) : isLoading ? (
          <tbody
            className={styles.EmptyTableBody}
            data-testid="DraggableInfiniteScrollingTable__bodyLoading"
          >
            <tr className={styles.Loader}>
              <td colSpan={colSpanValue}>
                <div className={styles.SpinnerContainer}>
                  <Spinner size="large" />
                  <p className={styles.LoadingHeader}>{loadingHeader}</p>
                  {loadingDescription && (
                    <p
                      className={styles.LoadingDescription}
                      dangerouslySetInnerHTML={{ __html: htmlSanitizer(loadingDescription) }}
                    />
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <TableNoData
            children={emptyStateChildren}
            colSpan={colSpanValue}
            description={emptyStateDescription}
            title={emptyStateHeader}
          />
        )}
        {!isLoading && (
          <tfoot data-testid={`${testId}__tableFooter`} ref={footerRef}>
            {displayEndOfResultsBanner && (
              <tr>
                <td className={styles.EndOfResultsText} colSpan={colSpanValue}>
                  End of results
                </td>
              </tr>
            )}
          </tfoot>
        )}
      </TableContainer>
    </div>
  );
}
