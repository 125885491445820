import { FormEvent, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { COMPANY_NAME } from '../../../config/constants';
import { useQueryString } from '../../../services/routing';
import { resetPassword } from '../../adapters/auth';
import { didError, PositiveToast, Spinner, Button, Input } from '../Elements';
import styles from '../Login/Login.module.scss';

export default function ResetPassword() {
  const history = useHistory();
  const query = useQueryString();
  const token = query.get('token') || '';
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  const submitForm = async (e: FormEvent) => {
    e.preventDefault();
    if (password.trim().length > 0 && password === passwordConfirmation) {
      setIsLoading(true);
      setHasErrors(false);
      const payload = {
        password,
        passwordConfirmation,
        token,
        const: 'user',
      };
      try {
        await resetPassword(payload);
        PositiveToast('Successfully reset password.');
        history.push('/password_login');
      } catch (e) {
        didError(e);
      } finally {
        setIsLoading(false);
      }
    } else {
      setHasErrors(true);
    }
  };

  return (
    <div className={styles.Container} data-testid="ResetPassword">
      <div className={styles.FormContainer}>
        <div className={styles.LogoContainer}>
          <img
            alt={`${COMPANY_NAME} Logo`}
            height="32px"
            src="https://static.saleswhale.com/rep/images/6sense-logo-full.svg"
          />
        </div>
        <h2 className={styles.Header}>Change password</h2>
        <div>
          <form className={styles.Form} onSubmit={submitForm}>
            {isLoading && <Spinner inline className={styles.Spinner} />}
            <Input
              className={styles.Input}
              error={hasErrors}
              errorMsg="Passwords don't match"
              label="New password"
              name="new password"
              onChange={e => setPassword(e.currentTarget.value)}
              testId="ResetPasswordInput"
              type="password"
              value={password}
            />
            <Input
              className={styles.Input}
              error={hasErrors}
              errorMsg="Passwords don't match"
              label="Verify password"
              name="verify password"
              onChange={e => setPasswordConfirmation(e.currentTarget.value)}
              testId="ResetPasswordConfirmInput"
              type="password"
              value={passwordConfirmation}
            />
            <Button
              className={styles.ActionButton}
              label="Update password"
              testId="ResetPassword__update"
              theme="primary"
              type="submit"
            />
          </form>
        </div>
        <div className={styles.Links}>
          <NavLink data-testid="ResetPassword__return" to="/password_login">
            Return to log in
          </NavLink>
        </div>
      </div>
    </div>
  );
}
