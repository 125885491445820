import { useEffect, useRef, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { htmlSanitizerAllowAttrs, parseHtmlString } from '../../../../utils/formatting';
import { alterLinksToOpenNewTab } from '../../../../utils/linkUtils';
import { EllipsisButton } from '../EllipsisButton';
import styles from './EmailBody.module.scss';

interface Props {
  body: string;
  className?: string;
  testId?: string;
}

export function EmailBody({ body, className, testId = 'EmailBody' }: Props) {
  const [open, setOpen] = useState(false);
  const emailRef = useRef<HTMLDivElement>(null);
  const toggle = () => setOpen(value => !value);

  const t_html = parseHtmlString(body);
  for (const style of t_html.getElementsByTagName('style')) {
    style.remove();
  }
  const elements = t_html.getElementsByTagName('*');
  elements &&
    Array.from(elements).forEach(el => {
      el.removeAttribute('style');
    });
  const t_button = parseHtmlString(
    renderToStaticMarkup(<EllipsisButton className={styles.Toggle} />)
  ).body.children[0];
  const blockquote = t_html.querySelector('blockquote');
  blockquote?.classList.add(styles.Blockquote);
  blockquote?.insertAdjacentElement('beforebegin', t_button);

  useEffect(() => {
    const element = emailRef.current;
    if (element) {
      alterLinksToOpenNewTab(element);
      const button = element.querySelector(`.${styles.Toggle}`);
      button?.addEventListener('click', toggle);
      return () => {
        button?.removeEventListener('click', toggle);
      };
    }
  }, []);

  return (
    <div
      className={`${styles.Body} ${open ? styles.BodyOpen : ''} ${className ? className : ''}`}
      dangerouslySetInnerHTML={{ __html: htmlSanitizerAllowAttrs(t_html.body.innerHTML) }}
      data-testid={testId}
      ref={emailRef}
    />
  );
}
