import { ReactElement, ReactNode, SyntheticEvent } from 'react';
import { DropdownItemProps, DropdownProps } from 'semantic-ui-react';
import { ChipMultiSelect, Dropdown } from '../';

export interface MultiSelectDropdownItemProps {
  text: string | ReactNode;
  value: number | string;
  content?: ReactNode;
  description?: string;
  disabled?: boolean;
  key?: any;
}

interface Props<T extends string | number> {
  options: MultiSelectDropdownItemProps[];
  value: T[];
  additionLabel?: string | ReactNode;
  allowAdditions?: boolean;
  className?: string;
  clearable?: boolean;
  defaultOpen?: boolean;
  defaultValue?: string;
  direction?: 'left' | 'right';
  disabled?: boolean;
  dropdownClass?: string;
  error?: boolean;
  errorMsg?: string;
  fluid?: boolean;
  icon?: string | null;
  inline?: boolean;
  label?: string;
  name?: string;
  noResultsMessage?: ReactNode;
  onAddItem?: (event: SyntheticEvent<HTMLElement>, data: DropdownProps) => void;
  onBlur?: (event: SyntheticEvent<HTMLElement>, data: DropdownProps) => void;
  onChange?: (data: T[]) => void;
  onClick?: (event: SyntheticEvent<HTMLElement>) => void;
  //You'll want to extract {value} from data
  onClose?: (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
  onOpen?: (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void;
  onSearchChange?: (event: SyntheticEvent<HTMLElement>, data: DropdownProps) => void;
  openOnFocus?: boolean;
  placeholder?: string;
  scrolling?: boolean;
  search?: boolean | ((options: DropdownItemProps[], value: string) => any);
  searchQuery?: string;
  selected?: string;
  selection?: boolean;
  testId?: string;
  text?: string;
  trigger?: ReactElement;
  upward?: boolean;
}

export function MultiSelectDropdown<T extends string | number>({
  className = '',
  label,
  error,
  errorMsg,
  dropdownClass = '',
  upward,
  testId = 'MultiSelectDropdown',
  defaultOpen = false,
  disabled = false,
  onChange,
  options,
  value,
  ...semanticDropdownProps
}: Props<T>) {
  const removeMultiSelectSelection = (id: number) => {
    if (onChange) {
      const updatedSelection = value.filter(selected => selected !== id);
      onChange(updatedSelection);
    }
  };
  const handleChange = (value: T[]) => {
    onChange && onChange(value);
  };

  const t_label = ({ value, text }: DropdownItemProps) =>
    onChange ? (
      <ChipMultiSelect
        onDismiss={() => removeMultiSelectSelection(value as number)}
        value={text as string}
      />
    ) : (
      <ChipMultiSelect value={text as string} />
    );

  return (
    <Dropdown
      multiple
      className={className}
      defaultOpen={defaultOpen}
      disabled={disabled}
      dropdownClass={dropdownClass}
      error={error}
      errorMsg={errorMsg}
      label={label}
      onChange={(e, { value }) => handleChange(value as T[])}
      options={options}
      renderLabel={t_label}
      testId={testId}
      upward={upward}
      value={value}
      {...semanticDropdownProps}
    />
  );
}
