import { Skeleton } from '..';
import ProgressLoader from '../ProgressLoader';
import styles from './EmailLoader.module.scss';

const PROGRESS_LOADING_STEPS = [
  {
    title: 'Processing email content...',
    progress: 0,
  },
  {
    title: 'Reading email...',
    progress: 30,
  },
  {
    title: 'Extracting relevant information...',
    progress: 45,
  },
  {
    title: 'Analysing...',
    progress: 60,
  },
  {
    title: 'Wordsmithing...',
    progress: 75,
  },
  {
    title: 'Some final checks...',
    progress: 90,
  },
];

interface Props {
  title?: string;
}
export function EmailLoader({ title }: Props) {
  const testId = 'EmailLoader';

  return (
    <div className={styles.Container} data-testid={testId}>
      <div className={styles.Header}>
        <Skeleton avatarSize="sm" variant="avatar" />
        <div className={styles.HeaderContent}>
          <Skeleton textWidthPercent={50} />
          <Skeleton textWidthPercent={25} />
        </div>
      </div>
      <div className={styles.BodyContainer}>
        <ProgressLoader steps={PROGRESS_LOADING_STEPS} testId={testId} title={title} />
      </div>
    </div>
  );
}
