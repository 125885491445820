import {
  InfoCircle,
  CheckCircle,
  AlertTriangle,
  AlertCircle,
  ArrowNarrowRight,
  XClose,
} from '@saleswhale/barnacle/icons';
import { ReactElement, ReactNode, useState, CSSProperties } from 'react';
import { Button } from '../Button';
import styles from './AlertMessage.module.scss';

type AlertMessageExtraActionButtons = {
  label: string;
  onClick: () => void;
  theme: 'primary' | 'secondary' | 'flush';
  isLoading?: boolean;
  testId?: string;
};

interface Props {
  title: string;
  actionText?: string;
  children?: ReactNode;
  className?: string;
  extraActionButtons?: AlertMessageExtraActionButtons[];
  isRemovable?: boolean;
  noIcon?: boolean;
  onActionClick?: () => void;
  onRemove?: () => void;
  style?: CSSProperties;
  testId?: string;
  type?: 'success' | 'error' | 'info' | 'warning';
}
// TODO: we should consider setting a max width for this
export function AlertMessage({
  noIcon,
  actionText,
  onActionClick,
  title,
  children,
  isRemovable,
  style,
  type = 'info',
  className = '',
  testId = 'AlertMessage',
  extraActionButtons,
  onRemove,
}: Props) {
  const [isVisible, setIsVisible] = useState(true);
  const icons: { [key: string]: ReactElement } = {
    info: <InfoCircle size={16} />,
    success: <CheckCircle size={16} />,
    error: <AlertCircle size={16} />,
    warning: <AlertTriangle size={16} />,
  };
  const icon = icons[type];
  const containerClassName = type.charAt(0).toUpperCase() + type.slice(1);
  const hasActions = actionText || extraActionButtons;
  const t_mainAction = actionText ? (
    <div
      className={styles.MainActionContainer}
      data-testid={`${testId}__action`}
      onClick={onActionClick}
    >
      <div className={styles.MainActionText} data-testid={`${testId}__actionText`}>
        {actionText}
      </div>
      <ArrowNarrowRight size={16} />
    </div>
  ) : null;
  const t_otherActions = extraActionButtons?.map(a => (
    <Button
      isLoading={a.isLoading}
      label={a.label}
      onClick={a.onClick}
      testId={a.testId}
      theme={a.theme}
    />
  ));

  if (!isVisible) return null;
  return (
    <div
      className={`${styles[containerClassName]} ${className}`}
      data-testid={testId}
      style={style}
    >
      <div className={styles.ContentContainer}>
        {!noIcon && (
          <div className={styles.Icon} data-testid={`${testId}__icon`}>
            {icon}
          </div>
        )}
        {title && (
          <div className={styles.TitleContainer} data-testid={`${testId}__title`}>
            <span>{title}</span>
          </div>
        )}
        {children ? (
          <div className={styles.Description} data-testid={`${testId}__description`}>
            <span>{children}</span>
          </div>
        ) : null}
        {hasActions && (
          <div className={styles.ActionsContainer}>
            {t_mainAction}
            {t_otherActions}
          </div>
        )}
        {isRemovable && (
          <div className={styles.CloseContainer}>
            <XClose
              className={styles.CloseIcon}
              data-testid={`${testId}__remove`}
              onClick={() => {
                onRemove && onRemove();
                setIsVisible(false);
              }}
              size={16}
            />
          </div>
        )}
      </div>
    </div>
  );
}
