import { ROLLBAR_TOKEN } from '../config/environment';
const version = process.env.REACT_APP_GIT_SHA || require('../../package.json').version;

export const rollbarConfig = {
  accessToken: ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: process.env.REACT_APP_DISABLE_VENDORS !== 'true',
  ignoredMessages: [
    'ResizeObserver loop',
    'Unexpected error occurred. Please contact Saleswhale support with the error reference code',
    'Network request failed',
    'Token is invalid',
    'Token is expired',
    'Unauthorized',
    'Script error.',
    'AbortError: Aborted',
    'Object Not Found Matching Id',
  ],
  payload: {
    environment: process.env.REACT_APP_APP_ENV || 'local',
    code_version: version,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: version,
      },
    },
  },
};
