import { SessionSchema } from '../app/schemas';
import { formattedDisplayName, formattedTeamRole } from '../utils/formatting';

export function bootPendo(session?: SessionSchema) {
  try {
    if (!session || process.env.REACT_APP_DISABLE_VENDORS === 'true') {
      return pendo.clearSession();
    }

    const payload = {
      visitor: {
        id: session.email,
        email: session.email,
        full_name: formattedDisplayName(session.firstName, session.lastName),
        firstname: session.firstName,
        lastname: session.lastName,
        ce_role: formattedTeamRole(session.membershipRole as 'member' | 'admin'),
      },
      account: {
        id: session.currentTeam.id,
        name: session.currentTeam.name,
        source: 'ConversationalEmail_WebApp',
        product: 'ConversationalEmail_WebApp',
      },
    };
    if (!pendo.isReady()) {
      pendo.initialize(payload);
    } else {
      pendo.identify(payload);
    }
  } catch {
    //catch in case pendo doesn't exist
    return;
  }
}
export function invalidatePendo() {
  try {
    pendo.clearSession();
  } catch {
    return;
  }
}
