import { useResendEmail } from '../../../../../../services/email';
import { useQueryString } from '../../../../../../services/routing';
import { Button, RedirectToInboxButtons, NegativeToast } from '../../../../Elements';
import StatusMessage from '../../../StatusMessage';
import styles from './Expired.module.scss';

export default function Expired() {
  const query = useQueryString();
  const id = query.get('id');
  const email = query.get('email');
  const resendEmail = useResendEmail();

  const handleResendEmail = async (id: string | null) => {
    if (id) {
      await resendEmail(id);
    } else {
      NegativeToast('User id not found!');
    }
  };

  return (
    <div data-testid="EmailConfirmationErrorExpired">
      <div>
        <StatusMessage text="Confirmation link has expired" type="error" />
        <div className={styles.ButtonContainer}>
          <Button
            label="Resend confirmation email"
            onClick={() => handleResendEmail(id)}
            size="l"
            testId="EmailConfirmationErrorExpired__resend"
            theme="primary"
          />
        </div>
        <div className={styles.Description} data-testid="EmailConfirmationErrorExpired__content">
          <span className={styles.DescriptionText}>
            We will send a confirmation email to <strong>{email}</strong>
          </span>
        </div>
        <RedirectToInboxButtons className={styles.RedirectButtons} />
      </div>
    </div>
  );
}
