import { getClass } from '../../../../utils/getClass';
import './Typography.scss';

export interface TypographyProps {
  children?: React.ReactNode;
  label?: string;
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body';
  variant?: string;
}

export const Typography = ({ variant, tag = 'body', label, children }: TypographyProps) => {
  const customTag = () => {
    switch (tag) {
      case 'body':
        return 'div';
      default:
        return tag;
    }
  };
  const Tag = customTag();

  return (
    <Tag className={getClass('Typography', { tag }, variant)} data-testid="Typography">
      {label || children}
    </Tag>
  );
};
