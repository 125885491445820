import { ReactElement } from 'react';
import * as Icons from 'react-icons/md';
import { Icon6DS } from '../SixSenseElements';
import styles from './PageHeader.module.scss';

interface PageHeaderProps {
  header: string;
  children?: ReactElement;
  className?: string;
  hasNavLinks?: boolean;
  icon?: keyof typeof Icons | ReactElement;
  primaryButton?: ReactElement | null;
  secondaryButton?: ReactElement | null;
  subHeader?: string | ReactElement;
  testId?: string;
}

export function PageHeader({
  children,
  className = '',
  hasNavLinks = false,
  header,
  icon,
  primaryButton,
  secondaryButton,
  subHeader,
  testId = 'PageHeader',
}: PageHeaderProps) {
  return (
    <div
      className={`${hasNavLinks ? styles.NavHeaderContainer : styles.HeaderContainer} ${className}`}
    >
      <div className={styles.HeaderTopContainer} data-testid={testId}>
        <div>
          <div className={styles.HeaderTitleContainer}>
            {icon ? (
              typeof icon === 'string' ? (
                <Icon6DS className={styles.Icon} type={icon} />
              ) : (
                <div className={styles.Icon}>{icon}</div>
              )
            ) : null}
            <h5 className={styles.HeaderText} data-testid={`${testId}__headerText`}>
              {header}
            </h5>
          </div>
          {subHeader && (
            <span className={styles.SubHeaderText} data-testid={`${testId}__subHeaderText`}>
              {subHeader}
            </span>
          )}
        </div>
        <div className={styles.ActionButtonsContainer}>
          {secondaryButton ? <div className={styles.SecondaryButton}>{secondaryButton}</div> : null}
          {primaryButton ? primaryButton : null}
        </div>
      </div>
      <div className={styles.Content}>{children}</div>
    </div>
  );
}
