import { find } from 'lodash';
import { SyntheticEvent, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { FilterButton, RadioButton } from '..';
import { checkIfFilterIsValid } from '../../../../utils/filterUtils';
import { MultiStringInput } from '../MultiStringInput';
import styles from './FilterMultiStringSearch.module.scss';

interface Props {
  fieldLabel: string;
  filterCondition: string;
  filterConditions: { label: string; value: string }[];
  isLoading: boolean;
  onChange: (value: string[]) => void;
  onClose: () => void;
  onFilterConditionChange: (filterCondition: string) => void;
  className?: string;
  defaultOpen?: boolean;
  onClickDelete?: () => void;
  readOnly?: boolean;
  testId?: string;
  value?: string[];
}

export function FilterMultiStringSearch({
  fieldLabel,
  filterCondition,
  filterConditions,
  className,
  readOnly,
  value,
  testId = 'FilterMultiStringSearch',
  onChange,
  onClickDelete,
  onClose,
  onFilterConditionChange,
  isLoading,
  defaultOpen = true,
}: Props) {
  const shouldOpenByDefault = defaultOpen && value === undefined;
  const [isOpen, setIsOpen] = useState(shouldOpenByDefault);
  const isDisabled = readOnly || isLoading;
  const isFilterValid = checkIfFilterIsValid({ filterType: 'multi_string_search', values: value });
  const label = find(filterConditions, { value: filterCondition })?.label;
  const textAfterLabel =
    value && value.length > 1 ? `${value[0]} and ${value.length - 1} more` : value?.[0];

  const handleOptionChange = (
    e: SyntheticEvent<HTMLDivElement, MouseEvent>,
    { value: newFilterCondition }: DropdownItemProps
  ) => {
    e.preventDefault();
    e.stopPropagation();
    if (newFilterCondition === filterCondition) return;
    onFilterConditionChange(newFilterCondition as string);
  };

  const t_button = (
    <FilterButton
      onClick={() => setIsOpen(true)}
      onClickDelete={onClickDelete}
      readOnly={readOnly}
      showError={!isFilterValid}
      testId={`${testId}__button`}
      title={`${fieldLabel} ${isFilterValid ? `${label} ${textAfterLabel}` : 'is missing value'}`}
    >
      {fieldLabel}
      <strong> {isFilterValid ? `${label} ${textAfterLabel}` : 'is missing value'}</strong>
    </FilterButton>
  );

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setIsOpen(false);
      }}
    >
      <Dropdown
        className={`${className} DropdownFilter`}
        data-testid={testId}
        disabled={isDisabled}
        icon={null}
        onClose={onClose}
        open={isOpen}
        trigger={t_button}
      >
        <Dropdown.Menu className={styles.Menu} data-testid={`${testId}__menu`}>
          {filterConditions.map(({ value: f, label }) => {
            const isSelected = f === filterCondition;
            return (
              <Dropdown.Item key={f} onClick={handleOptionChange} selected={isSelected} value={f}>
                <RadioButton checked={isSelected} className={styles.Radio} label={label} />
                {isSelected && (
                  <MultiStringInput
                    helpText="Separate keywords with a comma."
                    limit={20}
                    onUpdateOptions={onChange}
                    options={value || []}
                    placeholder="bereavement, death"
                  />
                )}
              </Dropdown.Item>
            );
          })}
          <Dropdown.Item className={styles.Done} onClick={() => setIsOpen(false)}>
            Done
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </OutsideClickHandler>
  );
}
