import { Switch, Route, useRouteMatch, Link } from 'react-router-dom';
import { SimpleMainNav, Button } from '../../../Elements';
import ContentContainer from '../../ContentContainer';
import Catchall from './Catchall';
import Expired from './Expired';
import styles from './ErrorContainer.module.scss';

export default function ErrorContainer() {
  const { path } = useRouteMatch();

  return (
    <div data-testid="EmailConfirmationError">
      <SimpleMainNav>
        <Link className={styles.Link} data-testid="EmailConfirmationError__login" to="/login">
          <Button label="Log in" size="l" theme="secondary" />
        </Link>
      </SimpleMainNav>
      <ContentContainer>
        <div className={styles.ImageContainer}>
          <img
            alt="Error"
            className={styles.Image}
            src="https://static.saleswhale.com/rep/images/email-confirmation-error.png"
          />
        </div>
        <Switch>
          <Route path={`${path}/catchall`}>
            <Catchall />
          </Route>
          <Route path={`${path}/expired`}>
            <Expired />
          </Route>
        </Switch>
      </ContentContainer>
    </div>
  );
}
