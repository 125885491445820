import { ReactNode } from 'react';
import styles from './RuleBlock.module.scss';

interface Props {
  children: ReactNode;
  label: string;
  className?: string;
  labelClassName?: string;
  testId?: string;
}

export function RuleBlock({
  children,
  className = '',
  label,
  labelClassName = '',
  testId = 'RuleBlock',
}: Props) {
  return (
    <div className={`${styles.Container} ${className}`} data-testid={testId}>
      <div className={`${styles.Label} ${labelClassName}`} data-testid={`${testId}__label`}>
        {label}
      </div>
      <div className={styles.Content} data-testid={`${testId}__content`}>
        {children}
      </div>
    </div>
  );
}
