import { IconProps } from '@saleswhale/barnacle/icons/types';
import { getClass } from '../../../../utils/getClass';
import BadgeIcon from './BadgeIcon';
import './Badge.scss';

export type BadgeColor =
  | 'primary'
  | 'success'
  | 'warning'
  | 'error'
  | 'gray'
  | 'violet'
  | 'orange'
  | 'pink'
  | 'teal';

type BadgeProps = {
  bottom?: number;
  className?: string;
  color?: BadgeColor;
  left?: number;
  onClick?: () => void;
  right?: number;
  testId?: string;
  theme?: 'light' | 'dark';
  top?: number;
  variant?: string;
} & (
  | { icon: React.FC<IconProps>; label?: string | number }
  | { label: string | number; icon?: React.FC<IconProps> }
);

export const Badge = ({
  className = '',
  variant,
  theme = 'light',
  color = 'orange',
  left,
  right,
  top,
  bottom,
  testId = 'Badge',
  onClick,
  ...badgeProps
}: BadgeProps) => {
  const customStyle = {
    marginLeft: left,
    marginRight: right,
    marginTop: top,
    marginBottom: bottom,
  };

  return (
    <div
      className={`${getClass('Badge', { theme, color }, variant)} ${className} ${
        badgeProps.icon && !badgeProps.label
          ? 'BadgeWithIcon'
          : badgeProps.label && !badgeProps.icon
          ? 'BadgeWithLabel'
          : ''
      }`}
      data-testid={testId}
      onClick={onClick}
      style={customStyle}
    >
      {badgeProps.icon ? <BadgeIcon color={color} icon={badgeProps.icon} theme={theme} /> : null}
      {badgeProps.label || badgeProps.label === 0 ? (
        <span className="BadgeLabel" data-testid={`${testId}__label`}>
          {badgeProps.label}
        </span>
      ) : null}
    </div>
  );
};
