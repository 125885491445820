import classNames from 'classnames';
import styles from './ProgressBar.module.scss';

interface ProgressBarProps {
  current: number;
  total: number;
  bottom?: number;
  className?: string;
  color?: 'blue' | 'orange' | 'transparent' | 'red';
  height?: number;
  left?: number;
  right?: number;
  top?: number;
  width?: string;
}

export const ProgressBar = ({
  className = '',
  width = '100px',
  height = 8,
  top = 0,
  right = 0,
  bottom = 0,
  left = 0,
  current,
  total,
  color = 'blue',
}: ProgressBarProps) => {
  const hasProgress = current > 0;

  const barClass = classNames(styles.Bar, {
    [styles['Bar--success']]: hasProgress && total === current,
    [styles[`Bar--${color}`]]: !!color,
  });

  const style = {
    width,
    height: `${height}px`,
    margin: `${top}px ${right}px ${bottom}px ${left}px`,
  };
  const fillStyle = hasProgress
    ? {
        width: `${(current / total) * 100}%`,
        height: `${height}px`,
      }
    : {
        width: `${height}px`,
        height: `${height}px`,
        borderRadius: '50%',
      };

  return (
    <div className={`${styles.Container} ${className}`} data-testid="ProgressBar" style={style}>
      <div className={barClass} data-testid="ProgressBar__fill" style={fillStyle}></div>
    </div>
  );
};
