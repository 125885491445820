import { compact, uniq } from 'lodash';
import { KeyboardEventHandler, useState } from 'react';
import { removeExtraSpace } from '../../../../utils/formatting';
import { Dropdown } from '../Dropdown';
import { NegativeToast } from '../Toast';
import styles from './MultiStringInput.module.scss';

interface Props {
  onUpdateOptions: (options: string[]) => void;
  options: string[];
  className?: string;
  disabled?: boolean;
  error?: boolean;
  errorMsg?: string;
  helpText?: string;
  limit?: number;
  placeholder?: string;
  testId?: string;
}

export function MultiStringInput({
  options,
  onUpdateOptions,
  placeholder = 'first, second',
  className = '',
  helpText = '',
  testId = 'MultiStringInput',
  limit,
  error,
  errorMsg,
  disabled,
}: Props) {
  const LIMIT_ERROR_MESSAGE = `You already have ${limit} keywords. Remove a keyword before adding another.`;
  const formattedOptions = options.map(o => ({ text: o, value: o, key: o }));
  const [query, setQuery] = useState<string>('');

  const handleOnKeyDown: KeyboardEventHandler<HTMLDivElement> = e => {
    /** To make sure that spaces in a string are still enabled when we use this component inside a dropdown component */
    if (e.key === ' ' || e.keyCode === 32) e.stopPropagation();
  };
  const handleOnSearchChange = (q: string = '') => {
    const newAdditions = q.includes(',') ? q.split(',').map(i => i.trim()) : [];
    if (newAdditions.length > 0) {
      handleOnUpdateOptions([...options, ...newAdditions]);
      return;
    }
    setQuery(q);
  };
  const handleOnUpdateOptions = (options: string[]) => {
    const cleanedUpOptions = uniq(compact(options.map(o => removeExtraSpace(o))));
    if (limit && cleanedUpOptions.length > limit) {
      NegativeToast(LIMIT_ERROR_MESSAGE);
    } else onUpdateOptions(cleanedUpOptions);
    setQuery('');
  };

  return (
    <div className={className} data-testid={testId} onKeyDown={handleOnKeyDown}>
      <Dropdown
        allowAdditions
        fluid
        multiple
        search
        selection
        disabled={disabled}
        dropdownClass={styles.Dropdown}
        error={error}
        errorMsg={errorMsg}
        onBlur={(_, { searchQuery }) => handleOnUpdateOptions([...options, searchQuery as string])}
        onChange={(e, { value }) => {
          e.stopPropagation();
          handleOnUpdateOptions(value as string[]);
        }}
        onSearchChange={(_, { searchQuery }) => handleOnSearchChange(searchQuery)}
        options={formattedOptions}
        placeholder={placeholder}
        searchQuery={query}
        testId={`${testId}Dropdown`}
        value={options}
      />
      {(helpText || limit) && (
        <div className={styles.HelpText} data-testid="MultiStringInput__helpText">
          {helpText} {limit ? `Enter up to ${limit}` : ''}
        </div>
      )}
    </div>
  );
}
