import { ChangeEvent, ReactNode } from 'react';
import { ControlLabel, ControlLabelProps } from '../ControlLabel';
import styles from './ToggleButton.module.scss';

interface Props extends ControlLabelProps {
  checked: boolean;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  name?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  testId?: string;
}

export function ToggleButton({
  children,
  className = '',
  disabled,
  checked,
  onChange,
  testId = 'ToggleButton',
  label,
  subLabel,
  labelPosition,
  ...inputProps
}: Props) {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
  };
  return (
    <ControlLabel
      disabled={disabled}
      label={label}
      labelPosition={labelPosition}
      subLabel={subLabel}
    >
      <div className={`${styles.ToggleButton} ${className}`} data-testid={testId}>
        <label className={styles.ToggleButtonLabelElement} data-testid="ToggleButton__label">
          <input
            disabled={disabled}
            {...inputProps}
            checked={checked}
            data-testid={`${testId}Input`}
            onChange={handleOnChange}
            type="checkbox"
          />
          <div className={styles.ToggleButtonToggle} data-testid={`${testId}Button`} />
        </label>
        {children && <span className={styles.ToggleButtonText}>{children}</span>}
      </div>
    </ControlLabel>
  );
}
