import { leadJourneyStatuses } from 'app/schemas';
import { decode, encode } from 'js-base64';
import { compact, find, isArray, isBoolean, isString, keys } from 'lodash';
import { snakecaseKeys } from 'utils/formatting';
import {
  FilterableFieldSchema,
  FiltersWrappedInAnd,
  FiltersWrappedInOr,
  FilterType,
  PicklistOption,
  SelectedFilterOperator,
  SelectedFilterSchema,
  SerializedFiltersSchema,
} from '../../app/schemas/filterableField';
import { isEmptyValues } from '../formUtils';

export const checkIfFilterIsValid = ({
  filterType,
  values,
  selectedFilterCondition,
  picklistOptions,
}: {
  filterType: FilterType;
  picklistOptions?: PicklistOption[];
  selectedFilterCondition?: string;
  values?: SelectedFilterSchema['values'];
}) => {
  if (values === undefined) return false;
  switch (filterType) {
    case 'datetime':
      return selectedFilterCondition === 'is_between'
        ? isArray(values) && values.length === 2
        : parseInt(values as string) > 0; // checks if both dates are selected if it's a range filter. returns true otherwise
    case 'boolean':
      return isBoolean(values);
    case 'multi_select_picklist':
      const filteredValues =
        isArray(values) && values.filter(s => picklistOptions?.some(p => p.value === s)); // Filters out values that do not have a match in picklistOptions
      return !isEmptyValues(filteredValues);
    case 'picklist':
      return (
        !isEmptyValues(values) && picklistOptions?.some(p => p.value === values) // Checks if value has a match in picklistOptions
      );
    case 'search':
      return isString(values) && values.trim().length > 0;
    case 'multi_string_search':
      return isArray(values) && values.length > 0;
    default:
      return !!values;
  }
};
export const checkIfFiltersAreValid = (filters: SelectedFilterSchema[]) => {
  return !filters.some(f => !checkIfFilterIsValid(f));
};
interface FilterToSerialize {
  fieldName: SelectedFilterSchema['fieldName'];
  selectedFilterCondition: SelectedFilterSchema['selectedFilterCondition'];
  values?: SelectedFilterSchema['values'];
}
// takes an array of filter objects and formats it into what BE expects to receive: https://www.notion.so/saleswhale/Filters-Api-Doc-ddf5e6a4d6334acaa86d52ff6045722e#f02f55bbd47948b28d964e033b63b4ab
export const serializeFilters = (
  filters: FilterToSerialize[],
  selectedFilterOperator: SelectedFilterOperator = 'and'
): SerializedFiltersSchema => {
  const formatFilter = (f: FilterToSerialize) => ({
    [f.fieldName]: { [f.selectedFilterCondition]: f.values },
  });
  // typescript isn't able to infer the return type, so we'll have to explicitly define it
  if (selectedFilterOperator === 'and') {
    return { [selectedFilterOperator]: filters.map(f => formatFilter(f)) } as FiltersWrappedInAnd;
  }
  return {
    [selectedFilterOperator]: filters.map(f => formatFilter(f)),
  } as FiltersWrappedInOr;
};
// for single filter use cases
export const serializeFilter = (filter: FilterToSerialize) => {
  return serializeFilters([filter]);
};
// i'll add stronger typing than this once we remove our old filter code
export const encodeFilters = (serializedFilters: any) => {
  const s = JSON.stringify(snakecaseKeys(serializedFilters, { deep: true, exclude: [/__/g] }));
  const q = encode(s);
  return q;
};
export const decodeFilters = (encodedFilterString: string) => {
  try {
    const q = decode(encodedFilterString as string);
    return JSON.parse(q);
  } catch {
    return {};
  }
};
export const checkIfFiltersAreValidFormat = (filterObject: any) => {
  if (!filterObject) return true;
  if (typeof filterObject !== 'object') return false;
  const { and, or } = filterObject;
  const operator = and || or;
  return !!(operator && Array.isArray(operator));
};
export const generateCampaignFilter = (
  campaignId: string | number,
  additionalFilter?: FilterToSerialize
) =>
  serializeFilters([
    {
      fieldName: 'campaign_id',
      selectedFilterCondition: 'is_any_of',
      values: [Number(campaignId)],
    },
    ...(additionalFilter ? [additionalFilter] : []),
  ]);
export const generateCampaignContactEnrollmentsFilter = (
  campaignId: string | number,
  campaignFilter: 'bounce_risk' | 'enrollment_pending' | 'enrolled' | null
) => {
  const additionalFilter = campaignFilter
    ? campaignFilter === 'bounce_risk'
      ? {
          fieldName: 'enrolment_is_potential_bounce',
          selectedFilterCondition: 'is',
          values: true,
        }
      : {
          fieldName: 'enrolment_state',
          selectedFilterCondition: 'is_any_of',
          values: [...leadJourneyStatuses[campaignFilter].states],
        }
    : null;
  return serializeFilters([
    {
      fieldName: 'enrolment_campaign_id',
      selectedFilterCondition: 'is',
      values: [Number(campaignId)],
    },
    ...(additionalFilter ? [additionalFilter] : []),
  ]);
};
export const generateAccountFilter = (accountId: string) => {
  return serializeFilter({
    fieldName: 'account_id',
    selectedFilterCondition: 'is_any_of',
    values: [accountId],
  });
};
export const generateTableActionsPayloadWithFilters = (filtersQueryParam: string) => {
  return {
    type: 'filters',
    filters: filtersQueryParam,
  };
};
/**
 * This deserializer converts the serialized filters object into a SelectedFilterSchema[] array
 * This is used to initialise the `selectedFilters` state in AddFilterDropdown if there are already active filters
 */
export const deserializeFilters = (
  serializedFilters: SerializedFiltersSchema | null,
  availableFilterableFields: FilterableFieldSchema[]
): [SelectedFilterSchema[], SelectedFilterOperator] => {
  if (!serializedFilters) return [[], 'and'];
  const { and, or } = serializedFilters;
  const fields = and || or;
  const selectedFilters =
    fields &&
    fields.map(f => {
      const fieldName = keys(f)[0];
      const conditionValuePair = f[fieldName];
      const selectedFilterCondition = keys(conditionValuePair)[0];
      const values = conditionValuePair[selectedFilterCondition];
      const filterableField = find(availableFilterableFields, { fieldName });
      if (!filterableField) return null;
      return { ...filterableField, selectedFilterCondition, values } as SelectedFilterSchema;
    });
  return [compact(selectedFilters), and ? 'and' : 'or'];
};
export function deserializeFilter(
  serializedFilter: {
    [key: string]: { [key: string]: null | string | number | (string | number | null)[] };
  },
  filterableFields: FilterableFieldSchema[]
) {
  const fieldName = keys(serializedFilter)[0];
  const conditionValuePair = serializedFilter[fieldName];
  const selectedFilterCondition = keys(conditionValuePair)[0];
  const {
    availableConditions,
    fieldLabel = fieldName,
    picklistOptions,
    values,
  } = {
    ...find(filterableFields, { fieldName }),
    values: conditionValuePair[selectedFilterCondition],
  };

  const fieldCondition =
    find(availableConditions, { value: selectedFilterCondition })?.label || selectedFilterCondition;
  const fieldValue = Array.isArray(values)
    ? values
        .map(value => (value ? find(picklistOptions, { value })?.label || value : 'missing value'))
        .join(', ')
    : values || 'missing value';
  return `${fieldLabel} ${fieldCondition} ${fieldValue}`;
}
// A temporary necessary evil until we standardize naming of keys for filters and non-filters during cooldown
export const convertFromSelectedFilterSchema = (filters: SelectedFilterSchema[]) => {
  return filters.map(f => ({
    ...f,
    values: f.values,
    fieldType: f.filterType,
    selectedFilterCondition: undefined,
    filterType: undefined,
  }));
};
