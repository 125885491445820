import { sendEvent } from 'services/analytics';

const events = {
  clickEmailProviderSupportLink: `Click Email Provider Support Link on AI Restricted Modal`,
  clickLearnMoreSupportLink: `Click Learn More Link on AI Restricted Modal`,
  clickCopyBotRestrictedErrorMessage: `Click Copy Error Message on AI Restricted Modal`,
  clickGoToAiInboxOnBotRestrictedModal: `Click Go To AI Inbox on AI Restricted Modal`,
};

export const sendClickCopyBotRestrictedErrorMessage = (botId: string) => {
  sendEvent({
    event: events.clickCopyBotRestrictedErrorMessage,
    group: 'settings_ai',
    type: 'button',
    properties: { botId },
  });
};
export const sendClickBotRestrictedModalLearnMore = (botId: string) => {
  sendEvent({
    event: events.clickLearnMoreSupportLink,
    group: 'settings_ai',
    type: 'redirect_link',
    properties: { botId },
  });
};
export const sendClickBotRestrictedModalEmailProviderLink = (botId: string) => {
  sendEvent({
    event: events.clickCopyBotRestrictedErrorMessage,
    group: 'settings_ai',
    type: 'redirect_link',
    properties: { botId },
  });
};
export const sendClickBotRestrictedModalGoToAiInbox = (botId: string) => {
  sendEvent({
    event: events.clickGoToAiInboxOnBotRestrictedModal,
    group: 'settings_ai',
    type: 'modal',
    properties: { botId },
  });
};
