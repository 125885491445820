import { BotSchema } from '../../../schemas';
import { Avatar } from '../Avatar';
import styles from './BotAvatar.module.scss';

interface Props {
  bot: BotSchema;
  className?: string;
  size?: 'small' | 'large' | 'x-large' | 'medium';
  testId?: string;
}

export const BotAvatar = ({
  bot: { picture, name },
  size = 'small',
  className = '',
  testId = 'BotAvatar',
}: Props) => {
  return (
    <div
      className={`${styles.BotAvatarContainer} ${
        styles[`BotAvatarContainer--${size}`]
      } ${className}`}
      data-testid={testId}
    >
      {picture ? (
        <div className={`${styles.BotAvatar}`}>
          <img alt={name} src={picture} />
        </div>
      ) : (
        <Avatar className={styles.BotAvatar} size={size} userName={name} />
      )}
    </div>
  );
};
