import { ReactNode, useEffect, useState, useRef } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import styles from './StickyContainer.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
  isFullHeight?: boolean;
  testId?: string;
}

export function StickyContainer({
  children,
  className,
  testId = 'StickyContainer',
  isFullHeight = false,
}: Props) {
  const elementRef = useRef<HTMLDivElement>(null);
  const observer = useRef<ResizeObserver>();
  const topbarHeight = 52;
  const [top, setTop] = useState<string>('0px');
  const [offset, setOffset] = useState<number>(0);

  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      const mainErrorBanner = document.getElementById('MainErrors');
      if (mainErrorBanner) {
        const { height } = mainErrorBanner.getBoundingClientRect();
        setOffset(height);
      }
      const { top } = element.getBoundingClientRect();
      observer.current = new ResizeObserver(() => {
        setTop(`${top - offset - topbarHeight}px`);
      });
      observer.current.observe(element);
    }
    return () => {
      if (observer.current && element) {
        observer.current.unobserve(element);
      }
    };
  }, [offset]);
  const height = isFullHeight ? `calc(100vh - ${top})` : 'auto';

  return (
    <div
      className={`${styles.Container} ${className ? className : ''}`}
      data-testid={testId}
      ref={elementRef}
      style={{ top, minHeight: height, maxHeight: height }}
    >
      {children}
    </div>
  );
}
