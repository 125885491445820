import { useHistory } from 'react-router-dom';
import { resendConfirmationEmail } from '../app/adapters/membership';
import { PositiveToast, didError } from '../app/components/Elements';
import { FULL_COMPANY_NAME } from '../config/constants';

export const useResendEmail = () => {
  const history = useHistory();
  return async (userId: number | string) => {
    const id = userId.toString();
    try {
      const response = await resendConfirmationEmail(id);
      if (response.confirmedAt) {
        PositiveToast(
          `You’ve already confirmed your email! Redirecting you to ${FULL_COMPANY_NAME}...`
        );
        const timer = setTimeout(() => {
          history.push('/');
          clearTimeout(timer);
        }, 3000);
      } else {
        PositiveToast('Confirmation email resent! Check your inbox to complete sign-up.');
      }
    } catch (e) {
      didError(e);
    }
  };
};
