import { Check } from '@saleswhale/barnacle/icons';
import { Fragment } from 'react';
import { Button, StickyContainer } from '../../Elements';
import styles from './EnrollFlowHeader.module.scss';

interface Props {
  currentStep: number;
  goToNextStep: (s: number) => void;
  goToPrevStep: (s: number) => void;
  isCompletingLastStep: boolean;
  isValid: boolean;
  onClickCancel: () => void;
  steps: { description: string; number: number }[];
}

export function EnrollFlowHeader({
  isValid,
  currentStep,
  goToNextStep,
  goToPrevStep,
  isCompletingLastStep,
  steps,
  onClickCancel,
}: Props) {
  const backButtonIsDisabled = currentStep === 1;
  const nextButtonIsDisabled = !isValid;
  const isLastStep = currentStep === steps.length;

  return (
    <StickyContainer className={styles.Container} testId="EnrollFlowHeader">
      <div className={styles.LeftContainer}>
        <div className={styles.BackButtonContainer}>
          <Button
            disabled={backButtonIsDisabled}
            label="Back"
            onClick={() => goToPrevStep(currentStep)}
            testId="EnrollFlowHeader__backButton"
            theme="secondary"
          />
        </div>
        <Button
          label="Cancel enrollment"
          onClick={onClickCancel}
          testId="EnrollFlowHeader__cancel"
          theme="flush"
        />
      </div>
      <div className={styles.StepperWrapper} data-testid="EnrollFlowHeader__stepper">
        {steps.map((step, i) => {
          const isCurrentStep = currentStep === step.number;
          const isStepCompleted = currentStep > step.number;

          return (
            <Fragment key={i}>
              <div
                className={`${
                  isStepCompleted
                    ? styles.CompleteStep
                    : isCurrentStep
                    ? styles.ActiveStep
                    : styles.Step
                }`}
                data-testid={
                  isCurrentStep || isStepCompleted
                    ? 'EnrollFlowHeader__stepActive'
                    : 'EnrollFlowHeader__step'
                }
              >
                <div
                  className={styles.StepIndicator}
                  data-testid={`EnrollFlowHeader__stepIndicator${step.number}`}
                >
                  {isStepCompleted ? (
                    <Check color="#12B76A" size={12} />
                  ) : (
                    <div className={styles.StepDot} />
                  )}
                </div>
                <div className={styles.StepDescription}>
                  <span data-testid="EnrollFlowHeader__stepDescription">{step.description}</span>
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
      <div className={styles.RightContainer}>
        <Button
          disabled={nextButtonIsDisabled}
          isLoading={isCompletingLastStep}
          label={isLastStep ? 'Link to campaign' : 'Next'}
          onClick={() => goToNextStep(currentStep)}
          testId="EnrollFlowHeader__nextButton"
          theme="primary"
        />
      </div>
    </StickyContainer>
  );
}
