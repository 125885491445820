import { LoadingSpinner } from '../LoadingSpinner';

interface Props {
  className?: string;
  testId?: string;
}

export function Loading({ className, testId = 'Loading' }: Props) {
  return <LoadingSpinner className={className} testId={testId} type="page" />;
}
