import { EmptyState6DS } from '../SixSenseElements';
import styles from './TableNoData.module.scss';

interface Props {
  children?: React.ReactNode;
  colSpan?: number;
  description?: string;
  testId?: string;
  title?: string;
}

export default function TableNoData({
  title = 'No data to show',
  description,
  testId = 'TableNoData',
  colSpan = 7,
  children,
}: Props) {
  return (
    <tbody className={styles.Container} data-testid={testId}>
      <tr>
        <td colSpan={colSpan}>
          <EmptyState6DS description={description} level="component" title={title}>
            {children}
          </EmptyState6DS>
        </td>
      </tr>
    </tbody>
  );
}
