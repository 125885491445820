import { Checkbox as UiCheckbox, CheckboxProps } from 'semantic-ui-react';
import { ControlLabel, ControlLabelProps } from '../ControlLabel';
import './Checkbox.scss';

interface Props extends ControlLabelProps {
  checked: boolean;
  className?: string;
  disabled?: boolean;
  indeterminate?: boolean;
  labelColor?: 'warning' | 'success' | 'neutral' | 'danger';
  name?: string;
  onChange?: (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>, data: CheckboxProps) => void;
  readOnly?: boolean;
  testId?: string;
}

export function Checkbox({
  checked,
  className,
  disabled,
  onClick,
  onChange,
  readOnly,
  name,
  testId = 'Checkbox',
  indeterminate = false,
  label,
  labelPosition,
  subLabel,
  labelColor = 'neutral',
}: Props) {
  return (
    <ControlLabel
      disabled={disabled}
      label={label}
      labelColor={labelColor}
      labelPosition={labelPosition}
      subLabel={subLabel}
    >
      <UiCheckbox
        checked={checked}
        className={className}
        data-testid={testId}
        disabled={disabled}
        indeterminate={checked && indeterminate}
        name={name}
        onChange={onChange}
        onClick={onClick}
        readOnly={readOnly}
      />
    </ControlLabel>
  );
}
