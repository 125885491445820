import { startCase } from 'lodash';
import { MergeTagValueSchema } from '../../app/schemas';
import { MergeTagSchema } from '../../app/schemas/mergeTag';

const normalizeCase = (mergeTag: string) => {
  return mergeTag.replace(/[._]/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
};
function formatIdentifier(identifier: string) {
  switch (identifier) {
    case 'bot':
    case 'ai':
      return 'AI';
    // add a case for lead -> contacts if we need to mask it in the future
    default:
      return startCase(identifier);
  }
}
export function mergeTagDecorator(mergeTag: string) {
  const mergeTagSplit = mergeTag.split(/[._]/g);
  return `${formatIdentifier(mergeTagSplit[0])} ${normalizeCase(mergeTagSplit.slice(1).join(' '))}`;
}
export function formattedMergeTag(tag: string | null) {
  if (!tag) return '';
  // replaces brackets if any
  const tagSplit = tag.replace(/{{(.*?)}}/, `$1`).split('.');
  return `${formatIdentifier(tagSplit[0])} ${tagSplit
    .slice(1)
    .map(s => startCase(s))
    .join(' ')}`;
}

export const substitute = (email: string, mergeTags: MergeTagValueSchema[]) => {
  if (mergeTags.length === 0) return email;
  // the BE currently doesn't return the tags in the subject. in the hopes of one day standardizing, and to handle both cases where we get a tag back, and don't get a tag back, i'm stripping out the tags (if it exists) before adding them back.
  let substitutedEmail = email.replace(new RegExp('<merge-tag>|</merge-tag>', 'g'), '');
  mergeTags.forEach(({ attribute, value }) => {
    const mergeTag = value ? value : mergeTagDecorator(attribute);
    substitutedEmail = substitutedEmail.replace(
      new RegExp(`{{${attribute}}}`, 'g'),
      `<merge-tag>${mergeTag}</merge-tag>`
    );
  });
  return substitutedEmail;
};
export const format = (text: string) => {
  const splitString = text.split(/{{|}}/);
  if (splitString.length === 1) return text;

  // <snippet-merge-tag>s are used for the referral snippets instead of proper <merge-tag>s for some reason so we parse those out so we don't end up with <snippet-merge-tag><merge-tag>...</merge-tag></snippet-merge-tag>
  if (text.includes('snippet-merge-tag')) {
    return `${splitString[0].replace('snippet-', '')}${mergeTagDecorator(
      splitString[1]
    )}${splitString[2].replace('snippet-', '')}`;
  }
  return splitString
    .map(s => (s.includes('merge-tag') ? s : `<merge-tag>${mergeTagDecorator(s)}</merge-tag>`))
    .join('');
};
// TODO: replace all usages of the old format function with this, as this covers more cases.
export const formatMergeTags = (text: string) => {
  const mergeTags = text.match(/[^{{}]+(?=\}})/g);
  if (!mergeTags || mergeTags.length === 0) return text;
  mergeTags.forEach(t => {
    const mergeTag = mergeTagDecorator(t);
    text = text.replace(new RegExp(`{{${t}}}`, 'g'), `<merge-tag>${mergeTag}</merge-tag>`);
  });
  return text;
};
export const substituteForRephrase = (text: string) => {
  let substitutedText = text.replace(new RegExp('<merge-tag>|</merge-tag>', 'g'), '');
  const mergeTags = text.match(/[^{{}]+(?=\}})/g) || [];
  mergeTags.forEach(t => {
    const mergeTag = mergeTagDecorator(t);
    const attributeRegex = new RegExp(`{{${t}}}`, 'g');
    const mergeTagValue = `<merge-tag>${mergeTag}</merge-tag>`;
    substitutedText = substitutedText.replace(attributeRegex, mergeTagValue);
  });
  return substitutedText;
};
export const validateMergeTag = (value: string, mergeTags: MergeTagValueSchema[]) => {
  return mergeTags.some(tag => tag.attribute === value);
};
export function convertToMergeTagAttributes(html: string, mergeTags: MergeTagSchema[]) {
  if (mergeTags.length === 0) return html;
  // Replaces merge tag display name with merge tag attribute
  mergeTags.forEach(({ attribute }) => {
    html = html.replace(
      new RegExp(`<merge-tag>${mergeTagDecorator(attribute)}</merge-tag>`, 'g'),
      `<merge-tag>{{${attribute}}}</merge-tag>`
    );
  });

  return html;
}
export function checkIfMergeTagAttribute(value: string) {
  const checkRegex = /{{[^.]+\.[^}]+}}/;
  return checkRegex.test(value);
}
export function formatMergeTagAttribute(formattedTag: string) {
  if (checkIfMergeTagAttribute(formattedTag)) return formattedTag;
  const mergeTagWords = formattedTag.split(' ');
  const mainIdentifier = mergeTagWords[0].toLowerCase();
  const endingIdentifier = mergeTagWords.slice(1).map(part => part.toLowerCase());
  const tag = `{{${mainIdentifier}.${endingIdentifier.join('_')}}}`;
  return tag;
}
