import { ReactElement } from 'react';
import styles from './TableActionsHeader.module.scss';

interface Props {
  children: ReactElement | ReactElement[];
  className?: string;
  testId?: string;
}

export const TableActionsHeader = ({
  children,
  className = '',
  testId = 'TableActionsHeader',
}: Props) => {
  return (
    <div className={`${styles.Container} ${className}`} data-testid={testId}>
      {children}
    </div>
  );
};
