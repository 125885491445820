import EmailConfirmationContainer from 'app/components/EmailConfirmation/EmailConfirmationContainer';
import FromEmailContainer from 'app/components/HumanReviewTask/FromEmailContainer';
import IntegrationReconnectedContainer from 'app/components/Integrations/IntegrationReconnectedContainer';
import ResetPassword from 'app/components/ResetPassword';
import SetPassword from 'app/components/SetPassword';
import { UnsubscribeContainer } from 'app/components/Unsubscribe/UnsubscribeContainer';
import { useFlagsmithLoading } from 'flagsmith/react';
import { lazy, Suspense, useEffect, useRef } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AuthSwitchTeam } from './app/components/AuthSwitchTeam';
import AuthTokenHandoff from './app/components/AuthTokenHandoff/AuthTokenHandoff';
import { CloseToastButton, Loading } from './app/components/Elements';
import { sendPageViewEvent } from './App.tracking';
import './App.scss';

const AppContainer = lazy(() => import('./app/components/AppContainer'));

function App() {
  const history = useHistory();
  const previousLocation = useRef('');
  const currentLocation = useLocation();

  const flagsmithLoading = useFlagsmithLoading();

  useEffect(() => {
    const unlisten = history.listen(location => {
      if (location.pathname !== previousLocation.current) {
        window.scrollTo(0, 0);
        previousLocation.current = location.pathname;
      }
    });
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    sendPageViewEvent(currentLocation.pathname);
  }, [currentLocation]);

  if (flagsmithLoading?.isLoading) return <Loading />;

  return (
    <>
      <ToastContainer
        closeButton={<CloseToastButton />}
        closeOnClick={false}
        draggable={false}
        limit={3}
      />
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/auth/handover">
            <AuthTokenHandoff />
          </Route>
          <Route path="/auth/switch_team">
            <AuthSwitchTeam />
          </Route>
          <Route
            key="humanReviewTaskRoutes"
            path={[
              '/assign_human_review_task/:uuid/:status',
              '/update_human_review_task/:uuid/:status',
              '/cancel_human_review_task/:uuid/:status',
              '/continue_human_review_task/:uuid',
            ]}
          >
            <FromEmailContainer />
          </Route>
          <Route path="/integration_reconnected">
            <IntegrationReconnectedContainer />
          </Route>
          <Route path="/email_confirmation">
            <EmailConfirmationContainer />
          </Route>
          <Route path="/set_password">
            <SetPassword />
          </Route>
          <Route path="/reset_password">
            <ResetPassword />
          </Route>
          <Route path="/unsubscribe">
            <UnsubscribeContainer />
          </Route>
          <AppContainer />
        </Switch>
      </Suspense>
    </>
  );
}

export default App;
