import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import styles from './BreadcrumbItem.module.scss';

export type BreadcrumbItemProps = {
  children: ReactJSXElement | string;
  testId?: string;
  title?: string;
};

const BreadcrumbItem = ({ children, testId = 'BreadcrumbItem', title }: BreadcrumbItemProps) => (
  <span className={styles.BreadcrumbItem} data-testid={testId} title={title}>
    {children}
  </span>
);

export default BreadcrumbItem;
