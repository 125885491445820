import { IconProps } from '@saleswhale/barnacle/icons/types';
import { BadgeColor } from '..';
import { getClass } from '../../../../../utils/getClass';
import './BadgeIcon.scss';

interface BadgeIconProps {
  color: BadgeColor;
  icon: React.FC<IconProps>;
  theme: 'light' | 'dark';
}

const BadgeIcon = ({ icon: Icon, color, theme, ...props }: BadgeIconProps) => (
  <div className={`BadgeIcon ${getClass('BadgeIcon', { theme, color })}`}>
    <Icon data-testid="Badge__icon" size={12} {...props} />
  </div>
);

export default BadgeIcon;
