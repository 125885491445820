import { ReactNode, forwardRef, ForwardedRef } from 'react';
import styles from './Card.module.scss';

interface Props {
  children: ReactNode;
  activeClassName?: string;
  className?: string;
  isActive?: boolean;
  // Adding isClickable as some cards use links
  isClickable?: boolean;
  onClick?: () => void;
  testId?: string;
}

export const Card = forwardRef(
  (
    {
      activeClassName,
      children,
      className = '',
      isActive = false,
      onClick,
      isClickable = false,
      testId = 'Card',
    }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <div
      className={`${styles.Container} ${(onClick || isClickable) && styles.ClickableContainer} ${
        isActive && (activeClassName ?? styles['ClickableContainer--active'])
      } ${className}`}
      data-testid={testId}
      onClick={onClick}
      ref={ref}
    >
      {children}
    </div>
  )
);
