interface EngageError {
  detail: string;
  id?: string;
  message?: string;
  // TODO: engage doesn't return status in individual error objects - verify and remove eventually
  status?: string;
  title?: string;
}
export interface EngageErrors {
  errors: EngageError[];
}

/**
 * Errors thrown by the Engage API when something in the request went wrong.
 * This is specifically to handle errors unrelated to networks requests and when
 * the API actually is able to return a custom error object
 */
export class EngageAPIError extends Error {
  httpStatus: number;
  errors: EngageError[];
  constructor(httpStatus: number, engageErrors: EngageErrors) {
    const _message = engageErrors.errors.map(({ message, detail }) => message || detail).join(`\n`);
    super(_message);

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, EngageAPIError);
    }

    this.name = 'APIError';
    this.httpStatus = httpStatus;
    this.errors = engageErrors.errors;
  }

  isServiceUnavailable() {
    return this.httpStatus === 503;
  }
}
